<template>
  <base-header
    v-if="loggedInUser.is_service"
    type="gradient-success"
    style="background: url(/img/bg-1.2147a146.jpg) !important"
    class="pb-6 pb-8 pt-5 pt-md-8"
  >
    <div class="row">
      <div class="col-xl-6 col-lg-6">
        <stats-card
          title="Total Parts"
          type="gradient-red"
          sub-title="10"
          class="mb-4 mb-xl-0"
        >
          <template v-slot:footer>
            <span class="text-success mr-2">
              <i class="fa fa-arrow-up"></i> 0%
            </span>
            <span class="text-nowrap">Since last month</span>
          </template>
        </stats-card>
      </div>
      <div class="col-xl-6 col-lg-6">
        <stats-card
          title="Total Ads Banners"
          type="gradient-red"
          sub-title="0"
          class="mb-4 mb-xl-0"
        >
          <template v-slot:footer>
            <span class="text-success mr-2">
              <i class="fa fa-arrow-up"></i> 0%
            </span>
            <span class="text-nowrap">Since last month</span>
          </template>
        </stats-card>
      </div>
    </div>
  </base-header>
  <base-header
    v-else-if="loggedInUser.is_superuser"
    type="gradient-success"
    class="pb-6 pb-8 pt-5 pt-md-8"
  >
    <div class="row">
      <div class="col-xl-3 col-lg-6">
        <stats-card
          title="Total Parts"
          type="gradient-red"
          sub-title="350"
          icon="ni ni-active-40"
          class="mb-4 mb-xl-0"
        >
          <template v-slot:footer>
            <span class="text-success mr-2">
              <i class="fa fa-arrow-up"></i> 3.48%
            </span>
            <span class="text-nowrap">Since last month</span>
          </template>
        </stats-card>
      </div>
      <div class="col-xl-3 col-lg-6">
        <stats-card
          title="Total Customers"
          type="gradient-orange"
          sub-title="23"
          icon="ni ni-chart-pie-35"
          class="mb-4 mb-xl-0"
        >
          <template v-slot:footer>
            <span class="text-success mr-2">
              <i class="fa fa-arrow-up"></i> 12.18%
            </span>
            <span class="text-nowrap">Since last month</span>
          </template>
        </stats-card>
      </div>
      <div class="col-xl-3 col-lg-6">
        <stats-card
          title="Total Subscriber"
          type="gradient-green"
          sub-title="4"
          icon="ni ni-money-coins"
          class="mb-4 mb-xl-0"
        >
          <template v-slot:footer>
            <span class="text-danger mr-2">
              <i class="fa fa-arrow-down"></i> 5.72%
            </span>
            <span class="text-nowrap">Since last month</span>
          </template>
        </stats-card>
      </div>
      <div class="col-xl-3 col-lg-6">
        <stats-card
          title="Total Services"
          type="gradient-info"
          sub-title="4"
          icon="ni ni-chart-bar-32"
          class="mb-4 mb-xl-0"
        >
          <template v-slot:footer>
            <span class="text-success mr-2">
              <i class="fa fa-arrow-up"></i> 54.8%
            </span>
            <span class="text-nowrap">Since last month</span>
          </template>
        </stats-card>
      </div>
    </div>
  </base-header>
</template>
<script>
// Charts

export default {
  components: {},
  data() {
    return {};
  },
  computed: {
    loggedInUser() {
      return this.$store.getters.user;
    },
    isLoggedIn() {
      return this.$store.getters.isAuthenticated;
    },
  },
  methods: {},
};
</script>
<style></style>
