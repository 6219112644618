<template>
  <div>
    <base-dialog :show="isLoading" title="Loading..." fixed>
      <base-spinner></base-spinner>
    </base-dialog>
    <admin-header></admin-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col">
          <div class="card shadow bg-default">
            <div class="card-header border-0 bg-transparent">
              <div class="row align-items-center">
                <div class="col">
                  <h3 class="mb-0 text-white" v-if="hasSubscriptionType">
                    You have successfully subcribed for the
                    {{ subscription_type.name }} package.<br /><br />
                  </h3>
                  <h3 class="mb-0 text-white">
                    You will be automatically logged out.<br />
                    Thank you and enjoy our service.
                  </h3>
                  <!-- <a href="/admin-add-part" class="btn btn-md btn-block btn-primary"
                      >Pay for Subscription</a
                    > -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["packageId"],
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    async loadService() {
      this.isLoading = true;
      try {
        await this.$store.dispatch("services/getService", {
          serviceId: this.loggedInUser.id,
        });
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;
    },
    async getSelectedPackage() {
      this.isLoading = true;
      try {
        await this.$store.dispatch("subscription_types/getSubscriptionType", {
          packageId: this.packageId,
        });
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;
    },
    logout() {
      this.$store.dispatch("logout");
      this.$router.replace("/login");
    },
    toDashboard() {
      this.$router.replace("/admin-dashboard");
    },
  },
  computed: {
    loggedInUser() {
      return this.$store.getters.user;
    },
    service() {
      return this.$store.getters["services/service"];
    },
    hasService() {
      return this.$store.getters["services/hasService"];
    },
    subscription_type() {
      return this.$store.getters["subscription_types/subscription_type"];
    },
    hasSubscriptionType() {
      return this.$store.getters["subscription_types/hasSubscriptionType"];
    },
  },
  async created() {
    document.title = "PSI Link | Subscription Success";
    if (this.loggedInUser.is_service) {
      await this.loadService();
      await this.getSelectedPackage();
    }

    setTimeout(() => this.logout(), 5000);
  },
};
</script>
<style scoped>
.custom-h {
  min-height: 1px;
  max-height: 400px;
  overflow: scroll;
}
</style>
