<template>
  <li :class="{ active: activeClass }">
    <a href="#" @click="onClick">{{ pageNumber }}</a>
  </li>
</template>
<script>
export default {
  props: {
    pageNumber: {
      type: Number,
      required: true,
    },
    activeClass: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onClick() {
      this.$emit("loadPage", this.pageNumber);
    },
  },
};
</script>
