<template>
  <!-- Begin Uren's Product Area -->
  <div class="uren-product_area">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-12">
          <div v-if="showHeading" class="section-title_area">
            <span>Top New On This Week</span>
            <h3>New Arrivals Parts</h3>
          </div>
          <div
            v-if="products.length > 0"
            class="product-slider uren-slick-slider slider-navigation_style-1 img-hover-effect_area"
            data-slick-options='{
                        "slidesToShow": 6,
                        "arrows" : true
                        }'
            data-slick-responsive='[
                                                {"breakpoint":1501, "settings": {"slidesToShow": 4}},
                                                {"breakpoint":1200, "settings": {"slidesToShow": 3}},
                                                {"breakpoint":992, "settings": {"slidesToShow": 2}},
                                                {"breakpoint":767, "settings": {"slidesToShow": 1}},
                                                {"breakpoint":480, "settings": {"slidesToShow": 1}}
                                            ]'
          >
            <div
              class="product-slide_item"
              v-for="part in products"
              :key="part.id"
            >
              <div class="inner-slide">
                <div class="single-product">
                  <div class="product-img">
                    <a
                      data-toggle="modal"
                      :data-target="`#customModalCenter-${part.id}`"
                    >
                      <img
                        v-for="(img, index) in part.images"
                        :key="img.id"
                        :class="index === 0 ? 'primary-img' : 'secondary-img'"
                        :src="img.get_image"
                        :alt="part.name"
                      />
                      <!-- <img
                        class="secondary-img"
                        :src="part.images[1].get_image"
                        :alt="part.name"
                      /> -->
                    </a>
                    <div class="sticker-area-2">
                      <span v-if="part.discount !== '0'" class="sticker-2"
                        >{{ part.discount }}%</span
                      >
                      <span v-if="hasValue('new', part.tags)" class="sticker"
                        >New</span
                      >
                    </div>
                    <div class="add-actions">
                      <ul>
                        <li>
                          <a
                            class="uren-wishlist"
                            :href="!isLoggedIn ? '/login' : '#'"
                            @click="addToWishlist()"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Add To Wishlist"
                            ><i class="ion-android-favorite-outline"></i
                          ></a>
                        </li>
                        <li
                          class="quick-view-btn"
                          data-toggle="modal"
                          :data-target="`#customModalCenter-${part.id}`"
                        >
                          <a
                            href="javascript:void(0)"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Quick View"
                            ><i class="ion-android-open"></i
                          ></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="product-content">
                    <div class="product-desc_info">
                      <!-- <div class="rating-box">
                        <ul>
                          <li><i class="ion-android-star"></i></li>
                          <li><i class="ion-android-star"></i></li>
                          <li><i class="ion-android-star"></i></li>
                          <li class="silver-color">
                            <i class="ion-android-star"></i>
                          </li>
                          <li class="silver-color">
                            <i class="ion-android-star"></i>
                          </li>
                        </ul>
                      </div> -->
                      <h6>
                        <a class="product-name" href="#">{{ part.name }}</a>
                      </h6>
                      <div class="price-box">
                        <span class="new-price new-price-2">{{
                          part.price == -1
                            ? "Contact for price"
                            : `R${part.price}`
                        }}</span>
                        <!-- <span v-if="part.discount !== '0'" class="old-price"
                          >R{{ part.oldprice }}</span
                        > -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Uren's Product Area End Here -->
  <product-info-modal
    v-for="part in products"
    :key="part.id"
    :part="part"
  ></product-info-modal>
</template>

<script>
import ProductInfoModal from "./ProductInfoModal.vue";

export default {
  components: { ProductInfoModal },
  props: {
    products: {
      type: Array,
      required: true,
      // default: ()=>[]
    },
    showHeading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters.isAuthenticated;
    },
    hasParts() {
      return this.$store.getters["parts/hasParts"];
    },
  },
  methods: {
    hasValue(q, haystack) {
      return haystack.includes(q);
    },
    addToWishlist() {
      console.log("logged in");
    },
  },
};
</script>
