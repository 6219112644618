<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <side-bar
      :background-color="sidebarBackground"
      short-title="PSI LINK"
      title="PSI LINK"
    >
      <template v-slot:links>
        <sidebar-item
          :link="{
            name: 'Home',
            icon: 'ni ni-planet text-primary',
            path: '/homepage',
          }"
        />
        <sidebar-item
          :link="{
            name: 'Dashboard',
            icon: 'ni ni-tv-2 text-primary',
            path: '/admin-dashboard',
          }"
        />

        <sidebar-item
          v-if="loggedInUser.is_superuser"
          :link="{
            name: 'Services',
            icon: 'ni ni-planet text-blue',
            path: '/admin-icons',
          }"
        />
        <sidebar-item
          v-if="loggedInUser.is_superuser"
          :link="{
            name: 'Categories',
            icon: 'ni ni-pin-3 text-orange',
            path: '/admin-maps',
          }"
        />
        <sidebar-item
          v-if="loggedInUser.is_superuser"
          :link="{
            name: 'Part Types',
            icon: 'ni ni-single-02 text-yellow',
            path: '/admin-profile',
          }"
        />
        <sidebar-item
          v-if="loggedInUser.is_service || loggedInUser.is_superuser"
          :link="{
            name: 'Advertisement Banner',
            icon: 'ni ni-bullet-list-67 text-red',
            path: '/admin-adverts',
          }"
        />
        <sidebar-item
          v-if="loggedInUser.is_service || loggedInUser.is_superuser"
          :link="{
            name: 'Parts',
            icon: 'ni ni-key-25 text-info',
            path: '/admin-parts',
          }"
        />
        <sidebar-item
          v-if="loggedInUser.is_superuser"
          :link="{
            name: 'Subscriptions',
            icon: 'ni ni-circle-08 text-pink',
            path: '/admin-dashboard',
          }"
        />
      </template>
    </side-bar>
    <div class="main-content" :data="sidebarBackground">
      <dashboard-navbar></dashboard-navbar>

      <div @click="toggleSidebar">
        <!-- your content here -->
        <router-view></router-view>
        <content-footer v-if="!$route.meta.hideFooter"></content-footer>
      </div>
    </div>
  </div>
</template>
<script>
import DashboardNavbar from "./DashboardNavbar.vue";
import ContentFooter from "./ContentFooter.vue";

export default {
  components: {
    DashboardNavbar,
    ContentFooter,
  },
  data() {
    return {
      sidebarBackground: "vue", //vue|blue|orange|green|red|primary
    };
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters.isAuthenticated;
    },
    loggedInUser() {
      return this.$store.getters.user;
    },
    hasService() {
      return this.$store.getters["services/hasService"];
    },
    service() {
      return this.$store.getters["services/service"];
    },
    subscribedAdsAndparts() {
      if (this.loggedInUser.is_service && this.hasService) {
        if (
          this.service.serviceAdAllow === "parts_and_ads" ||
          this.service.serviceAdAllow === "ads_only" ||
          this.service.serviceAdAllow === "parts_only"
        ) {
          if (
            this.service.subscription &&
            this.service.subscription.subscriptionType === "Premium Package"
          ) {
            return true;
          }
        }
        return false;
      }

      return false;
    },
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    subscribed(type) {
      if (this.loggedInUser.is_service && this.hasService) {
        if (
          this.service.subscription &&
          this.service.subscription.subscriptionType === "Standard Package"
        ) {
          if (this.service.serviceAdAllow === type) {
            return true;
          }
          return false;
        }

        return false;
      }

      return false;
    },
  },
};
</script>
<style lang="scss"></style>
