import axios from "axios";

const API_URL = process.env.VUE_APP_API_URL;

class PartService {
  getConfig(token) {
    return {
      headers: { Authorization: `Token ${token}` },
    };
  }

  async createPart(token, data) {
    return await axios.post(API_URL + "parts/", data, this.getConfig(token));
  }

  async getParts(token, payload) {
    return await axios.get(API_URL + `parts/${payload}`, this.getConfig(token));
  }

  async getPart(token, payload) {
    return await axios.get(
      API_URL + `parts/${payload.id}`,
      this.getConfig(token)
    );
  }

  async getPartsWeb(payload, token) {
    return await axios.get(
      API_URL + `parts/${payload}`,
      token !== null ? this.getConfig(token) : null
    );
  }

  async uploadParts(token, data) {
    return await axios.post(
      API_URL + "parts/csv-upload",
      data,
      this.getConfig(token)
    );
  }

  async updatePart(token, data) {
    return await axios.patch(
      API_URL + `parts/${data.slug}`,
      data.part,
      this.getConfig(token)
    );
  }

  async deletePart(token, payload) {
    return await axios.delete(
      API_URL + `parts/${payload.id}`,
      this.getConfig(token)
    );
  }
}

export default new PartService();
