import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";

export default {
  namespaced: true,
  state() {
    return {
      services: [],
      serviceAddresses: [],
      service: null,
      customers: [],
      customer: null,
    };
  },
  mutations,
  actions,
  getters,
};
