/* eslint-disable */
var createElement = (function () {
  return function (tagName, attributes) {
    attributes = attributes || {};
    var element = document.createElement(tagName);
    for (var attr in attributes) {
      if (attributes.hasOwnProperty(attr)) {
        element.setAttribute(attr, attributes[attr]);
      }
    }
    return element;
  };
})();

export default function postToURL(url, values) {
  values = values || {};
    
  var form = createElement("form", {
    action: url,
    method: "POST",
    style: "display: none",
  });

  for (var property in values) {
    if (values.hasOwnProperty(property)) {
      var value = values[property];
      if (value instanceof Array) {
        for (var i = 0, l = value.length; i < l; i++) {
          form.appendChild(
            createElement("input", {
              type: "hidden",
              name: property,
              value: value[i],
            })
          );
        }
      } else {
        form.appendChild(
          createElement("input", {
            type: "hidden",
            name: property,
            value: value,
          })
        );
      }
    }
  }
  document.body.appendChild(form);
  form.submit();
  document.body.removeChild(form);
}
