import axios from "axios";

const API_CAT_URL = process.env.VUE_APP_API_URL + "categories/";
const API_SUB_CAT_URL = process.env.VUE_APP_API_URL + "sub-categories/";
const API_PARTTYPE_URL = process.env.VUE_APP_API_URL + "part-types/";
const API_V_MAKE_URL = process.env.VUE_APP_API_URL + "vehicle-make/";
const API_V_MODEL_URL = process.env.VUE_APP_API_URL + "vehicle-model/";

class CategoryService {
  getConfig(token) {
    return {
      headers: { Authorization: `Token ${token}` },
    };
  }

  async createCategory(token, data) {
    return await axios.post(API_CAT_URL, data, this.getConfig(token));
  }

  async createSubCategory(token, data) {
    return await axios.post(API_SUB_CAT_URL, data, this.getConfig(token));
  }

  async createVehicleMake(token, data) {
    return await axios.post(API_V_MAKE_URL, data, this.getConfig(token));
  }

  async createVehicleModel(token, data) {
    return await axios.post(API_V_MODEL_URL, data, this.getConfig(token));
  }

  async createPartType(token, data) {
    return await axios.post(API_PARTTYPE_URL, data, this.getConfig(token));
  }

  async getCategories(token) {
    return await axios.get(
      API_CAT_URL,
      token !== null ? this.getConfig(token) : null
    );
  }

  async getSubCategories(token, payload) {
    return await axios.get(API_SUB_CAT_URL + payload, this.getConfig(token));
  }

  async getVehicleMakes(token) {
    return await axios.get(
      API_V_MAKE_URL,
      token !== null ? this.getConfig(token) : null
    );
  }

  async getVehicleModels(token, payload) {
    return await axios.get(API_V_MODEL_URL + payload, this.getConfig(token));
  }

  async getPartTypes(token) {
    return await axios.get(API_PARTTYPE_URL, this.getConfig(token));
  }
}

export default new CategoryService();
