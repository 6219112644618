<template>
  <div>
    <base-dialog :show="isLoading" title="Loading..." fixed>
      <base-spinner></base-spinner>
    </base-dialog>
    <admin-header></admin-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col">
          <adverts-table
            v-if="adverts.length >= 0"
            :tableData="adverts"
            title="Advertisments"
            type="dark"
          ></adverts-table>
        </div>
        <!-- <div class="col" v-else>
          <div class="card shadow bg-default">
            <div class="card-header border-0 bg-transparent">
              <div class="row align-items-center">
                <div class="col">
                  <h3 class="mb-0 text-white">
                    You have not registered for Advertisments.
                  </h3>
                </div>
                <div class="col text-right"></div>
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
import AdvertsTable from "../Tables/AdvertsTable.vue";

export default {
  components: { AdvertsTable },
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    async loadService() {
      this.isLoading = true;
      try {
        await this.$store.dispatch("services/getService", {
          serviceId: this.loggedInUser.id,
        });
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;
    },
    async loadServices() {
      this.isLoading = true;
      try {
        await this.$store.dispatch("services/loadServices");
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;
    },
    async loadServiceAdverts() {
      this.isLoading = true;
      try {
        await this.$store.dispatch("adverts/loadAdverts", {
          serviceSlug: this.service.slug,
        });
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;
    },
    async loadAdverts() {
      this.isLoading = true;
      try {
        await this.$store.dispatch("adverts/loadAdverts");
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;
    },
  },
  computed: {
    loggedInUser() {
      return this.$store.getters.user;
    },
    service() {
      return this.$store.getters["services/service"];
    },
    hasService() {
      return this.$store.getters["services/hasService"];
    },
    hasAdverts() {
      return this.$store.getters["adverts/hasAdverts"];
    },
    adverts() {
      return this.$store.getters["adverts/adverts"];
    },
    checkAdsAccess() {
      return (
        this.hasService &&
        (this.service.serviceAdAllow === "parts_and_ads" ||
          this.service.serviceAdAllow === "ads_only")
      );
    },
  },
  async created() {
    document.title = "PSI Link | Admin Adverts";
    if (this.loggedInUser.is_service) {
      await this.loadService();
      await this.loadServiceAdverts();
    } else {
      await this.loadServices();
      await this.loadAdverts();
    }
  },
};
</script>
<style></style>
