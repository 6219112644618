<template>
  <div class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
    <div
      class="card-header border-0"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
            {{ title }}
          </h3>
        </div>
        <div class="col text-right">
          <a
            id="add_part_btn"
            href="/admin-add-part"
            class="btn btn-sm btn-primary"
            >Add Part</a
          >
          <a href="/admin-upload-parts" class="btn btn-sm btn-warning"
            >Import Parts</a
          >
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <base-table
        class="table align-items-center table-flush"
        :class="type === 'dark' ? 'table-dark' : ''"
        :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
        tbody-classes="list"
        :data="tableData.results"
      >
        <template v-slot:columns>
          <th>Part</th>
          <th>Price</th>
          <th>Vehicle</th>
          <th>Part Type</th>
          <th>Part Quantity</th>
          <th></th>
        </template>

        <template v-slot:default="row">
          <th scope="row">
            <div class="media align-items-center">
              <a
                :href="'/admin-edit-part-' + row.item.id"
                class="avatar rounded-circle mr-3"
              >
                <img
                  alt="Image placeholder"
                  :src="row.item.images[0].get_thumpnail"
                />
              </a>
              <div class="media-body">
                <a
                  style="color: white"
                  :href="'/admin-edit-part-' + row.item.id"
                  ><span class="name mb-0 text-sm">{{ row.item.name }}</span></a
                >
              </div>
            </div>
          </th>
          <td class="budget">
            {{
              row.item.price == -1 ? "Contact for price" : `R${row.item.price}`
            }}
          </td>
          <td>
            <badge class="badge-dot mr-4" :type="`info`">
              <i :class="`bg-info`"></i>
              <span
                class="status"
                v-if="vehicleMakes.length > 0 && vehicleModels.length > 0"
                >{{
                  //`${findMake(row.item.vehicleMake).name} ${
                  //  findModel(row.item.vehicleModel).name
                  //} - ${row.item.vehicleYear}`
                  `${findMake(row.item.vehicleMake)} ${findModel(
                    row.item.vehicleModel
                  )} ${row.item.vehicleYear ? "- " + row.item.vehicleYear : ""}`
                }}</span
              >
            </badge>
          </td>
          <td class="budget">
            {{ row.item.type.toUpperCase() }}
          </td>

          <td>
            <div class="d-flex align-items-center">
              <span class="completion mr-2">{{ row.item.quantity }}</span>
            </div>
          </td>

          <td class="text-right">
            <base-dropdown class="dropdown" position="right">
              <template v-slot:title>
                <a
                  class="btn btn-sm btn-icon-only text-light"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i class="fas fa-ellipsis-v"></i>
                </a>
              </template>

              <template v-slot:default>
                <a
                  class="dropdown-item"
                  :href="'/admin-edit-part-' + row.item.id"
                  ><i class="fa fa-edit"></i>Edit</a
                >
                <a class="dropdown-item" @click="startDeleteProcess(row.item)"
                  ><i class="fa fa-trash"></i>Delete</a
                >
              </template>
            </base-dropdown>
          </td>
        </template>
      </base-table>
    </div>

    <div
      class="card-footer d-flex justify-content-end"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <base-pagination
        @input="handlePageChange"
        :value="currentPage"
        :total="tableData.count"
      ></base-pagination>
    </div>
    <modal v-model:show="deletePartDialog">
      <template v-slot:header>
        <h6 class="modal-title" id="modal-title-default">Delete Part</h6>
      </template>

      <p v-if="deletePart !== null">
        You are about to detete this part: {{ deletePart.name }}
      </p>

      <template v-slot:footer>
        <input
          @click.stop="deletePartPerm(deletePart.id)"
          class="btn btn-danger btn-block"
          value="Continue and delete"
        />
        <base-button @click="deletePartDialog = false" block type="primary"
          >Cancel delete</base-button
        >
      </template>
    </modal>
    <modal v-model:show="deletePartSuccessDialog">
      <template v-slot:header>
        <h6 class="modal-title" id="modal-title-default">Part Deleted</h6>
      </template>

      <p>Part deleted successfully</p>

      <template v-slot:footer>
        <base-button
          block
          @click="deletePartSuccessDialog = false"
          type="secondary"
          >Close</base-button
        >
      </template>
    </modal>
  </div>
</template>
<script>
export default {
  name: "parts-table",
  props: {
    type: {
      type: String,
    },
    service: {
      type: Object,
    },
    tableData: {
      type: Object,
      default: () => {},
    },
    title: String,
  },
  data() {
    return {
      currentPage: 1,
      deletePartDialog: null,
      deletePartSuccessDialog: false,
      deletePart: null,
    };
  },
  computed: {
    loggedInUser() {
      return this.$store.getters.user;
    },
    vehicleMakes() {
      return this.$store.getters["categories/vehicleMakes"];
    },
    vehicleModels() {
      return this.$store.getters["categories/vehicleModels"];
    },
  },
  methods: {
    deletePartPerm(partId) {
      this.deletePartDialog = false;
      try {
        this.$store
          .dispatch("parts/deletePartPermanently", {
            id: partId,
          })
          .then(() => {
            this.deletePartSuccessDialog = true;
          });
      } catch (error) {
        console.log(error);
      }
    },
    startDeleteProcess(part) {
      this.deletePart = {
        id: part.id,
        name: part.name,
      };

      this.deletePartDialog = true;
    },
    async loadVehicleMakes() {
      // this.isLoading = true;
      try {
        await this.$store.dispatch("categories/loadVehicleMakes");
      } catch (error) {
        console.log(error);
      }
      // this.isLoading = false;
    },
    async loadVehicleModels() {
      // this.isLoading = true;
      try {
        await this.$store.dispatch("categories/loadVehicleModels");
      } catch (error) {
        console.log(error);
      }
      // this.isLoading = false;
    },
    findMake(id) {
      let value = this.vehicleMakes.find((o) => o.id === id);
      return value ? value.name : id;
    },
    findModel(id) {
      if (id === null) {
        return "";
      }
      let value = this.vehicleModels.find((o) => o.id === id);
      return value ? value.name : id;
    },
    async handlePageChange(val) {
      await this.getPartsByPage(val);
    },
    async getPartsByPage(page) {
      // this.isLoading = true;
      try {
        if (this.loggedInUser.is_service) {
          await this.$store.dispatch("parts/loadParts", {
            params: {
              p: page,
              serviceSlug: this.service.slug,
            },
          });
          this.currentPage = page;
        } else {
          await this.$store.dispatch("parts/loadParts", {
            params: {
              p: page,
            },
          });
          this.currentPage = page;
        }
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;
    },
  },
  async created() {
    await this.loadVehicleMakes();
    await this.loadVehicleModels();
  },
};
</script>

<style scoped>
#add_part_btn {
  margin-bottom: 0;
}

@media (max-width: 491px) {
  #add_part_btn {
    margin-bottom: 10px;
  }
}
</style>
