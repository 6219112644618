export default {
  addService(state, payload) {
    state.service.push(payload);
  },
  addServiceAddress(state, payload) {
    state.serviceAddresses.push(payload);
  },
  setServices(state, payload) {
    state.services = payload;
  },
  setServiceAddresses(state, payload) {
    state.serviceAddresses = payload;
  },
  setService(state, payload) {
    state.service = payload;
  },
  addCustomer(state, payload) {
    state.customers.push(payload);
  },
  setCustomers(state, payload) {
    state.customers = payload;
  },
  setCustomer(state, payload) {
    state.customer = payload;
  },
  updateService(state, payload) {
    state.service[payload.prop] = payload.value;
  },
  updateCustomer(state, payload) {
    state.customer[payload.prop] = payload.value;
  },
  deleteServiceAddress(state, payload) {
    const p = state.serviceAddresses;
    let index = p.findIndex((e) => e.id === payload);
    p.splice(index, 1);
    state.serviceAddresses = p;
  },
};
