<template>
  <base-wrapper>
    <template v-slot:content>
      <!-- Begin Uren's Breadcrumb Area -->
      <div class="breadcrumb-area">
        <div class="container">
          <div class="breadcrumb-content">
            <ul>
              <li><a href="/">Home</a></li>
              <li class="active">Activate Account</li>
            </ul>
          </div>
        </div>
      </div>
      <!-- Uren's Breadcrumb Area End Here -->
      <!-- Begin Uren's About Us Area -->
      <div class="about-us-area">
        <div class="container-fluid">
          <div class="row">
            <!-- <div class="col-lg-6 col-md-5">
              <div class="overview-img text-center img-hover_effect">
                <a href="#">
                  <img
                    class="img-full"
                    src="@/assets/images/about-us/1.jpg"
                    alt="Uren's About Us Image"
                  />
                </a>
              </div>
            </div> -->
            <div class="col-lg-12 col-md-12 d-flex align-items-center">
              <div class="overview-content">
                <h2>Welcome To <span>PS</span> Link!</h2>
                <p class="short_desc" v-if="resultReady">
                  You have successfully activated your account.
                </p>
                <p style="color: red" class="short_desc" v-if="errorMessage">
                  Failed to activated your account: <br />
                  {{ errorMessage }}
                </p>
                <div v-if="resultReady" class="uren-about-us_btn-area">
                  <a style="margin-bottom: 40px" class="about-us_btn" href="/"
                    >Shop Now</a
                  >
                </div>
                <div v-if="errorMessage" class="uren-about-us_btn-area">
                  <button
                    style="margin-bottom: 40px"
                    @click="showResend = !showResend"
                    class="about-us_btn"
                  >
                    Resend Activation Email
                  </button>
                </div>
              </div>
            </div>
            <div
              v-if="errorMessage && showResend"
              style="margin-bottom: 20px"
              class="col-sm-12 col-md-12 col-lg-6 col-xs-12"
            >
              <Form
                @submit="onSubmitEmail"
                :validation-schema="schema"
                v-slot="{ errors }"
              >
                <div class="login-form">
                  <div class="row">
                    <div class="col-md-12 form-group">
                      <label :class="{ 'invalid-feedback': errors.email }"
                        >Email Address*</label
                      >
                      <Field
                        name="email"
                        class="form-control"
                        :class="{ 'is-invalid': errors.email }"
                        v-model="email"
                        type="email"
                        placeholder="Email Address"
                      />
                      <div class="invalid-feedback">
                        {{ errors.email }}
                      </div>
                    </div>
                    <div class="col-12">
                      <base-alert v-if="success" type="success" dismissible>
                        <span class="alert-inner--text">{{ success }}</span>
                        <button
                          type="button"
                          class="close"
                          data-dismiss="alert"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </base-alert>
                      <button class="uren-register_btn">Resend</button>
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
      <!-- Uren's About Us Area End Here -->
    </template>
    <page-loader v-if="isLoading"></page-loader>
  </base-wrapper>
</template>

<script>
import BaseWrapper from "../../components/website/BaseWrapper.vue";
import PageLoader from "../../components/website/PageLoader.vue";
import { Field, Form } from "vee-validate";
import * as yup from "yup";

export default {
  components: { BaseWrapper, PageLoader, Form, Field },
  data() {
    return {
      isLoading: false,
      resultReady: false,
      errorMessage: "",
      email: "",
      success: "",
      showResend: false,
    };
  },
  computed: {
    schema() {
      const schema = yup.object({
        email: yup.string().required().email(),
      });

      return schema;
    },
  },
  methods: {
    loadActivate() {
      this.isLoading = true;
      try {
        this.$store
          .dispatch("activation", {
            uid: this.$route.params.uid,
            token: this.$route.params.token,
          })
          .then((response) => {
            this.resultReady = true;
            console.log(response);
            this.isLoading = false;
          })
          .catch((err) => {
            this.errorMessage = err.response.data.detail;
            console.log(err.response.data.detail);
            this.isLoading = false;
          });
      } catch (error) {
        console.log(error);
      }
      // this.isLoading = false;
    },
    async onSubmitEmail() {
      this.isLoading = true;

      this.$store
        .dispatch("resendActivation", {
          email: this.email,
        })
        .then((response) => {
          this.success = "Activation email has been send.";
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error.respose);
        });
      this.isLoading = false;
    },
  },
  created() {
    document.title = "PSI Link | Activate Account";
    this.loadActivate();
  },
};
</script>
