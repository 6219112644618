import wishlistService from "../../../services/wishlist.service";

export default {
  async addWishlist(context, data) {
    // const userId = context.rootGetters.userId;

    const token = context.rootGetters.token;
    const response = await wishlistService.createWishlist(token, data.form);
    console.log(response);

    // context.commit("addPart", {
    //   ...coachData,
    //   id: userId,
    // });
  },

  async loadWishlists(context) {
    // if (!payload.forceRefresh && !context.getters.shouldUpdate) {
    //   return;
    // }

    const token = context.rootGetters.token;
    const response = await wishlistService.loadWishlists(token);

    context.commit("setWishlists", response.data);
    // context.commit("setFetchTimeStamp");
  },

  async getWishlist(context, payload) {
    const token = context.rootGetters.token;
    const response = await wishlistService.getWishlist(
      token,
      payload.customerId
    );

    context.commit("setWishlist", response.data);
  },

  updateWishlist(context, payload) {
    context.commit("updateWishlist", payload);
  },

  deletePartWishist(context, payload) {
    context.commit("removePartWishist", payload);
  },

  async patchWishlist(context, payload) {
    const token = context.rootGetters.token;
    let partsNew = [];
    payload.wishlist.parts.forEach((part) => {
      partsNew.push(part.id);
    });
    payload.wishlist.parts = partsNew;
    const response = await wishlistService.patchWishlist(token, payload);

    // console.log(response.data);

    context.commit("setWishlist", response.data);
  },
};
