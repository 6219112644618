<template>
  <div>
    <base-dialog :show="isLoading" title="Loading..." fixed>
      <base-spinner></base-spinner>
    </base-dialog>
    <base-dialog
      :show="pageErrors.length > 0 || successMsg !== ''"
      :title="alertTitle"
      @close="handleError"
    >
      <div v-if="pageErrors.length > 0 && successMsg === ''">
        <base-alert v-for="error in pageErrors" :key="error" type="danger">
          <span class="alert-inner--text">{{ error }}</span>
        </base-alert>
      </div>
      <div v-else>
        <base-alert type="success">
          <span class="alert-inner--text">{{ successMsg }}</span>
        </base-alert>
        <table
          v-if="dataErrors.length > 0"
          class="table table-bordered specification-inner_stuff"
        >
          <tbody>
            <tr v-for="error in dataErrors" :key="error">
              <td>{{ error }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </base-dialog>
    <base-header
      class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
      style="
        min-height: 600px;
        background-image: url(img/theme/car-img.jpg);
        background-size: cover;
        background-position: center top;
      "
    >
      <!-- Mask -->
      <span class="mask bg-gradient-success opacity-8"></span>
      <!-- Header container -->
      <div class="container-fluid d-flex align-items-center">
        <div class="row">
          <div class="col-lg-7 col-md-10"></div>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <!-- <div class="col-xl-4 order-xl-2 mb-5 mb-xl-0"></div> -->

        <div class="col-xl-12 order-xl-1">
          <card shadow type="secondary">
            <template v-slot:header>
              <div class="bg-white border-0">
                <div class="row align-items-center">
                  <div class="col-8">
                    <h3 class="mb-0">Upload Bulk Parts</h3>
                  </div>
                  <div class="col-4 text-right">
                    <a id="add_part_btn" href="/admin-parts" class="btn btn-sm btn-primary"
                      >All Parts</a
                    >
                    <a href="/admin-add-part" class="btn btn-sm btn-warning"
                      >Add New Part</a
                    >
                  </div>
                </div>
              </div>
            </template>

            <Form
              @submit="onSubmit"
              :validation-schema="schema"
              v-slot="{ errors }"
              enctype="multipart/form-data"
              id="addPartForm"
            >
              <h6 class="heading-small text-muted mb-4">Upload Information</h6>
              <div class="pl-lg-4">
                <div class="row">
                  <div class="col-lg-12 form-group">
                    <label :class="{ 'error-show': errors.file }"
                      >Upload Spreadsheet File</label
                    >
                    <Field
                      name="file"
                      class="form-control"
                      accept=".xlsx"
                      :class="{ 'is-invalid': errors.file }"
                      @change="onChange($event)"
                      type="file"
                      placeholder="Upload Spreadsheet File"
                    />
                    <div class="invalid-feedback">
                      {{ errors.file }}
                    </div>
                  </div>
                </div>
              </div>
              <hr class="my-4" />
              <div class="pl-lg-4">
                <base-button
                  type="primary"
                  nativeType="submit"
                  block
                  icon="ni ni-bag-17"
                  >Load New Parts</base-button
                >
              </div>
            </Form>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Field, Form } from "vee-validate";
import * as yup from "yup";

export default {
  components: { Form, Field },
  data() {
    return {
      isLoading: false,
      pageErrors: [],
      dataErrors: [],
      successMsg: "",
      file: null,
    };
  },
  computed: {
    typeValid() {
      return this.advert.advertType !== null;
    },
    serviceValid() {
      return this.advert.service !== null;
    },
    schema() {
      const schema = yup.object({
        file: yup.mixed().required("File is required"),
      });

      return schema;
    },
    services() {
      const services = [];
      const servArr = this.$store.getters["services/services"];
      for (let index = 0; index < servArr.length; index++) {
        let element = { label: servArr[index].name, value: servArr[index].id };
        services.push(element);
      }
      return services;
    },
    service() {
      return this.$store.getters["services/service"];
    },
    isLoggedIn() {
      return this.$store.getters.isAuthenticated;
    },
    loggedInUser() {
      return this.$store.getters.user;
    },
    loggedInUserId() {
      return this.$store.getters.userId;
    },
    alertTitle() {
      if (this.pageErrors.length > 0 && this.successMsg === "") {
        return this.pageErrors.length === 1
          ? "An Error has occurred"
          : "A couple of errors have occured";
      } else {
        return "Details Update";
      }
    },
  },
  async created() {
    if (this.loggedInUser.is_superuser) {
      await this.loadServices();
    } else {
      // this.advert.service = this.loggedInUserId;
      await this.loadService();
    }
  },
  methods: {
    onChange(event) {
      this.file = event.target.files[0];
      console.log(this.file);
    },
    async loadServices() {
      this.isLoading = true;
      try {
        await this.$store.dispatch("services/loadServices");
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;
    },
    async loadService() {
      this.isLoading = true;
      try {
        await this.$store.dispatch("services/getService", {
          serviceId: this.loggedInUser.id,
        });
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;
    },
    async onSubmit() {
      this.isLoading = true;
      const formData = new FormData();
      formData.append("file", this.file);
      try {
        const res = await this.$store.dispatch("parts/uploadParts", {
          form: formData,
        });
        if (res.data_errors.length > 0) {
          this.successMsg =
            "Successfully some parts, pleace check log report below for data errors.";
          this.dataErrors = res.data_errors;
        } else {
          this.successMsg = "Successfully saved all the parts.";
        }

        this.file = null;
      } catch (error) {
        console.log(error.response.data);
        if (error.response.data.error) {
          this.pageErrors.push(error.response.data.error);
        } else if (error.response.data.non_field_errors) {
          this.pageErrors = error.response.data.non_field_errors;
        } else {
          for (const key in error.response.data) {
            this.pageErrors.push(
              error.response.data[key][0] + " (" + key.toUpperCase() + ")"
            );
          }
        }
      }
      this.file = null;
      this.isLoading = false;
    },
    handleError() {
      this.pageErrors = [];
      this.successMsg = "";
    },
  },
};
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped>
.error-show {
  color: #fb6340;
}

#add_part_btn {
  margin-bottom: 0;
}

@media (max-width: 981px){
  #add_part_btn {
    margin-bottom: 10px;
  }
}
</style>
