<template>
  <base-wrapper>
    <template v-slot:content>
      <page-loader v-if="isLoading"></page-loader>
      <!-- Begin Uren's Breadcrumb Area -->
      <div class="breadcrumb-area">
        <div class="container">
          <div class="breadcrumb-content">
            <ul>
              <li><a href="index.html">Home</a></li>
              <li class="active">Contact</li>
            </ul>
          </div>
        </div>
      </div>
      <!-- Uren's Breadcrumb Area End Here -->
      <!-- Begin Contact Main Page Area -->
      <div class="contact-main-page">
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-5 offset-lg-1 col-md-12 order-1 order-lg-2">
              <div class="contact-page-side-content">
                <h3 class="contact-page-title">Contact Us</h3>
                <p class="contact-page-message">
                  Please contact us if you need any more information?
                </p>
                <!-- <div class="single-contact-block">
                  <h4><i class="fa fa-fax"></i> Address</h4>
                  <p>123 Street, Johannesburg, Gauteng, 12345 – South Africa</p>
                </div> -->
                <div class="single-contact-block">
                  <h4><i class="fa fa-phone"></i> Phone</h4>
                  <p>Mobile: +27 (0) 78 893 4978</p>
                  <!-- <p>Hotline: 0000000000</p> -->
                </div>
                <div class="single-contact-block last-child">
                  <h4><i class="fa fa-envelope-o"></i> Email</h4>
                  <p>info@pslink.co.za</p>
                  <p>support@pslink.co.za</p>
                  <p>mark@pslink.co.za</p>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-12 order-2 order-lg-1">
              <div class="contact-form-content">
                <h3 class="contact-page-title">Tell Us Your Message</h3>
                <div class="contact-form">
                  <base-alert
                    v-if="loginErrors.length > 0"
                    type="danger"
                    dismissible
                  >
                    <!-- <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span> -->
                    <span
                      class="alert-inner--text"
                      v-for="error in loginErrors"
                      :key="error"
                      >{{ error }}</span
                    >
                    <button
                      type="button"
                      class="close"
                      data-dismiss="alert"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </base-alert>
                  <Form
                    id="contact-form"
                    @submit="onSubmit"
                    :validation-schema="schema"
                    v-slot="{ errors }"
                    enctype="multipart/form-data"
                  >
                    <div class="form-group">
                      <label :class="{ 'error-show': errors.name }"
                        >Your Name <span class="required">*</span></label
                      >
                      <Field
                        name="name"
                        class="form-control"
                        :class="{ 'is-invalid': errors.name }"
                        v-model="name"
                        type="text"
                        placeholder="Your Name"
                      />
                      <div class="invalid-feedback">
                        {{ errors.name }}
                      </div>
                      <!-- <input
                        type="text"
                        name="con_name"
                        id="con_name"
                        required
                      /> -->
                    </div>
                    <div class="form-group">
                      <label :class="{ 'error-show': errors.email }"
                        >Your Email <span class="required">*</span></label
                      >
                      <Field
                        name="email"
                        class="form-control"
                        :class="{ 'is-invalid': errors.email }"
                        v-model="email"
                        type="email"
                        placeholder="Your Email"
                      />
                      <div class="invalid-feedback">
                        {{ errors.email }}
                      </div>
                      <!-- <input
                        type="email"
                        name="con_email"
                        id="con_email"
                        required
                      /> -->
                    </div>
                    <div class="form-group">
                      <label :class="{ 'error-show': errors.subject }"
                        >Subject <span class="required">*</span></label
                      >
                      <Field
                        name="subject"
                        class="form-control"
                        :class="{ 'is-invalid': errors.subject }"
                        v-model="subject"
                        type="text"
                        placeholder="Subject"
                      />
                      <div class="invalid-feedback">
                        {{ errors.subject }}
                      </div>
                      <!-- <input type="text" name="con_subject" id="con_subject" /> -->
                    </div>
                    <div class="form-group form-group-2">
                      <label :class="{ 'error-show': errors.message }"
                        >Message <span class="required">*</span></label
                      >
                      <Field
                        as="textarea"
                        name="message"
                        class="form-control"
                        :class="{ 'is-invalid': errors.message }"
                        v-model="message"
                        rows="3"
                        placeholder="Message"
                      />
                      <div class="invalid-feedback">
                        {{ errors.message }}
                      </div>
                      <!-- <textarea name="con_message" id="con_message"></textarea> -->
                    </div>

                    <base-alert v-if="success" type="success" dismissible>
                      <span class="alert-inner--text">{{ success }}</span>
                      <button
                        type="button"
                        class="close"
                        data-dismiss="alert"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </base-alert>

                    <div class="form-group">
                      <button
                        type="submit"
                        value="submit"
                        id="submit"
                        class="uren-contact-form_btn"
                        name="submit"
                      >
                        send
                      </button>
                    </div>
                  </Form>
                </div>
                <p class="form-messege"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Contact Main Page Area End Here -->
    </template>
  </base-wrapper>
</template>

<script>
import BaseWrapper from "../../components/website/BaseWrapper.vue";
import PageLoader from "../../components/website/PageLoader.vue";
import { Field, Form } from "vee-validate";
import * as yup from "yup";

export default {
  components: { PageLoader, BaseWrapper, Field, Form },
  data() {
    return {
      isLoading: false,
      name: "",
      email: "",
      subject: "",
      message: "",
      success: "",
      loginErrors: [],
    };
  },
  computed: {
    schema() {
      const schema = yup.object({
        name: yup.string().required(),
        email: yup.string().required().email(),
        subject: yup.string().required(),
        message: yup.string().required(),
      });

      return schema;
    },
  },
  methods: {
    async onSubmit() {
      this.isLoading = true;
      try {
        await this.$store.dispatch("contactUsPost", {
          name: this.name,
          email: this.email,
          subject: this.subject,
          message: this.message,
        });
        this.success = "Your message has been sent successfully";
      } catch (error) {
        console.log(error.response.data);
        if (error.response.data.non_field_errors) {
          this.loginErrors = error.response.data.non_field_errors;
        } else {
          for (const key in error.response.data) {
            this.loginErrors.push(
              error.response.data[key][0] + " (" + key.toUpperCase() + ")"
            );
          }
        }
      }

      this.isLoading = false;
    },
  },
  created() {
    document.title = "PSI Link | Contact";
  },
};
</script>
<style scoped>
.error-show {
  color: #fb6340;
}
</style>
