import servicesService from "../../../services/services.service";

export default {
  async addService(context, data) {
    const token = context.rootGetters.token;
    const response = await servicesService.createService(token, data.form);
    console.log(response);

    // context.commit("addPart", {
    //   ...coachData,
    //   id: userId,
    // });
  },

  async addServiceAddress(context, data) {
    const token = context.rootGetters.token;
    const response = await servicesService.createServiceAddress(token, data);
    console.log(response);

    context.commit("addServiceAddress", response.data);
  },

  async addCustomer(context, data) {
    const token = context.rootGetters.token;
    const response = await servicesService.createCustomer(token, data.form);
    console.log(response);

    // context.commit("addPart", {
    //   ...coachData,
    //   id: userId,
    // });
  },

  async loadServices(context) {
    const token = context.rootGetters.token;
    const response = await servicesService.getServices(token);
    console.log(response.data);

    context.commit("setServices", response.data);
  },

  async loadServiceAddresses(context) {
    const token = context.rootGetters.token;
    const response = await servicesService.getServiceAddresses(token);
    console.log(response.data);

    context.commit("setServiceAddresses", response.data);
  },

  async loadCustomers(context) {
    const token = context.rootGetters.token;
    const response = await servicesService.getCustomers(token);
    console.log(response.data);

    // context.commit("setPartTypes", response.data);
  },

  async getService(context, payload) {
    const token = context.rootGetters.token;
    const response = await servicesService.getService(token, payload.serviceId);

    context.commit("setService", response.data);
  },

  // async getServiceAddress(context, payload) {
  //   const token = context.rootGetters.token;
  //   const response = await servicesService.getServiceAddress(token, payload.serviceId);

  //   context.commit("setServiceAddress", response.data);
  // },

  async getServiceAddressByFilter(context, payload = null) {
    const token = context.rootGetters.token;
    // use to filter and pagination calls
    let params = "?";
    if (payload !== null) {
      for (const key in payload.params) {
        params = params + `${key}=${payload.params[key]}&`;
      }
      params.slice(0, -1);
    }
    const loadpay = payload !== null ? params : "";
    const response = await servicesService.getServiceAddressesByFilter(
      loadpay,
      token
    );

    context.commit("setServiceAddresses", response.data);
  },

  async getCustomer(context, payload) {
    const token = context.rootGetters.token;
    const response = await servicesService.getCustomer(
      token,
      payload.customerId
    );

    context.commit("setCustomer", response.data);
  },

  async updateServiceInfo(context, payload) {
    const token = context.rootGetters.token;
    const response = await servicesService.updateService(token, payload);
    // console.log(response.data);
    context.commit("setService", response.data);
  },

  async updateCustomerInfo(context, payload) {
    const token = context.rootGetters.token;
    const response = await servicesService.updateCustomer(token, payload);
    // console.log(response.data);
    context.commit("setCustomer", response.data);
  },

  updateService(context, payload) {
    context.commit("updateService", payload);
  },

  updateCustomer(context, payload) {
    context.commit("updateCustomer", payload);
  },

  async deleteServAddressPermanently(context, payload) {
    const token = context.rootGetters.token;
    const response = await servicesService.deleteServiceAddress(token, payload);
    console.log(response.data);
    context.commit("deleteServiceAddress", payload.id);
  },
};
