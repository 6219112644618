import partService from "../../../services/parts.service";

export default {
  async addPart(context, data) {
    // const userId = context.rootGetters.userId;

    const token = context.rootGetters.token;
    const response = await partService.createPart(token, data.form);

    context.commit("addPart", response.data);
    return true;
  },

  async uploadParts(context, data) {
    // const userId = context.rootGetters.userId;

    const token = context.rootGetters.token;
    const response = await partService.uploadParts(token, data.form);
    return response.data;
  },

  async loadParts(context, payload = null) {
    // use to filter and pagination calls
    let params = "?";
    if (payload !== null) {
      for (const key in payload.params) {
        params = params + `${key}=${payload.params[key]}&`;
      }
      params.slice(0, -1);
    }
    const token = context.rootGetters.token;
    const loadpay = payload !== null ? params : "";
    const response = await partService.getParts(token, loadpay);

    context.commit("setParts", response.data);
  },

  async getPart(context, payload) {
    const token = context.rootGetters.token;
    const response = await partService.getPart(token, payload);

    context.commit("setPart", response.data);
  },

  async loadPartsWeb(context, payload = null) {
    const token = context.rootGetters.token;
    // use to filter and pagination calls
    let params = "?";
    if (payload !== null) {
      for (const key in payload.params) {
        params = params + `${key}=${payload.params[key]}&`;
      }
      params.slice(0, -1);
    }
    const loadpay = payload !== null ? params : "";
    const response = await partService.getPartsWeb(loadpay, token);

    context.commit("setParts", response.data);
  },
  async updatePartInfo(context, payload) {
    const token = context.rootGetters.token;
    delete payload.part.images;
    const response = await partService.updatePart(token, payload);
    context.commit("setPart", response.data);
  },

  async deletePartPermanently(context, payload) {
    const token = context.rootGetters.token;
    await partService.deletePart(token, payload);
    context.commit("deletePart", payload.id);
  },

  updatePart(context, payload) {
    context.commit("updatePart", payload);
  },
};
