<template>
  <div>
    <base-dialog :show="isLoading" title="Loading..." fixed>
      <base-spinner></base-spinner>
    </base-dialog>
    <base-dialog
      :show="pageErrors.length > 0 || successMsg !== ''"
      :title="alertTitle"
      @close="handleError"
    >
      <div v-if="pageErrors.length > 0 && successMsg === ''">
        <base-alert v-for="error in pageErrors" :key="error" type="danger">
          <span class="alert-inner--text">{{ error }}</span>
        </base-alert>
      </div>
      <div v-else>
        <base-alert type="success">
          <span class="alert-inner--text">{{ successMsg }}</span>
        </base-alert>
      </div>
    </base-dialog>
    <base-header
      class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
      style="
        min-height: 600px;
        background-image: url(img/theme/car-img.jpg);
        background-size: cover;
        background-position: center top;
      "
    >
      <!-- Mask -->
      <span class="mask bg-gradient-success opacity-8"></span>
      <!-- Header container -->
      <div class="container-fluid d-flex align-items-center">
        <div class="row">
          <div class="col-lg-12 col-md-12">
            <h1 class="display-2 text-white">
              {{
                `Hello, ${loggedInUser.first_name} ${loggedInUser.last_name}`
              }}
            </h1>
            <p class="text-white mt-0 mb-5">
              This is your profile page. You can update your profile details
              <span v-if="hasService && service.subscription === null">
                <br />You haven't selected a subscription yet, please select and
                pay. After select the type of service you require based on your
                subscription.
              </span>
            </p>
            <!-- <a href="#!" class="btn btn-info">Edit profile</a> -->
          </div>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-xl-12 order-xl-1">
          <card shadow type="secondary">
            <template v-slot:header>
              <div class="bg-white border-0">
                <div class="row align-items-center">
                  <div class="col-8">
                    <h3 class="mb-0">My account</h3>
                  </div>
                  <div class="col-4 text-right">
                    <!-- <a href="#!" class="btn btn-sm btn-primary">Settings</a> -->
                  </div>
                </div>
              </div>
            </template>

            <Form
              @submit="onSubmit"
              :validation-schema="schema"
              v-slot="{ errors }"
              enctype="multipart/form-data"
              id="userProfileForm"
            >
              <h6 class="heading-small text-muted mb-4">User information</h6>
              <div class="pl-lg-4">
                <div class="row">
                  <div class="col-lg-6 form-group">
                    <label :class="{ 'error-show': errors.username }"
                      ><strong>Username</strong></label
                    >
                    <Field
                      name="username"
                      class="form-control"
                      :class="{ 'is-invalid': errors.username }"
                      :value="loggedInUser.username"
                      type="text"
                      @input="updateState('username', $event)"
                      placeholder="Username"
                    />
                    <div class="invalid-feedback">
                      {{ errors.username }}
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <label :class="{ 'error-show': errors.email }"
                      ><strong>Email Address</strong></label
                    >
                    <Field
                      name="email"
                      class="form-control"
                      :class="{ 'is-invalid': errors.email }"
                      :value="loggedInUser.email"
                      type="text"
                      @input="updateState('email', $event)"
                      placeholder="Email Address"
                    />
                    <div class="invalid-feedback">
                      {{ errors.email }}
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6 form-group">
                    <label :class="{ 'error-show': errors.first_name }"
                      ><strong>First Name</strong></label
                    >
                    <Field
                      name="first_name"
                      class="form-control"
                      :class="{ 'is-invalid': errors.first_name }"
                      :value="loggedInUser.first_name"
                      @input="updateState('first_name', $event)"
                      type="text"
                      placeholder="First Name"
                    />
                    <div class="invalid-feedback">
                      {{ errors.first_name }}
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <label :class="{ 'error-show': errors.last_name }"
                      ><strong>Last Name</strong></label
                    >
                    <Field
                      name="last_name"
                      class="form-control"
                      :class="{ 'is-invalid': errors.last_name }"
                      :value="loggedInUser.last_name"
                      type="text"
                      @input="updateState('last_name', $event)"
                      placeholder="Last Name"
                    />
                    <div class="invalid-feedback">
                      {{ errors.last_name }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="pl-lg-4">
                <div class="row">
                  <div class="col-lg-12">
                    <!-- <base-button
                      type="info"
                      @click.prevent="onSubmitUpdateUser"
                      block
                      icon="ni ni-bag-17"
                      >Save User Information</base-button> -->
                    <button
                      @click="onSubmitUpdateUser"
                      class="btn btn-info btn-block"
                    >
                      <em class="ni ni-bag-17"></em>
                      Save User Information
                    </button>
                  </div>
                </div>
              </div>
              <hr class="my-4" />
              <!-- Service -->
              <h6
                v-if="loggedInUser.is_service"
                class="heading-small text-muted mb-4"
              >
                Service information
              </h6>
              <div v-if="loggedInUser.is_service && hasService" class="pl-lg-4">
                <div class="row">
                  <div class="col-lg-6 form-group">
                    <label :class="{ 'error-show': errors.companyName }"
                      ><strong>Company Name*</strong></label
                    >
                    <Field
                      name="companyName"
                      class="form-control"
                      :class="{ 'is-invalid': errors.companyName }"
                      :value="service.companyName"
                      type="text"
                      @input="updateServiceState('companyName', $event)"
                      placeholder="Company Name"
                    />
                    <div class="invalid-feedback">
                      {{ errors.companyName }}
                    </div>
                  </div>
                  <div class="col-lg-6 form-group">
                    <label :class="{ 'error-show': errors.companyName }"
                      ><strong>Availability*</strong></label
                    >
                    <Field
                      name="availability"
                      class="form-control"
                      :class="{ 'is-invalid': errors.availability }"
                      :value="service.availability"
                      type="text"
                      @input="updateServiceState('availability', $event)"
                      placeholder="Availability"
                    />
                    <div class="invalid-feedback">
                      {{ errors.availability }}
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div
                    :class="
                      service.serviceKind === 'service_provider'
                        ? 'col-lg-6'
                        : 'col-lg-12'
                    "
                    class="form-group"
                  >
                    <label :class="{ 'error-show': errors.businessEmail }"
                      ><strong>Business Email</strong></label
                    >
                    <Field
                      name="businessEmail"
                      class="form-control"
                      :class="{ 'is-invalid': errors.businessEmail }"
                      :value="serviceCp.businessEmail"
                      type="email"
                      @input="updateServiceState('businessEmail', $event)"
                      placeholder="Business Email"
                    />
                    <div class="invalid-feedback">
                      {{ errors.businessEmail }}
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div
                    class="col-lg-6 form-group"
                    v-if="service.serviceKind === 'service_provider'"
                  >
                    <label :class="{ 'error-show': errors.hourlyRate }"
                      ><strong>Hourly Rate</strong></label
                    >
                    <Field
                      name="hourlyRate"
                      class="form-control"
                      :class="{ 'is-invalid': errors.hourlyRate }"
                      :value="serviceCp.hourlyRate"
                      type="number"
                      @input="updateServiceState('hourlyRate', $event)"
                      placeholder="Hourly Rate"
                    />
                    <div class="invalid-feedback">
                      {{ errors.hourlyRate }}
                    </div>
                  </div>
                  <div
                    :class="
                      service.serviceKind === 'service_provider'
                        ? 'col-lg-6'
                        : 'col-lg-12'
                    "
                    class="form-group"
                  >
                    <label :class="{ 'error-show': errors.landline }"
                      ><strong>Phone - Landline</strong></label
                    >
                    <Field
                      name="landline"
                      class="form-control"
                      :class="{ 'is-invalid': errors.landline }"
                      :value="serviceCp.landline"
                      type="text"
                      @input="updateServiceState('landline', $event)"
                      placeholder="Phone - Landline"
                    />
                    <div class="invalid-feedback">
                      {{ errors.landline }}
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6 form-group">
                    <label :class="{ 'error-show': errors.mobile }"
                      ><strong>Phone - Mobile*</strong></label
                    >
                    <Field
                      name="mobile"
                      class="form-control"
                      :class="{ 'is-invalid': errors.mobile }"
                      :value="serviceCp.mobile"
                      type="text"
                      @input="updateServiceState('mobile', $event)"
                      placeholder="Phone - Mobile"
                    />
                    <div class="invalid-feedback">
                      {{ errors.mobile }}
                    </div>
                  </div>
                  <div class="col-lg-6 form-group">
                    <label :class="{ 'error-show': errors.specialty }"
                      ><strong>Specialty</strong></label
                    >
                    <Field
                      v-slot="{ field }"
                      name="specialty"
                      class="form-control"
                      :value="serviceCp.specialty"
                      @input="updateServiceMState('specialty', $event)"
                    >
                      <Multiselect
                        v-model="serviceCp.specialty"
                        v-bind="field"
                        placeholder="Select Specialty"
                        mode="tags"
                        :closeOnSelect="false"
                        :searchable="true"
                        :options="specialties"
                      />
                    </Field>
                    <div class="invalid-feedback">
                      {{ errors.specialty }}
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6 form-group">
                    <label :class="{ 'error-show': errors.serviceProvided }"
                      ><strong>Select Service Provided*</strong></label
                    >
                    <Field
                      v-slot="{ field }"
                      name="serviceProvided"
                      class="form-control"
                      :value="serviceCp.serviceProvided"
                      @input="updateServiceMState('serviceProvided', $event)"
                    >
                      <Multiselect
                        v-bind="field"
                        v-model="serviceCp.serviceProvided"
                        placeholder="Select Service Provided"
                        mode="tags"
                        :closeOnSelect="false"
                        :searchable="true"
                        :options="[
                          {
                            value: 'auto_technician',
                            label: 'Auto Technician',
                          },
                          {
                            value: 'heavy_commecial_technician',
                            label: 'Heavy Commercial Technician',
                          },
                          {
                            value: 'auto_electrician',
                            label: 'Auto Electrician',
                          },
                          {
                            value: 'specialist_investigator_technical',
                            label: 'Specialist Investigator Technical',
                          },
                          {
                            value: 'specialist_investigator_accident',
                            label: 'Specialist Investigator Accident',
                          },
                          {
                            value: 'specialist_investigator_fire',
                            label: 'Specialist Investigator Fire',
                          },
                        ]"
                      />
                    </Field>
                    <div class="invalid-feedback">
                      {{ errors.serviceProvided }}
                    </div>
                  </div>
                  <div class="col-lg-6 form-group">
                    <label :class="{ 'error-show': errors.serviceType }"
                      ><strong>Select Service Type*</strong></label
                    >
                    <Field
                      v-slot="{ field }"
                      name="serviceType"
                      class="form-control"
                      :value="serviceCp.serviceType"
                      @input="updateServiceMState('serviceType', $event)"
                    >
                      <Multiselect
                        v-bind="field"
                        v-model="serviceCp.serviceType"
                        placeholder="Select Service Type"
                        mode="tags"
                        :closeOnSelect="false"
                        :searchable="true"
                        :options="[
                          { value: 'field_service', label: 'Field Service' },
                          {
                            value: 'workshop',
                            label: 'Workshop',
                          },
                          {
                            value: 'parts',
                            label: 'Parts',
                          },
                        ]"
                      />
                    </Field>
                    <div class="invalid-feedback">
                      {{ errors.serviceType }}
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12 form-group">
                    <label :class="{ 'error-show': errors.description }"
                      ><strong>Profile Description</strong></label
                    >
                    <Field
                      as="textarea"
                      :value="serviceCp.description"
                      name="description"
                      class="form-control"
                      rows="3"
                      @input="updateServiceState('description', $event)"
                      placeholder="Profile Description..."
                    >
                    </Field>
                    <div
                      class="invalid-feedback"
                      :style="
                        errors.description
                          ? { display: 'block' }
                          : { display: 'none' }
                      "
                    >
                      {{ errors.description }}
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12 form-group">
                    <label :class="{ 'error-show': errors.qualifications }"
                      ><strong>Service Qualifications</strong></label
                    >
                    <Field
                      as="textarea"
                      :value="serviceCp.qualifications"
                      name="qualifications"
                      class="form-control"
                      rows="3"
                      @input="updateServiceState('qualifications', $event)"
                      placeholder="Service Qualifications..."
                    >
                    </Field>
                    <div
                      class="invalid-feedback"
                      :style="
                        errors.qualifications
                          ? { display: 'block' }
                          : { display: 'none' }
                      "
                    >
                      {{ errors.qualifications }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="pl-lg-4">
                <div class="row">
                  <div class="col-lg-12">
                    <!-- <base-button
                      type="info"
                      @click="test"
                      block
                      icon="ni ni-bag-17"
                      >Save Service Information</base-button> -->
                    <button
                      @click="onSubmitUpdateService"
                      class="btn btn-info btn-block"
                    >
                      Save Service Information
                    </button>
                  </div>
                </div>
              </div>
              <hr class="my-4" />
              <!-- Address -->
              <h6
                v-if="loggedInUser.is_service"
                class="heading-small text-muted mb-4"
              >
                Address information
              </h6>
              <div v-if="loggedInUser.is_service" class="pl-lg-4">
                <div class="container" style="margin-bottom: 40px">
                  <div class="row">
                    <div class="col-lg-4 col-sm-12">
                      <a
                        @click="showAddressInput()"
                        class="btn btn-primary block"
                        ><i class="ni ni-shop"></i> Add Address</a
                      >
                    </div>
                    <div class="col-lg-8 col-sm-12" v-if="addAddress">
                      <vue-google-autocomplete
                        id="map"
                        classname="form-control"
                        placeholder="Please type your address"
                        v-on:placechanged="getAddressData"
                        country="za"
                      >
                      </vue-google-autocomplete>
                    </div>
                  </div>
                </div>
                <div v-if="hasServiceAddresses" class="container">
                  <div
                    class="row"
                    style="
                      border: 1px solid rgba(0, 0, 0, 0.1);
                      padding-top: 1.5rem;
                    "
                    v-for="add in serviceAddresses"
                    :key="add.id"
                  >
                    <div class="col-lg-9 col-xs-12">
                      <base-input
                        alternative=""
                        placeholder="Address"
                        :value="add.address"
                        input-classes="form-control-alternative"
                      />
                    </div>
                    <div class="col-lg-3 col-xs-12">
                      <!-- <base-button
                        type="primary"
                        sm
                        icon="fa fa-edit"
                      ></base-button> -->
                      <a
                        style="font-size: 20px"
                        class="btn btn-primary btn-sm"
                        @click="deleteServiceAddressPerm(add.id)"
                        ><i class="fa fa-trash"></i
                      ></a>
                    </div>
                  </div>
                </div>
              </div>
              <hr class="my-4" />
              <!-- Address -->
              <h6 class="heading-small text-muted mb-4">Package information</h6>
              <div v-if="hasPackage" class="pl-lg-4">
                <div class="row">
                  <div class="col-lg-4">
                    <base-input
                      disabled
                      alternative=""
                      label="Is Active"
                      placeholder="Is Active"
                      :value="
                        service.subscription.isActive ? 'Active' : 'Not Active'
                      "
                      input-classes="form-control-alternative"
                    />
                  </div>
                  <div class="col-lg-4">
                    <base-input
                      alternative=""
                      label="Payment Recurring"
                      placeholder="Payment Recurring"
                      :value="service.subscription.recurring ? 'Yes' : 'No'"
                      input-classes="form-control-alternative"
                    />
                  </div>
                  <div class="col-lg-4">
                    <base-input
                      disabled
                      alternative=""
                      label="Payment Date"
                      :value="service.subscription.paymentDate"
                      placeholder="Payment Date"
                      input-classes="form-control-alternative"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12">
                    <base-button
                      type="warning"
                      @click="test"
                      block
                      icon="ni ni-bag-17"
                      >Change Package (Current:
                      {{ service.subscription.subscriptionType }})</base-button
                    >
                  </div>
                </div>
              </div>
              <div v-else class="pl-lg-4">
                <div class="row">
                  <div class="col-lg-12">
                    <base-button
                      type="warning"
                      @click="test"
                      block
                      icon="ni ni-bag-17"
                      >Select A Package</base-button
                    >
                  </div>
                </div>
              </div>
              <hr class="my-4" />
              <!-- Description -->
            </Form>
          </card>
        </div>
      </div>
    </div>
    <modal
      v-model:show="profileSelectPackage"
      body-classes="p-0"
      modal-classes="modal-dialog-centered modal-xl"
    >
      <card
        type="secondary"
        shadow
        header-classes="bg-white pb-5"
        body-classes="px-lg-5 py-lg-5"
        class="border-0"
      >
        <template v-slot:header>
          <div class="text-muted text-center mb-3">
            <h2 class="modal-title">Select Package</h2>
          </div>
        </template>
        <div class="row">
          <div
            class="col-xl-6 order-xl-2 mb-5 mb-xl-0"
            v-for="subType in subscription_types"
            :key="subType.id"
          >
            <div class="card card-profile shadow">
              <div class="row justify-content-center">
                <div class="col-lg-3 order-lg-2">
                  <div class="card-profile-image">
                    <a href="#">
                      <img src="img/theme/team-4-800x800.jpg" />
                    </a>
                  </div>
                </div>
              </div>
              <div
                class="card-header text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4"
              >
                <div class="d-flex justify-content-between">
                  <!-- <base-button size="sm" type="info" class="mr-4"
                    >Connect</base-button
                  > -->
                  <!-- <base-button size="sm" type="default" class="float-right"
                    >Message</base-button
                  > -->
                </div>
              </div>
              <div class="card-body pt-0 pt-md-4">
                <div class="row">
                  <div class="col">
                    <div
                      class="card-profile-stats d-flex justify-content-center mt-md-5"
                    >
                      <div>
                        <span class="heading">R{{ subType.price }}</span>
                        <span class="description">{{ subType.name }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="text-center">
                  <hr class="my-4" />
                  <p style="min-height: 100px">
                    {{ subType.description }}
                  </p>
                  <base-button
                    :disabled="checkSub(subType.name)"
                    size="md"
                    block
                    :type="checkSub(subType.name) ? 'warning' : 'info'"
                    class="mr-4"
                    icon="ni ni-bag-17"
                    @click.once="selectPackage(subType)"
                  >
                    {{ checkSub(subType.name) ? "Current Package" : "Select" }}
                  </base-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </card>
    </modal>
    <modal v-model:show="deletePartSuccessDialog">
      <template v-slot:header>
        <h6 class="modal-title" id="modal-title-default">Address Deleted</h6>
      </template>

      <p>Your address deleted successfully</p>

      <template v-slot:footer>
        <base-button
          block
          @click="deletePartSuccessDialog = false"
          type="secondary"
          >Close</base-button
        >
      </template>
    </modal>
  </div>
</template>
<script>
import { Field, Form } from "vee-validate";
import Multiselect from "@vueform/multiselect";
// import UploadImages from "vue-upload-drop-images";
import modal from "../components/Modal.vue";
import * as yup from "yup";
import VueGoogleAutocomplete from "vue-google-autocomplete";

export default {
  name: "user-profile",
  components: { Form, Field, Multiselect, modal, VueGoogleAutocomplete },
  data() {
    return {
      profileSelectPackage: false,
      deletePartSuccessDialog: false,
      addAddress: false,
      isLoading: false,
      successMsg: "",
      pageErrors: [],
      address: {
        address: "",
        reqion: "",
        googleLat: "",
        googleLong: "",
      },
      address_line: "",
      specialties: [
        { label: "Petrol", value: "petrol" },
        { label: "Diesel", value: "diesel" },
        { label: "Truck", value: "truck" },
        { label: "Bus", value: "bus" },
        { label: "Car", value: "car" },
        { label: "Motorcycle", value: "motorcycle" },
        { label: "Commercial Trailers", value: "trailers" },
        { label: "AC", value: "ac" },
        { label: "Hydraulics", value: "hydraulics" },
        { label: "Pneumatic", value: "pneumatic" },
        { label: "Technical Reports", value: "technical_reports" },
        { label: "Assessments", value: "assessments" },
        { label: "Fire Inspections", value: "fire_inspections" },
        { label: "Metallurgy", value: "metallurgy" },
        { label: "Tribology", value: "tribology" },
        { label: "Component Failure", value: "component_failure" },
      ],
    };
  },
  computed: {
    alertTitle() {
      if (this.pageErrors.length > 0 && this.successMsg === "") {
        return this.pageErrors.length === 1
          ? "An Error has occurred"
          : "A couple of errors have occured";
      } else {
        return "Details Update";
      }
    },
    isLoggedIn() {
      return this.$store.getters.isAuthenticated;
    },
    loggedInUser() {
      return this.$store.getters.user;
    },
    service() {
      return this.$store.getters["services/service"];
    },
    hasServiceAddresses() {
      return this.$store.getters["services/hasServiceAddresses"];
    },
    serviceAddresses() {
      return this.$store.getters["services/serviceAddresses"];
    },
    serviceCp() {
      if (this.hasService) {
        return { ...this.service };
      }
      return {};
    },
    subscription_types() {
      return this.$store.getters["subscription_types/subscription_types"];
    },
    hasSubscriptionTypes() {
      return this.$store.getters["subscription_types/hasSubscriptionTypes"];
    },
    hasService() {
      return this.$store.getters["services/hasService"];
    },
    schema() {
      const schema = yup.object({
        first_name: yup.string().required(),
        last_name: yup.string().required(),
        username: yup.string().required(),
        email: yup.string().required().email(),
        availability: yup.string().required(),
        companyName: yup.string().required(),
        delivery_type: yup.string().required(),
        description: yup.string().nullable(),
        hourlyRate: yup.string().nullable(),
        businessEmail: yup.string().email().nullable(),
        landline: yup.string().nullable(),
        mobile: yup.string().required(),
        qualifications: yup.string().required(),
        // serviceKind: yup.string().required(),
        serviceProvided: yup.array().min(1).required(),
        serviceType: yup.array().min(1).required(),
        specialty: yup.array().min(1).required(),
        // subscription: yup.mixed(),
      });

      return schema;
    },
    hasPackage() {
      return (
        this.loggedInUser.is_service &&
        this.hasService &&
        this.service.subscription !== null
      );
    },
    subscribedAdsAndparts() {
      if (this.loggedInUser.is_service && this.hasService) {
        if (
          this.service.serviceAdAllow === "parts_and_ads" ||
          this.service.serviceAdAllow === "ads_only" ||
          this.service.serviceAdAllow === "parts_only"
        ) {
          if (
            this.service.subscription &&
            this.service.subscription.subscriptionType === "Premium Package"
          ) {
            return true;
          }
        }
        return false;
      }

      return false;
    },
  },
  methods: {
    onSubmit() {},
    async loadService() {
      this.isLoading = true;
      try {
        await this.$store.dispatch("services/getService", {
          serviceId: this.loggedInUser.id,
        });
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;
    },
    async loadPackages() {
      this.isLoading = true;
      try {
        await this.$store.dispatch("subscription_types/loadSubscriptionTypes");
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;
    },
    async loadServiceAddresses() {
      this.isLoading = true;
      try {
        await this.$store.dispatch("services/getServiceAddressByFilter", {
          params: {
            serviceId: this.loggedInUser.id,
          },
        });
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;
    },
    test() {
      this.profileSelectPackage = true;
    },
    checkSub(packageName) {
      return (
        this.hasService &&
        this.service.subscription &&
        this.service.subscription.subscriptionType === packageName
      );
    },
    selectPackage(packageName) {
      this.profileSelectPackage = false;
      console.log(packageName.id);
      this.$router.push("/admin-subscribe-" + packageName.id);
    },
    subscribed(type) {
      if (this.loggedInUser.is_service && this.hasService) {
        if (
          this.service.subscription &&
          this.service.subscription.subscriptionType === "Standard Package"
        ) {
          if (this.service.serviceAdAllow === type) {
            return true;
          }
          return false;
        }

        return false;
      }

      return false;
    },
    updateState(prop, value) {
      value = value.target.value;
      this.$store.dispatch("updateUser", { prop, value }).catch(() => {
        console.log("Could not update user data");
      });
    },
    updateServiceMState(prop, value) {
      value = { target: { value: value } };
      this.updateServiceState(prop, value);
    },
    updateServiceState(prop, value) {
      value = value.target.value;
      this.$store
        .dispatch("services/updateService", { prop, value })
        .catch(() => {
          console.log("Could not update service data");
        });
    },
    async onSubmitUpdateUser() {
      this.isLoading = true;
      try {
        await this.$store.dispatch("updateUserInfo", {
          user: this.loggedInUser,
        });
        this.successMsg = "User details have been successfully updated.";
        // if (success) {
        //   this.resetForm();
        // }
      } catch (error) {
        console.log(error);
        console.log(error.response.data);
        if (error.response.data.non_field_errors) {
          this.pageErrors = error.response.data.non_field_errors;
        } else {
          for (const key in error.response.data) {
            this.pageErrors.push(
              error.response.data[key][0] + " (" + key.toUpperCase() + ")"
            );
          }
        }
      }
      console.log(this.pageErrors);
      this.isLoading = false;
    },
    async onSubmitUpdateService() {
      this.isLoading = true;
      try {
        let data = { ...this.service };
        delete data.user;
        delete data.addresses;
        delete data.subscription;
        await this.$store.dispatch("services/updateServiceInfo", {
          slug: this.loggedInUser.id,
          service: data,
        });
        this.successMsg = "Service details have been successfully updated.";
      } catch (error) {
        console.log(error);
        console.log(error.response.data);
        if (error.response.data.non_field_errors) {
          this.pageErrors = error.response.data.non_field_errors;
        } else {
          for (const key in error.response.data) {
            this.pageErrors.push(
              error.response.data[key][0] + " (" + key.toUpperCase() + ")"
            );
          }
        }
      }
      console.log(this.pageErrors);
      this.isLoading = false;
    },
    handleError() {
      this.pageErrors = [];
      this.successMsg = "";
    },
    showAddressInput() {
      // console.log(this.addAddress);
      this.addAddress = !this.addAddress;
    },
    /**
     * When the location found
     * @param {Object} addressData Data of the found location
     * @param {Object} placeResultData PlaceResult object
     * @param {String} id Input container ID
     */
    async getAddressData(addressData, placeResultData, id) {
      // this.address = addressData;
      // console.log(addressData);
      // console.log(placeResultData);
      console.log(id);
      const address = placeResultData.formatted_address;
      const lat = addressData.latitude;
      const long = addressData.longitude;
      const region = addressData.locality;

      this.isLoading = true;
      try {
        await this.$store.dispatch("services/addServiceAddress", {
          service: this.loggedInUser.id,
          address: address,
          googleLat: lat,
          googleLong: long,
          region: region,
        });
        this.successMsg = "Address has been created successfully";
      } catch (error) {
        console.log(error);
        console.log(error.response.data);
        if (error.response.data.non_field_errors) {
          this.pageErrors = error.response.data.non_field_errors;
        } else {
          for (const key in error.response.data) {
            this.pageErrors.push(
              error.response.data[key][0] + " (" + key.toUpperCase() + ")"
            );
          }
        }
      }
      console.log(this.pageErrors);
      this.isLoading = false;
    },
    deleteServiceAddressPerm(addressId) {
      this.deletePartDialog = false;
      try {
        this.$store
          .dispatch("services/deleteServAddressPermanently", {
            id: addressId,
          })
          .then(() => {
            this.deletePartSuccessDialog = true;
          });
      } catch (error) {
        console.log(error);
      }
    },
  },
  //AIzaSyD0pikDwYEeAabG2SkhIPSAgINg-h01Q4c google api key
  async created() {
    document.title = "PSI Link | Admin Profile";
    if (this.loggedInUser.is_service) {
      await this.loadService();
      await this.loadPackages();
      await this.loadServiceAddresses();
    }
  },
};
</script>
<style scoped>
.error-show {
  color: #fb6340;
}
</style>
