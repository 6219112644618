import categoryService from "../../../services/categories.service";

export default {
  async addCategory(context, data) {
    const token = context.rootGetters.token;
    const response = await categoryService.createCategory(token, data.form);
    console.log(response);

    // context.commit("addPart", {
    //   ...coachData,
    //   id: userId,
    // });
  },

  async addSubCategory(context, data) {
    const token = context.rootGetters.token;
    const response = await categoryService.createSubCategory(token, data.form);
    console.log(response);

    // context.commit("addPart", {
    //   ...coachData,
    //   id: userId,
    // });
  },

  async addPartType(context, data) {
    const token = context.rootGetters.token;
    const response = await categoryService.createPartType(token, data.form);
    console.log(response);

    // context.commit("addPart", {
    //   ...coachData,
    //   id: userId,
    // });
  },

  async loadCategories(context) {
    const token = context.rootGetters.token;
    const response = await categoryService.getCategories(token);

    context.commit("setCategories", response.data);
  },

  async loadSubCategories(context, payload = null) {
    // console.log(payload);
    let params = "?";
    if (payload !== null) {
      for (const key in payload.params) {
        console.log(key);
        params = params + `${key}=${payload.params[key]}&`;
      }
      params.slice(0, -1);
    }
    const loadpay = payload !== null ? params : "";
    const token = context.rootGetters.token;
    const response = await categoryService.getSubCategories(token, loadpay);

    context.commit("setSubCategories", response.data);
  },

  async loadVehicleMakes(context) {
    const token = context.rootGetters.token;
    const response = await categoryService.getVehicleMakes(token);

    context.commit("setVehicleMakes", response.data);
  },

  async loadVehicleModels(context, payload = null) {
    console.log(payload);
    let params = "?";
    if (payload !== null) {
      for (const key in payload.params) {
        console.log(key);
        params = params + `${key}=${payload.params[key]}&`;
      }
      params.slice(0, -1);
    }
    const loadpay = payload !== null ? params : "";
    const token = context.rootGetters.token;
    const response = await categoryService.getVehicleModels(token, loadpay);

    context.commit("setVehicleModels", response.data);
  },

  async loadPartTypes(context) {
    const token = context.rootGetters.token;
    const response = await categoryService.getPartTypes(token);

    context.commit("setPartTypes", response.data);
  },
};
