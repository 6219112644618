<template>
  <!-- Begin Uren's Modal Area -->
  <div class="modal fade modal-wrapper" :id="`customModalCenter-${part.id}`">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-body">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <div class="modal-inner-area sp-area row">
            <div class="col-lg-5">
              <div class="sp-img_area">
                <div
                  class="sp-img_slider slick-img-slider uren-slick-slider"
                  data-slick-options='{
                                    "slidesToShow": 1,
                                    "arrows": false,
                                    "fade": true,
                                    "draggable": false,
                                    "swipe": false,
                                    "asNavFor": ".sp-img_slider-nav"
                                    }'
                >
                  <div
                    class="single-slide"
                    :class="getClass(index)"
                    v-for="(img, index) in part.images"
                    :key="img.id"
                  >
                    <img :src="img.get_image" :alt="part.name" />
                  </div>
                </div>
                <div
                  class="sp-img_slider-nav slick-slider-nav uren-slick-slider slider-navigation_style-3"
                  data-slick-options='{
                                   "slidesToShow": 4,
                                    "asNavFor": ".sp-img_slider",
                                   "focusOnSelect": true,
                                   "arrows" : true,
                                   "spaceBetween": 30
                                  }'
                  data-slick-responsive='[
                                    {"breakpoint":1501, "settings": {"slidesToShow": 3}},
                                    {"breakpoint":992, "settings": {"slidesToShow": 4}},
                                    {"breakpoint":768, "settings": {"slidesToShow": 3}},
                                    {"breakpoint":575, "settings": {"slidesToShow": 2}}
                                ]'
                >
                  <div
                    class="single-slide"
                    :class="getClass(index)"
                    v-for="(img, index) in part.images"
                    :key="img.id"
                  >
                    <img :src="img.get_thumpnail" :alt="part.name" />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-7 col-lg-6">
              <div class="sp-content">
                <div class="sp-heading">
                  <h5>
                    <a href="#">{{ part.name }}</a>
                  </h5>
                </div>
                <!-- <div class="rating-box">
                  <ul>
                    <li><i class="ion-android-star"></i></li>
                    <li><i class="ion-android-star"></i></li>
                    <li><i class="ion-android-star"></i></li>
                    <li class="silver-color">
                      <i class="ion-android-star"></i>
                    </li>
                    <li class="silver-color">
                      <i class="ion-android-star"></i>
                    </li>
                  </ul>
                </div> -->
                <div class="price-box">
                  <span class="new-price new-price-2">{{
                    part.price == -1 || part.price == -1.0
                      ? "Contact for price"
                      : `R${part.price}`
                  }}</span>
                  <!-- <span v-if="part.discount !== '0'" class="old-price"
                    >R{{ part.oldprice }}</span
                  > -->
                </div>
                <div class="sp-e ssential_stuff">
                  <ul>
                    <li>
                      Part Type:
                      <a href="javascript:void(0)"
                        ><strong>{{ part.type.toUpperCase() }}</strong></a
                      >
                    </li>
                    <li>
                      Part Category:
                      <a href="javascript:void(0)">
                        <span style="margin-right: 5px"
                          ><strong>{{ part.category.name }}</strong></span
                        >
                      </a>
                    </li>
                    <li>
                      Availability:
                      <a href="javascript:void(0)"
                        ><strong>{{
                          part.quantity > 0 ? "In Stock" : "Not Available"
                        }}</strong></a
                      >
                    </li>
                    <li v-if="hasVehicleMakes">
                      Vehicle Make:
                      <a href="javascript:void(0)"
                        ><strong>{{
                          findVMakebyId(part.vehicleMake).name
                        }}</strong></a
                      >
                    </li>
                    <li v-if="hasVehicleMakes && part.vehicleModel">
                      Vehicle Model:
                      <a href="javascript:void(0)"
                        ><strong>{{
                          findVModelFromMakebyId(
                            part.vehicleMake,
                            part.vehicleModel
                          ).name
                        }}</strong></a
                      >
                    </li>
                    <li>
                      Vehicle Year(s):
                      <a href="javascript:void(0)"
                        ><strong>{{ part.vehicleYear }}</strong>
                      </a>
                    </li>
                    <!-- <li v-if="isLoggedIn"> -->
                    <li>
                      Description:
                      <a href="javascript:void(0)"
                        ><strong>{{ part.description }}</strong></a
                      >
                    </li>
                    <li>
                      Email Address:
                      <a href="javascript:void(0)"
                        ><strong>{{
                          adjustService(part.service).businessEmail
                        }}</strong></a
                      >
                    </li>
                    <li>
                      Phone Number(s):
                      <a href="javascript:void(0)"
                        ><strong
                          >{{ adjustService(part.service).mobile }}/{{
                            adjustService(part.service).landline
                          }}</strong
                        ></a
                      >
                    </li>
                  </ul>
                </div>
                <div
                  class="color-list_area"
                  v-if="isLoggedIn && loggedInUser.is_customer"
                >
                  <div class="color-list_heading">
                    <h4>Service Details</h4>
                  </div>
                  <!-- <span class="sub-title">{{ part.description }}</span> -->
                  <table class="table table-bordered specification-inner_stuff">
                    <tbody>
                      <tr
                        v-for="(value, name) in adjustService(part.service)"
                        :key="value"
                      >
                        <td>{{ name.replace(/_/g, " ").toUpperCase() }}</td>
                        <td>{{ value }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="uren-group_btn">
                  <ul>
                    <li>
                      <a
                        :href="!isLoggedIn ? '/login' : '#'"
                        @click="addToWishlist()"
                        class="add-to_cart"
                        >Add to Wishlist</a
                      >
                    </li>
                  </ul>
                </div>
                <div class="uren-tag-line" v-if="part.tags.length > 0">
                  <h6>Tags:</h6>
                  <a
                    href="javascript:void(0)"
                    v-for="tag in part.tags"
                    :key="tag"
                    >{{ tag }},</a
                  >
                </div>
                <div class="uren-social_link">
                  <ul>
                    <li class="facebook">
                      <a
                        href="https://www.facebook.com/"
                        data-toggle="tooltip"
                        target="_blank"
                        title="Facebook"
                      >
                        <i class="fab fa-facebook"></i>
                      </a>
                    </li>
                    <li class="twitter">
                      <a
                        href="https://twitter.com/"
                        data-toggle="tooltip"
                        target="_blank"
                        title="Twitter"
                      >
                        <i class="fab fa-twitter-square"></i>
                      </a>
                    </li>
                    <li class="youtube">
                      <a
                        href="https://www.youtube.com/"
                        data-toggle="tooltip"
                        target="_blank"
                        title="Youtube"
                      >
                        <i class="fab fa-youtube"></i>
                      </a>
                    </li>
                    <li class="google-plus">
                      <a
                        href="https://www.plus.google.com/discover"
                        data-toggle="tooltip"
                        target="_blank"
                        title="Google Plus"
                      >
                        <i class="fab fa-google-plus"></i>
                      </a>
                    </li>
                    <li class="instagram">
                      <a
                        href="https://rss.com/"
                        data-toggle="tooltip"
                        target="_blank"
                        title="Instagram"
                      >
                        <i class="fab fa-instagram"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Uren's Modal Area End Here -->
</template>

<script>
export default {
  props: {
    part: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters.isAuthenticated;
    },
    loggedInUser() {
      return this.$store.getters.user;
    },
    hasParts() {
      return this.$store.getters["parts/hasParts"];
    },
    hasVehicleMakes() {
      return this.$store.getters["categories/hasVehicleMakes"];
    },
    vehicleMakes() {
      return this.$store.getters["categories/vehicleMakes"];
    },
  },
  methods: {
    getClass(index) {
      let p = "";

      switch (index) {
        case 0:
          p = "red";
          break;
        case 1:
          p = "orange";
          break;
        case 2:
          p = "brown";
          break;
        case 3:
          p = "umber";
          break;
        case 4:
          p = "black";
          break;

        default:
          p = "golden";
          break;
      }
      return p;
    },
    adjustService(service) {
      console.log(service);
      let newService = {};
      newService.company_ame = service.companyName;
      newService.hourly_rate =
        service.hourlyRate === null ? "None" : `R${service.hourlyRate}`;
      newService.availability = service.availability;
      newService.landline = service.landline;
      newService.businessEmail = service.businessEmail;
      newService.mobile = service.mobile;
      // newService.specialty = service.specialty;
      newService.website = service.website;
      let sev_prov = "";
      for (let index = 0; index < service.serviceProvided.length; index++) {
        sev_prov +=
          service.serviceProvided[index].replace(/_/g, " ").toUpperCase() + " ";
      }
      newService.service_provided = sev_prov;
      let sev_type = "";
      for (let index = 0; index < service.serviceType.length; index++) {
        sev_type +=
          service.serviceType[index].replace(/_/g, " ").toUpperCase() + " ";
      }
      newService.service_type = sev_type;
      newService.delivery_type = service.delivery_type;
      return newService;
    },
    addToWishlist() {
      console.log("logged in");
    },
    findVMakebyId(id) {
      const vmake = this.vehicleMakes.find((x) => x.id === id);
      return vmake;
    },
    findVModelFromMakebyId(vmakeId, vmodelId) {
      const vmake = this.vehicleMakes.find((x) => x.id === vmakeId);
      const vmodel = vmake.vehicleModels.find((y) => y.id === vmodelId);
      return vmodel;
    },
  },
};
</script>
