import axios from "axios";

const API_SERV_URL = process.env.VUE_APP_API_URL + "services/";
const API_SERV_ADD_URL = process.env.VUE_APP_API_URL + "service-address/";
const API_CUST_URL = process.env.VUE_APP_API_URL + "customers/";

class ServicesService {
  getConfig(token) {
    return {
      headers: { Authorization: `Token ${token}` },
    };
  }

  async createService(token, data) {
    return await axios.post(API_SERV_URL, data, this.getConfig(token));
  }

  async createServiceAddress(token, data) {
    return await axios.post(API_SERV_ADD_URL, data, this.getConfig(token));
  }

  async createCustomer(token, data) {
    return await axios.post(API_CUST_URL, data, this.getConfig(token));
  }

  async getServices(token) {
    return await axios.get(API_SERV_URL, this.getConfig(token));
  }

  async getServiceAddresses(token) {
    return await axios.get(API_SERV_ADD_URL, this.getConfig(token));
  }

  async getServiceAddressesByFilter(payload, token) {
    return await axios.get(
      `${API_SERV_ADD_URL}${payload}`,
      this.getConfig(token)
    );
  }

  async getCustomers(token) {
    return await axios.get(API_CUST_URL, this.getConfig(token));
  }

  async getService(token, slug) {
    return await axios.get(API_SERV_URL + slug, this.getConfig(token));
  }

  async getServiceAddress(token, slug) {
    return await axios.get(API_SERV_ADD_URL + slug, this.getConfig(token));
  }

  async getCustomer(token, slug) {
    return await axios.get(API_CUST_URL + slug, this.getConfig(token));
  }

  async updateService(token, data) {
    return await axios.patch(
      API_SERV_URL + data.slug,
      data.service,
      this.getConfig(token)
    );
  }

  async updateCustomer(token, data) {
    return await axios.patch(
      API_CUST_URL + data.slug,
      data.customer,
      this.getConfig(token)
    );
  }

  async deleteServiceAddress(token, payload) {
    return await axios.delete(
      `${API_SERV_ADD_URL}${payload.id}`,
      this.getConfig(token)
    );
  }
}

export default new ServicesService();
