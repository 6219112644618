export default {
  addSubscriptionTypes(state, payload) {
    state.subscription_types.push(payload);
  },
  setSubscriptionTypes(state, payload) {
    state.subscription_types = payload;
  },
  setSubscriptionType(state, payload) {
    state.subscription_type = payload;
  },
};
