<template>
  <div>
    <base-dialog :show="isLoading" title="Loading..." fixed>
      <base-spinner></base-spinner>
    </base-dialog>
    <admin-header></admin-header>
    <modal
      v-model:show="userSub"
      gradient="danger"
      modal-classes="modal-danger modal-dialog-centered"
    >
      <template v-slot:header>
        <h6 class="modal-title" id="modal-title-notification">
          Your attention is required
        </h6>
      </template>
      <div class="py-3 text-center">
        <i class="ni ni-bell-55 ni-3x"></i>
        <h4 class="heading mt-4">You have successfully registered.</h4>
        <p style="color: white">
          To proceed to adding your parts you need to be subscribed. <br />
          Hence you need to navigate to your profile page, once there please
          scroll to the bottom of the page to start your subscription process.
        </p>
      </div>

      <template v-slot:footer>
        <a class="btn btn-white" href="/admin-profile">Got to profile page</a>
        <base-button
          type="link"
          text-color="white"
          class="ml-auto"
          @click="goto()"
        >
          Close
        </base-button>
      </template>
    </modal>
  </div>
</template>
<script>
// Charts

export default {
  components: {},
  data() {
    return {
      isLoading: false,
      userSub: false,
    };
  },
  computed: {
    loggedInUser() {
      return this.$store.getters.user;
    },
    isLoggedIn() {
      return this.$store.getters.isAuthenticated;
    },
    service() {
      return this.$store.getters["services/service"];
    },
    hasService() {
      return this.$store.getters["services/hasService"];
    },
  },
  methods: {
    async loadService() {
      this.isLoading = true;
      try {
        await this.$store.dispatch("services/getService", {
          serviceId: this.loggedInUser.id,
        });
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;
    },
    closeUserNotSubscribed() {
      this.userSub = false;
      console.log(this.userSub);
    },
    userSubscribed() {
      if (
        this.loggedInUser.is_service &&
        !this.loggedInUser.subscribed_service
      ) {
        this.userSub = true;
      }
    },
  },
  async created() {
    document.title = "PSI Link | Admin Dashboard";
    this.userSubscribed();
    if (this.loggedInUser.is_service) {
      await this.loadService();
    }
  },
};
</script>
<style></style>
