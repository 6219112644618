<template>
  <div class="uren-slider_area">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-12">
          <div class="main-slider slider-navigation_style-2">
            <!-- Begin Single Slide Area -->
            <!-- override bg image using style="background-image: url(../images/slider/2.jpg);" -->
            <!-- loop over this div for multiple -->
            <div
              v-for="slide in slides"
              :key="slide"
              class="single-slide animation-style-01 bg-1"
              :style="`background-image: url(${slide.image})`"
            >
              <div class="slider-content">
                <!-- or like this class="slider-content slider-content-2"  -->
                <span>{{ slide.title }}</span>
                <template v-html="slide.content"></template>
                <!-- <h3>Car interior</h3> sample content
                <h4>Starting at <span>$99.00</span></h4> -->
                <div class="uren-btn-ps_left slide-btn">
                  <a class="uren-btn" :href="slide.url">Read More</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    slides: {
      type: Array,
      required: true,
    },
  },
};
</script>
<style scoped>
.uren-slider_area .main-slider .slider-content > span {
  font-weight: 700;
  text-transform: none;
  color: #ffffff;
  padding-bottom: 25px;
  font-size: 24px;
  display: block;
  background: #000;
  padding: 10px;
  margin-bottom: 15px;
  max-width: 800px;
  border-radius: 10px;
}
</style>
