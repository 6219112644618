<template>
  <base-wrapper>
    <template v-slot:content>
      <!-- Begin Uren's Breadcrumb Area -->
      <div class="breadcrumb-area">
        <div class="container">
          <div class="breadcrumb-content">
            <ul>
              <li><a href="/">Home</a></li>
              <li class="active">Terms & Conditions</li>
            </ul>
          </div>
        </div>
      </div>
      <!-- Uren's Breadcrumb Area End Here -->
      <!-- Begin Uren's About Us Area -->
      <div class="about-us-area">
        <div class="container-fluid">
          <div class="row">
            <!-- <div class="col-lg-6 col-md-5">
              <div class="overview-img text-center img-hover_effect">
                <a href="#">
                  <img
                    class="img-full"
                    src="@/assets/images/about-us/1.jpg"
                    alt="Uren's About Us Image"
                  />
                </a>
              </div>
            </div> -->
            <div class="col-lg-12 col-md-12 d-flex align-items-center">
              <div class="overview-content">
                <h2><span>PS</span> Link Terms & Conditions</h2>
                <p class="short_desc">
                  We know that terms and conditions is a boring part of a website, but it is crucial in protecting the users as well as the web site.

                <br>Please take the time to read and understand the terms and conditions before proceeding with the website.<br><br>

                Definitions in this terms and conditions:<br>
                <strong>"Website/Platform"</strong> means this website including all aspects, facilities and services of any nature whatsoever related thereto; <br>
                <strong>"We/Us/Our"</strong> means PS Link (Pty) Ltd, a private company incorporated in South Africa with limited liability, and where applicable our associated companies;<br>
                <strong>"You"/"Your"</strong> means the user of the Website. This includes registered buyers, sellers and advertisers using this website.<br>
                <strong>"Seller"</strong> means the users selling services or parts on our website<br>
                <strong>"Buyers"</strong> means the user selling services or parts on our website<br>
                <strong>"Advertisers"</strong> means the user advertising services or parts on our website<br>
                <strong>"Service Provider"</strong> means the user promoting his/her services on our website
                </p>

                <h3> 1. Access to the Website</h3>
                <p class="short_desc">
                    We reserve the right of access to the website. From time to time parts of the website would be restricted due to maintenance, upgrades etc.
                </p>

                <h3>2. Content</h3>
                <p class="short_desc">
                    You agree that the content of this website may not be copied and/or used in any other way other than for the PS Link website. <br><br>

                    The information appearing on the Website is for information purposes only and does not constitute advice. You should not rely on any such information or content to make (or refrain from making) any decision or take (or refrain from taking) any action.<br>
                    We give no warranties, conditions, guarantees or representations, expressed or implied, as to:<br>

                    <ul style="list-style-type: square; margin-left: 40px;">
                        <li>
                            The completeness or accuracy of information or any advice that may contained on the Website or any website to which it is linked;
                        </li>
                        <li>
                            The content of the advertisements for parts, services, literature and service histories appearing on the Website, including but not limited to, the ownership, quality, authenticity of any photographs, compliance with description or fitness for purpose of any such services;
                        </li>
                        <li>
                            The completeness of the results of any search conducted on the Website or that the services revealed by such search might be the only information in our database which might meet the requirements of your search.
                        </li>
                    </ul>
                    It is the sellers’ responsibility to keep parts stock information relevant on our website
                </p>

                <h3>3. Contractual Issues</h3>
                <p class="short_desc">
                    We will not be bound to any allegations from you that a contract or legally binding arrangement has been entered into between you and us arising from communications addressed to us on the Website.
                    We reserve the right to insist upon written contracts as and when we deem appropriate within our discretion. As such, any information contained on the website cannot be regarded by you as an offer capable of acceptance resulting in a legally binding contract.
                </p>

                <h3>4. Liability of the Website</h3>
                <p class="short_desc">
                    We do not take any responsibility of whatsoever nature as a result of users making use of the services on this platform. <br><br>

                    Each user does so entirely at their own risk, and hereby indemnifies the owner of this website of direct and/or indirect damages.<br><br>

                    Please report scammers to <a style="text-decoration: underline;" href="mailto:info@pslink.co.za">info@pslink.co.za</a>
                </p>

                <h3>5. IP - Intellectual Property</h3>
                <p class="short_desc">
                    All intellectual property not limited to this website (website design, name, logos, pictures, videos, content information etc.) will remain the property of PS Link and its owners/shareholders. 
                </p>

                <h3>6. Registration details</h3>
                <p class="short_desc">
                    It is required to register before making use up this website. Buyers would sign up for free, but Sellers or Advertisers will have to sign up to a monthly subscriptions as set out in the website. <br><br>

                    As part of the registration process, you will be given a username and password. You agree that the information supplied with your registration will be truthful, accurate and complete.
                    You furthermore agree that you will not attempt to register in the name of any other individual, company or organisation nor will you adopt any username which we deem to be offensive. All information supplied by you as part of the registration process will be protected and used in accordance with the terms of our Privacy Policy.
                </p>

                <h3>7. Links to the Website</h3>
                <p class="short_desc">
                    Links to this website may be created without prior consent from us, as long as it is not maleficent towards the website.  
                </p>

                <h3>8. Links to other websites</h3>
                <p class="short_desc">
                    As other websites and/or advertisers are not necessarily part of this website, their terms and conditions needs to be read.
                </p>

                <h3>9. Security</h3>
                <p class="short_desc">
                    The website was designed with full intend of making is secure for users as well as for PS Link. <br>
                    Criminal action will be taken against hackers or anyone that is trying to be malicious against PS Link in entirety. 
                    Please be careful of scammers posing as buyers or sellers on this website. Please report any criminal activity at info@pslink.co.za
                </p>

                <h3>10. Payments</h3>
                <p class="short_desc">
                   A secure 3rd party supplier would be utilised to protect credit card and payment information as best possible
                </p>

                <h3>11. General</h3>
                <p class="short_desc">
                   We reserve the right to change any of the terms and conditions without prior notice. The changes will be amended in this Terms and Conditions Page. It is the user’s responsibility to make sure that the terms and conditions is followed at all times. <br><br>
                    You cannot assign or dispose responsibilities or obligations as set out in this terms and conditions.<br><br>
                    Furthermore, we can assign our obligations to a third party if and when needed.<br><br> 
                    The laws of the Republic of South Africa will apply in respect of any legal issue or litigation arising directly or indirectly from the terms and conditions, the use or inability to use the website and/or information contained on the Website.
                </p>

                <h3>12. Communication / Data</h3>
                <p class="short_desc">
                   Users accessing our website directly or indirectly agree to the terms and conditions and we can therefore utilise their date in the following way:<br>
                   <ul style="list-style-type: square; margin-left: 40px;">
                        <li>
                            Statistical purpose,
                        </li>
                        <li>
                            Communicating further with you in terms of advertisements, sourcing of parts or services as per your need or request,
                        </li>
                        <li>
                            Storing this information for future use.
                        </li>
                    </ul>

                    3rd party information provided in this website shall be done in the interest and in the spirit as indicated in the competition commission act.<br>
                    <small style="color:#e52e06;">
                        We make use of the Google Invisible reCAPTCHA service. Use of the Google Invisible reCAPTCHA service is subject to the Google Privacy Policy and Terms of Use.
                    </small>
                </p>

                <h3>13. Legislation</h3>
                <p class="short_desc">
                   This website complies with the following legislated acts:
                   <ul style="list-style-type: decimal; margin-left: 40px;">
                        <li>
                            Automotive competition commission act,
                        </li>
                        <li>
                            Protection of personal information act, 2013
                        </li>
                        <li>
                            Electronic Communications and Transactions Act, 2002 No. 25 of 2002
                        </li>
                    </ul><br>
                    Website information available as required by the Communications and Transactions Act 25 of 2002, Chapter 7:<br>
                    <ul style="list-style-type: square; margin-left: 40px;">
                        <li>
                            Our full name and legal status: PS Link (Pty) Ltd.
                        </li>
                        <li>
                            Main Business: Advertising / Marketing of automotive parts, services and advertising
                        </li>
                        <li>
                            Pretoria Physical address for receipt of legal service: 13 Tern Place, Montana Park, Pretoria
                        </li>
                        <li>
                            Telephone number: <strong>+27 78 893 4978</strong>
                        </li>
                        <li>
                            Website address: <a style="text-decoration: underline;" href="https://www.pslink.co.za">https://www.pslink.co.za</a>
                        </li>
                        <li>
                            Official email address: <a style="text-decoration: underline;" href="mailto:info@pslink.co.za">info@pslink.co.za</a>
                        </li>
                    </ul>
                </p>

                <!-- <h3>10. Payments</h3> -->
                <p class="short_desc">
                   <ul style="list-style-type: lower-alpha; margin-left: 40px;">
                        <li>
                            any code of conduct to which that supplier subscribes and how that code of conduct may be accessed electronically by the consumer;
                        </li>
                        <li>
                            in the case of a legal person, its registration number, the names of its office bearers and its place of registration;
                        </li>
                        <li>
                            the physical address where that supplier will receive legal service of documents;
                        </li>
                        <li>
                            a sufficient description of the main characteristics of the goods or services offered by that supplier to enable a consumer to make an informed decision on the proposed electronic transaction;
                        </li>
                        <li>
                            the full price of the goods or services, including transport costs, taxes and any other fees or costs;
                        </li>
                        <li>
                            the manner of payment;
                        </li>
                        <li>
                            any terms of agreement, including any guarantees, that will apply to the transaction and how those terms may be accessed, stored and reproduced electronically by consumers;
                        </li>
                        <li>
                            the time within which the goods will be dispatched or delivered or within which the services will be rendered;
                        </li>
                        <li>
                            the manner and period within which consumers can access and maintain a full record of the transaction;
                        </li>
                        <li>
                            the return, exchange and refund policy of that supplier;
                        </li>
                        <li>
                            any alternative dispute resolution code to which that supplier subscribes and how the wording of that code may be accessed electronically by the consumer;
                        </li>
                        <li>
                            the security procedures and privacy policy of that supplier in respect of payment, payment information and personal information;
                        </li>
                        <li>
                            where appropriate, the minimum duration of the agreement in the case of agreements for the supply of products or services to be performed on an ongoing basis or recurrently; and
                        </li>
                        <li>
                            the rights of consumers in terms of section 44, where applicable.
                        </li>
                    </ul>

                    (2) The supplier must provide a consumer with an opportunity—­<br>
                    <ul style="list-style-type: lower-alpha; margin-left: 40px;">
                        <li>
                            to review the entire electronic transaction;
                        </li>
                        <li>
                            to correct any mistakes; and
                        </li>
                        <li>
                            to withdraw from the transaction, before finally placing any order.
                        </li>
                    </ul>

                    (3) If a supplier fails to comply with the provisions of subsection (1) or (2), the consumer may cancel the transaction within 14 days of receiving the goods or services under the transaction.<br>
                    (4) If a transaction is cancelled in terms of subsection (3)—­<br>
                    <ul style="list-style-type: lower-alpha; margin-left: 40px;">
                        <li>
                            the consumer must return the performance of the supplier or, where applicable, cease using the services performed; and
                        </li>
                        <li>
                            the supplier must refund all payments made by the consumer minus the direct cost of returning the goods.
                        </li>
                    </ul>

                    (5) The supplier must utilise a payment system that is sufficiently secure with reference to accepted technological standards at the time of the transaction and the type of transaction concerned.<br>
                    (6) The supplier is liable for any damage suffered by a consumer due to a failure by the supplier to comply with subsection (5).
                </p>

                <h2>Advertising Terms and Conditions</h2>
                <p class="short_desc">
                    Advertisements placed on the Website will also appear on the Auto Trader mobile Internet service.<br>
                    By placing an advertisement with us, you are deemed to have read and accepted these Conditions. These Conditions and the contract to which they form part shall be governed by South African law.
                </p>

                <h3>1. Non Transferable</h3>
                <p class="short_desc">
                    You may not assign or transfer any or all of your rights and/or obligations arising from these Conditions without our prior written consent.
                </p>

                <h3>2. Multiple Advertisements</h3>
                <p class="short_desc">
                    <ul style="list-style-type: square; margin-left: 40px;">
                        <li>
                            We are only able to display one advertisement per vehicle at any one time on the Website.
                        </li>
                        <li>
                            If you order a subsequent placement of the advertisement which is already advertised on the Website, then the subsequent advertisement will replace the previous advertisement for that vehicle.
                        </li>
                        <li>
                            The time period for the first advertisement will automatically expire when it is replaced by the subsequent advertisement.
                        </li>
                        <li>
                            We will not be liable to reimburse you, should the first advertisement not be displayed for duration of the period for which payment was made.
                        </li>
                    </ul>
                </p>

                <h3>4. Processing Your Advertisement</h3>
                <p class="short_desc">
                    <ul style="list-style-type: square; margin-left: 40px;">
                        <li>
                            Only one vehicle may be featured in an advertisement.
                        </li>
                        <li>
                            We aim to process advertisements supplied online or to our sales team within 24 hours of receipt and placements of these advertisements on the Website the following working day.
                        </li>
                        <li>
                            As soon as an advertisement has been approved, a confirmation email will be sent to you confirming that the advertisement has gone live onto the Website. In the event that an advertisement has been rejected, we will attempt to send an email to you providing details of who you need to contact in order to find out why your advertisement has been rejected.
                        </li>
                    </ul>
                </p>

                <h3>5. Warranties</h3>
                <p class="short_desc">
                    You warrant that;<br>
                    <ul style="list-style-type: square; margin-left: 40px;">
                        <li>
                            all information and materials provided to us is complete, accurate, legal, non-defamatory, decent, honest and truthful and complies with the South African Code of Advertising Practice as well as all current relevant legislation;
                        </li>
                        <li>
                            the publication of the advertisement by us will not breach any contract, infringe any third party intellectual property rights or any other rights; nor render The Car Trader (Pty) Ltd to any proceedings, and/or be detrimental to the reputation of the The Car Trader (Pty) Ltd;
                        </li>
                        <li>
                            in respect of any advertisement which contains a copy or photographs or video by which any living person is or can be identified, you have obtained the necessary authority of such living person and complied with the Data Protection Act 1998 (as amended from time to time).
                        </li>
                    </ul>
                    We may suspend, or terminate with immediate effect, our agreement with you in circumstances in which:<br>
                    <ul style="list-style-type: square; margin-left: 40px;">
                        <li>
                            You fail to comply or we suspect that you are not complying with the provisions of clause 5.a; and/or
                        </li>
                        <li>
                            any competent law enforcement or compliance authority instructs, advises or makes a recommendation or orders to us to take down any of your advertisements and/or suspend the delivery of services to you.
                        </li>
                    </ul>
                </p>

                <h3>6. Intellectual Property Rights</h3>
                <p class="short_desc">
                    <ul style="list-style-type: square; margin-left: 40px;">
                        <li>
                            All intellectual property rights (including, without limitation, copyright, trade marks, database rights and design rights [whether registered or not]) ("IPRs") in any publication, copy, text, artwork, photographs or other materials which we have created and/or in any way altered for you shall belong to us absolutely. To the extent that any such IPRs do not automatically vest in us, you hereby assign to us all such IPRs.
                        </li>
                        <li>
                            Where necessary you shall, at our request, sign and execute all such documents and do all such acts as we may reasonably require to vest the IPRs in us and to enable us protect and enforce them. In respect of any materials other than that referred to in above, which you supply to us ("Materials").
                        </li>
                        <li>
                            You grant us a non-exclusive, irrevocable, perpetual and royalty free licence to use such Materials for any purpose. You undertake that neither you nor any other person will assert any moral rights in or relating to the IPRs referred to above against us or any third party.
                        </li>
                        <li>
                            You shall at all times indemnify us fully against all actions, suits, claims, demands, costs, charges, damages, losses and expenses (including legal expenses on an indemnity basis) suffered or incurred by us due to, or arising out of, the publication by us of any advertisement for you in accordance with your instructions and/or specifications including (without limitation) any claim for defamation or the infringement of any third party's IPRs.
                        </li>
                    </ul>
                </p>

                <h3>7. Our Rights and Obligations</h3>
                <p class="short_desc">
                    <ul style="list-style-type: square; margin-left: 40px;">
                        <li>
                            We shall provide the service with reasonable care and skill in a professional and timely manner and the advertisement shall be designed in accordance with the agreed specification.
                        </li>
                        <li>
                            Where your advertisement is to appear on the Website, you acknowledge and accept that it is technically impossible to guarantee that the Website will be continuously available online or the corruption free or error free transfer of the advertisement to the Website.
                        </li>
                        <li>
                            We do not monitor or control and shall not be responsible for the content of your advertisement. Accordingly, you shall be responsible for any losses, expenses or other costs incurred by us as a result of an untrue statement or inaccurate information, photograph or video.
                        </li>
                        <li>
                            We reserve the right to refuse publication of an advertisement without giving any reason. Further, it is within our sole discretion to classify, edit and/or delete an advertisement at our sole discretion. Where possible, we will attempt to inform you of any such changes.
                        </li>
                        <li>
                            We may at any time vary the technical specifications of the Website (or any part thereof) for operational reasons.
                        </li>
                        <li>
                            We may at any time remove any or all of the material and/or statements appearing in the advertisement from the advertisement which in our opinion are unlawful or constitute a breach of this agreement.
                        </li>
                        <li>
                            In the event that we do not accept an advertisement, we will, if possible, inform you accordingly. In the event that we reject the advertisement, no charge will be incurred by you. Further, no contract will be deemed to exist between you and us until such time as the advertisement has been accepted for posting on the Website and payment is made.
                        </li>
                        <li>
                            From time to time we will need to carry out maintenance on our equipment and systems, and we shall use our reasonable endeavours to ensure that in so doing and to the extent possible, minimum disruption to the advertising is caused.
                        </li>
                    </ul>
                </p>

                <h3>8. Media Files and Photographs</h3>
                <p class="short_desc">
                    <ul style="list-style-type: square; margin-left: 40px;">
                        <li>
                            Media files and/or photographs may be checked by us to ensure that the content meets the requirements as set out in these Conditions.
                        </li>
                        <li>
                            If your media file and/or photograph includes infringing material and amongst others contains nudity, profanity, pornography, drug use, violence, attacks on individuals or groups (including sexist, racist, defamatory or homophobic material), obscene material, copyright protected material including music or radio audio, material that could be considered dangerous or encourage others to be dangerous, we reserve the right to remove it from the Website.
                        </li>
                        <li>
                            Media files and photographs should only advertise one vehicle.
                        </li>
                    </ul>
                </p>

                <h3>9. Amendments</h3>
                <p class="short_desc">
                    <ul style="list-style-type: square; margin-left: 40px;">
                        <li>
                            Any amendments to your advertisement, can be done via the online edit functionality or by contacting Customer Services on <strong>0860 60 50 50</strong>.
                        </li>
                        <li>
                           	If you are experiencing any difficulties in relation to amendments or you wish to enquire whether changes can be made, please contact Customer Services on <strong>0860 60 50 50</strong> during business hours and as displayed on the Website or email your enquiry to <a style="text-decoration: underline;" href="mailto:info@pslink.co.za">customer service</a> providing your advertisement reference number, vehicle registration number or postcode.
                        </li>
                    </ul>
                </p>

                <h3>10. Cancellation and Refunds</h3>
                <p class="short_desc">
                    All advertisements will be processed immediately and payments are non-refundable once the advertisement appears on the Website. Please note that advertisements may take up to 24 hours to appear on the Website.
                </p>

                <h3>11. Liability</h3>
                <p class="short_desc">
                    <ul style="list-style-type: square; margin-left: 40px;">
                        <li>
                            We shall not be liable to you for any
                            <ul style="list-style-type: square; margin-left: 40px;">
                                <li>consequential, indirect or special loss or damage;</li>
                                <li>loss of goodwill or reputation;</li>
                                <li>loss of business and/or opportunity;</li>
                                <li>loss of profits;</li>
                                <li>loss of revenue; and/or</li>
                                <li>economic and/or other similar losses; and in each case such liability is excluded whether it is foreseeable, known, foreseen or otherwise and whether such losses are direct, indirect, consequential or otherwise</li>
                            </ul>
                            arising out of or in connection with any total or partial failure to publish an advertisement on the Website; loss of copy, artwork, photographs or other material; in contract, delict (including, without limitation, negligence), statutory duty or otherwise arising out of or in connection with your agreement with us.
                        </li>
                        <li>
                           	Our total liability to you in contract, delict (including, without limitation, negligence), statutory duty or otherwise (unless otherwise excluded) shall not exceed the advertising rates paid by you for the relevant advertisement in the timeframe during which the event, giving rise to the liability, occurred.
                        </li>
                        <li>
                            In the event of any error or omission in an advertisement resulting from our negligence, we may at our sole discretion either amend the relevant part of the advertisement, refund you or adjust the advertising rates. No refund, amendment or adjustment to the advertising rates will be made where, in our sole opinion, the error, misprint or omission does not materially detract from the advertisement or where it arises as a result of incorrect or inadequate information provided by you.
                        </li>
                        <li>
                            We shall not be liable to you for any delay in performing and/or any failure to perform our obligations if the delay or failure is due to any cause beyond our reasonable control (including, without limitation, any act of God, strike, infrastructure failure, fire, or power failure).
                        </li>
                    </ul>
                </p>    

                <h3>12. Data Protection</h3>
                <p class="short_desc">
                    <ul style="list-style-type: square; margin-left: 40px;">
                        <li>
                            By submitting an advertisement to us, you request the placement of the advertisement on the Website. This means that the advertisement together with your telephone number and company details (where applicable) can be viewed by all persons with internet access throughout the world. You consent to the publication of your data in this way.
                        </li>
                        <li>
                           	We warrant that we shall comply with the provisions of the Data Protection Act 1998 as may be amended from time to time in so far as our agreement with you relates to or involves the processing of Personal Data (as defined in the Act).
                        </li>
                        <li>
                            You acknowledge and agree that by placing an advertisement with us, we may collect certain Personal Data relating to you and/or your employees, and/or consultants and/or contractors (as applicable). This Personal Data may be used for a number of purposes as further detailed in our Privacy Policy on the Website. We advise that you familiarise yourself with such policy <a style="text-decoration: underline;" href="/privacy-policy">(click here)</a>. Amongst others, this information may be used for fraud prevention purposes, assisting with police investigations and/or enquiries and/or complying with statutory and regulatory obligations.
                        </li>
                    </ul>
                </p>

                <h3>13. General</h3>
                <p class="short_desc">
                    <ul style="list-style-type: square; margin-left: 40px;">
                        <li>
                            These Conditions and any order form (where relevant) contain the entire agreement between us and you and supersede all prior agreements.
                        </li>
                        <li>
                           	If any provision of these Conditions is deemed by a competent authority to be invalid, unlawful or otherwise unenforceable then such provision shall be severed from the remaining Conditions which shall continue to be valid and enforceable to the fullest extent permitted by law.
                        </li>
                        <li>
                            We reserve the right to assign or sub-contract this agreement and any of our rights and/or obligations to a third party.
                        </li>
                        <li>
                            We reserve the right to modify these Conditions from time to time and any such modifications shall be effective immediately from the time you are notified thereof. Display of the modified Conditions on the Website shall be deemed to be notice to you of such modifications. You agree to review the Conditions regularly to ensure that you are aware of any modifications.
                        </li>
                        <li>
                            The agreement which incorporates these Conditions shall be governed by and construed in accordance with South African Law and the parties irrevocably submit to the exclusive jurisdiction of the South African Courts.
                        </li>
                    </ul>
                </p>

                <h3>14. Dealer Advertising</h3>
                <p class="short_desc">
                    <ul style="list-style-type: square; margin-left: 40px;">
                        <li>
                            In addition to the above and with specific reference to dealer advertising, such dealer hereby agrees to have complied with the Business Advertisement Disclosure Order 1977 which came into effect on 1 January 1978.
                        </li>
                        <li>
                            Such order requires that all advertisements by people who seek to sell goods in the course of business, shall make such fact clear. This requirement applies whether the advertiser is acting on his own behalf, or for some other person in the course of whose business goods are to be sold. Customers should be able to tell whether an advertisement relates to a sale by a dealer or private seller. Non compliance therewith is an offence.
                        </li>
                        <li>
                            It is your responsibility to ensure that, where applicable, your advertisement states that you are a dealer. Hence, the placing of an order for dealer advertising to be included on the Website shall amount to an acceptance of these conditions. Any agency conditions stipulated on an agency's order shall be void in so far as they are in conflict with these Conditions.
                        </li>
                    </ul>
                </p>

                <h3>15. Trade Online Advertisements ("TOLA")</h3>
                <p class="short_desc">
                    <ul style="list-style-type: square; margin-left: 40px;">
                        <li>
                            You have the option of purchasing advertising space from us online and of paying for same online by accessing our website and utilising the self serve channel.
                        </li>
                        <li>
                            Payments can be made using credit card or by direct electronic funds transfer ("EFT").
                        </li>
                        <li>
                            Use of TOLA is subject to all of the terms and conditions contained herein as and where applicable including also our right;
                            <ul style="list-style-type: square; margin-left: 40px;">
                                <li>
                                    to refuse and/or withdraw any advertisement tendered or placed at your instance at any time in our sole unfettered discretion;
                                </li>
                                <li>
                                    to refuse the placement of any advertisement from you in circumstances where you have not honoured any of your obligations to ourselves and/or effected payment to us of monies due;
                                </li>
                                <li>
                                    to remove this facility known as TOLA from our website entirely;
                                </li>
                                <li>
                                    to modify, include or change in any way any aspect of TOLA including pricing, delivery time, deadlines and the like within our sole unfettered discretion.
                                </li>
                            </ul>
                        </li>
                        <li>
                            To place advertisements through TOLA, payment has to be made upfront via credit card or EFT prior to the deadline cut off date which is each and every Monday before 13h00 on the self serve channel.
                        </li>
                        <li>
                            No adverts will be placed through TOLA unless prior payment has been made.
                        </li>
                    </ul>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Uren's About Us Area End Here -->
    </template>
  </base-wrapper>
</template>

<script>
import BaseWrapper from "../../components/website/BaseWrapper.vue";

export default {
  components: { BaseWrapper },
  created() {
    document.title = "PSI Link | Terms & Conditions";
  },
};
</script>
