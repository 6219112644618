<template>
  <base-wrapper>
    <template v-slot:content>
      <!-- Begin Uren's Breadcrumb Area -->
      <div class="breadcrumb-area">
        <div class="container">
          <div class="breadcrumb-content">
            <ul>
              <li><a href="/">Home</a></li>
              <li class="active">About Us</li>
            </ul>
          </div>
        </div>
      </div>
      <!-- Uren's Breadcrumb Area End Here -->
      <!-- Begin Uren's About Us Area -->
      <div class="about-us-area">
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-6 col-md-5">
              <div class="overview-img text-center img-hover_effect">
                <a href="#">
                  <img
                    class="img-full"
                    src="@/assets/images/about-us/1.jpg"
                    alt="Uren's About Us Image"
                  />
                </a>
              </div>
            </div>
            <div class="col-lg-6 col-md-7 d-flex align-items-center">
              <div class="overview-content">
                <h2>Welcome To <span>PS</span> Link!</h2>
                <p class="short_desc">
                  PS Link.co.za is a marketplace where buyers can find
                  automotive parts and components and find qualified
                  professionals for any technical service needed. Sales
                  categories include components for cars, bakkies, motorcycles,
                  light and heavy commercial vehicles, as well as light and
                  heavy commercial trailers. Technical services include repair
                  and maintenance technicians, breakdown assistance, technical
                  reports, technical investigation and evaluation, and compiling
                  inventory lists. Subscription Benefits: (listed on
                  registration page for sellers/service providers) Sellers No
                  commission on sales. Buyers register wish lists free of charge
                  and contact sellers directly. Sellers receive trends lists and
                  are notified when matching items appear on wish lists.
                  Technical Service Providers No commission. Customers can
                  search geographically to find available service providers in
                  their area. Service providers can be rated to raise profiles
                  and increase trust rating. Capitalise on your expertise,
                  planned Competition Commission Legislation will allow
                  independent service providers to perform in-warranty
                  maintenance and repairs. (Wish list items to include technical
                  services according to categories and geographic location, if
                  none available, we are notified and can respond with in-house
                  assistance i.e., inventory and technical reports. But I don’t
                  want it advertised as an in-house service)
                </p>
                <div class="uren-about-us_btn-area">
                  <a class="about-us_btn" href="shop-left-sidebar.html"
                    >Shop Now</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Uren's About Us Area End Here -->

      <!-- Begin Uren's Project Countdown Area -->
      <div class="project-count-area">
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-6">
              <div class="single-count text-center">
                <div class="count-icon">
                  <span class="ion-ios-briefcase-outline"></span>
                </div>
                <div class="count-title">
                  <h2 class="count">169</h2>
                  <span>Car Accessories</span>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
              <div class="single-count text-center">
                <div class="count-icon">
                  <span class="ion-ios-wineglass-outline"></span>
                </div>
                <div class="count-title">
                  <h2 class="count">869</h2>
                  <span>Parts Sold</span>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
              <div class="single-count text-center">
                <div class="count-icon">
                  <span class="ion-ios-lightbulb-outline"></span>
                </div>
                <div class="count-title">
                  <h2 class="count">89</h2>
                  <span>Service Providers</span>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
              <div class="single-count text-center">
                <div class="count-icon">
                  <span class="ion-happy-outline"></span>
                </div>
                <div class="count-title">
                  <h2 class="count">109</h2>
                  <span>Happy Customers</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Uren's Project Countdown Area End Here -->

      <!-- Begin Uren's Team Area -->
      <div class="team-area">
        <!-- <div class="container-fluid">
          <div class="row">
            <div class="col-12">
              <div class="section_title-2">
                <h3>Our Team</h3>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-6">
              <div class="team-member">
                <div class="team-thumb img-hover_effect">
                  <a href="#">
                    <img
                      src="@/assets/images/about-us/team/1.jpg"
                      alt="Our Team Member"
                    />
                  </a>
                </div>
                <div class="team-content text-center">
                  <h3>Erwin Vermeulen</h3>
                  <p>CEO</p>
                  <a href="#">erwin@pslink.co.za</a>
                  <div class="uren-social_link">
                    <ul>
                      <li class="facebook">
                        <a
                          href="https://www.facebook.com/"
                          data-toggle="tooltip"
                          target="_blank"
                          title="Facebook"
                        >
                          <i class="fab fa-facebook"></i>
                        </a>
                      </li>
                      <li class="twitter">
                        <a
                          href="https://twitter.com/"
                          data-toggle="tooltip"
                          target="_blank"
                          title="Twitter"
                        >
                          <i class="fab fa-twitter-square"></i>
                        </a>
                      </li>
                      <li class="youtube">
                        <a
                          href="https://www.youtube.com/"
                          data-toggle="tooltip"
                          target="_blank"
                          title="Youtube"
                        >
                          <i class="fab fa-youtube"></i>
                        </a>
                      </li>
                      <li class="google-plus">
                        <a
                          href="https://www.plus.google.com/discover"
                          data-toggle="tooltip"
                          target="_blank"
                          title="Google Plus"
                        >
                          <i class="fab fa-google-plus"></i>
                        </a>
                      </li>
                      <li class="instagram">
                        <a
                          href="https://rss.com/"
                          data-toggle="tooltip"
                          target="_blank"
                          title="Instagram"
                        >
                          <i class="fab fa-instagram"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
              <div class="team-member">
                <div class="team-thumb img-hover_effect">
                  <a href="#">
                    <img
                      src="@/assets/images/about-us/team/2.jpg"
                      alt="Our Team Member"
                    />
                  </a>
                </div>
                <div class="team-content text-center">
                  <h3>The New Guy</h3>
                  <p>Parts Manager</p>
                  <a href="#">info@pslink.co.za</a>
                  <div class="uren-social_link">
                    <ul>
                      <li class="facebook">
                        <a
                          href="https://www.facebook.com/"
                          data-toggle="tooltip"
                          target="_blank"
                          title="Facebook"
                        >
                          <i class="fab fa-facebook"></i>
                        </a>
                      </li>
                      <li class="twitter">
                        <a
                          href="https://twitter.com/"
                          data-toggle="tooltip"
                          target="_blank"
                          title="Twitter"
                        >
                          <i class="fab fa-twitter-square"></i>
                        </a>
                      </li>
                      <li class="youtube">
                        <a
                          href="https://www.youtube.com/"
                          data-toggle="tooltip"
                          target="_blank"
                          title="Youtube"
                        >
                          <i class="fab fa-youtube"></i>
                        </a>
                      </li>
                      <li class="google-plus">
                        <a
                          href="https://www.plus.google.com/discover"
                          data-toggle="tooltip"
                          target="_blank"
                          title="Google Plus"
                        >
                          <i class="fab fa-google-plus"></i>
                        </a>
                      </li>
                      <li class="instagram">
                        <a
                          href="https://rss.com/"
                          data-toggle="tooltip"
                          target="_blank"
                          title="Instagram"
                        >
                          <i class="fab fa-instagram"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
              <div class="team-member">
                <div class="team-thumb img-hover_effect">
                  <a href="#">
                    <img
                      src="@/assets/images/about-us/team/3.jpg"
                      alt="Our Team Member"
                    />
                  </a>
                </div>
                <div class="team-content text-center">
                  <h3>My New Guy</h3>
                  <p>Sales Manager</p>
                  <a href="javascript:void(0)">info@pslink.co.za</a>
                  <div class="uren-social_link">
                    <ul>
                      <li class="facebook">
                        <a
                          href="https://www.facebook.com/"
                          data-toggle="tooltip"
                          target="_blank"
                          title="Facebook"
                        >
                          <i class="fab fa-facebook"></i>
                        </a>
                      </li>
                      <li class="twitter">
                        <a
                          href="https://twitter.com/"
                          data-toggle="tooltip"
                          target="_blank"
                          title="Twitter"
                        >
                          <i class="fab fa-twitter-square"></i>
                        </a>
                      </li>
                      <li class="youtube">
                        <a
                          href="https://www.youtube.com/"
                          data-toggle="tooltip"
                          target="_blank"
                          title="Youtube"
                        >
                          <i class="fab fa-youtube"></i>
                        </a>
                      </li>
                      <li class="google-plus">
                        <a
                          href="https://www.plus.google.com/discover"
                          data-toggle="tooltip"
                          target="_blank"
                          title="Google Plus"
                        >
                          <i class="fab fa-google-plus"></i>
                        </a>
                      </li>
                      <li class="instagram">
                        <a
                          href="https://rss.com/"
                          data-toggle="tooltip"
                          target="_blank"
                          title="Instagram"
                        >
                          <i class="fab fa-instagram"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
              <div class="team-member">
                <div class="team-thumb img-hover_effect">
                  <a href="#">
                    <img
                      src="@/assets/images/about-us/team/4.jpg"
                      alt="Our Team Member"
                    />
                  </a>
                </div>
                <div class="team-content text-center">
                  <h3>Eddy Adams</h3>
                  <p>Marketing officer</p>
                  <a href="#">info@pslink.co.za</a>
                  <div class="uren-social_link">
                    <ul>
                      <li class="facebook">
                        <a
                          href="https://www.facebook.com/"
                          data-toggle="tooltip"
                          target="_blank"
                          title="Facebook"
                        >
                          <i class="fab fa-facebook"></i>
                        </a>
                      </li>
                      <li class="twitter">
                        <a
                          href="https://twitter.com/"
                          data-toggle="tooltip"
                          target="_blank"
                          title="Twitter"
                        >
                          <i class="fab fa-twitter-square"></i>
                        </a>
                      </li>
                      <li class="youtube">
                        <a
                          href="https://www.youtube.com/"
                          data-toggle="tooltip"
                          target="_blank"
                          title="Youtube"
                        >
                          <i class="fab fa-youtube"></i>
                        </a>
                      </li>
                      <li class="google-plus">
                        <a
                          href="https://www.plus.google.com/discover"
                          data-toggle="tooltip"
                          target="_blank"
                          title="Google Plus"
                        >
                          <i class="fab fa-google-plus"></i>
                        </a>
                      </li>
                      <li class="instagram">
                        <a
                          href="https://rss.com/"
                          data-toggle="tooltip"
                          target="_blank"
                          title="Instagram"
                        >
                          <i class="fab fa-instagram"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> -->
      </div>
      <!-- Uren's Team Area End Here -->
    </template>
  </base-wrapper>
</template>

<script>
import BaseWrapper from "../../components/website/BaseWrapper.vue";

export default {
  components: { BaseWrapper },
  created() {
    document.title = "PSI Link | About Us";
  },
};
</script>
