import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";

export default {
  namespaced: true,
  state() {
    return {
      subscription_types: [],
      subscription_type: null,
    };
  },
  mutations,
  actions,
  getters,
};
