import axios from "axios";

const API_URL = process.env.VUE_APP_API_URL + "subscription-types/";

class SubscriptionTypeService {
  getConfig(token) {
    return {
      headers: { Authorization: `Token ${token}` },
    };
  }

  async createSubcriptionType(token, data) {
    return await axios.post(API_URL, data, this.getConfig(token));
  }

  async getSubcriptionTypes(token) {
    return await axios.get(API_URL, this.getConfig(token));
  }

  async getSubcriptionType(token, slug) {
    return await axios.get(API_URL + slug, this.getConfig(token));
  }
}

export default new SubscriptionTypeService();
