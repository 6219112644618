export default {
  subscription_types(state) {
    return state.subscription_types;
  },

  subscription_type(state) {
    return state.subscription_type;
  },

  hasSubscriptionTypes(state) {
    return state.subscription_types && state.subscription_types.length > 0;
  },

  hasSubscriptionType(state) {
    return state.subscription_type !== null;
  },
};
