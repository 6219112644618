<template>
  <div>
    <base-dialog :show="isLoading" title="Loading..." fixed>
      <base-spinner></base-spinner>
    </base-dialog>
    <admin-header></admin-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col">
          <parts-table
            :service="service"
            :tableData="parts"
            title="Parts Table"
            type="dark"
          ></parts-table>
        </div>
      </div>
    </div>
    <modal v-model:show="noPartsDialog">
      <template v-slot:header>
        <h6 class="modal-title" id="modal-title-default">Create Parts</h6>
      </template>

      <p>
        You currently have no parts on your profile please select of the options
        below:
      </p>

      <template v-slot:footer>
        <!-- <base-button type="primary">Create a single part</base-button> -->
        <a href="/admin-add-part" class="btn btn-primary btn-block"
          >Create a single part</a
        >
        <a href="/admin-upload-parts" class="btn btn-secondary btn-block"
          >Create parts using spreadsheet (bulk)</a
        >
        <!-- <base-button type="secondary">Create parts using spreadsheet (bulk)</base-button> -->
        <!-- <base-button type="link" class="ml-auto" @click="modals.modal1 = false">Close
              </base-button> -->
      </template>
    </modal>
  </div>
</template>
<script>
import PartsTable from "../Tables/PartsTable.vue";

export default {
  components: {
    PartsTable,
  },
  data() {
    return {
      noPartsDialog: false,
      isLoading: false,
    };
  },
  computed: {
    loggedInUser() {
      return this.$store.getters.user;
    },
    parts() {
      return this.$store.getters["parts/parts"];
    },
    hasParts() {
      return this.$store.getters["parts/hasParts"];
    },
    service() {
      return this.$store.getters["services/service"];
    },
  },
  methods: {
    async loadParts() {
      this.isLoading = true;
      try {
        if (this.loggedInUser.is_service) {
          await this.$store.dispatch("parts/loadParts", {
            params: {
              serviceSlug: this.service.slug,
            },
          });
        } else {
          await this.$store.dispatch("parts/loadParts");
        }
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;
    },
    async loadService() {
      this.isLoading = true;
      try {
        await this.$store.dispatch("services/getService", {
          serviceId: this.loggedInUser.id,
        });
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;
    },
  },
  async created() {
    document.title = "PSI Link | Admin View Parts";
    if (this.loggedInUser.is_service) {
      await this.loadService();
    }
    await this.loadParts();
    if (this.loggedInUser.is_service && !this.hasParts) {
      this.noPartsDialog = true;
    }
  },
};
</script>
<style></style>
