<template>
  <base-wrapper>
    <template v-slot:content>
      <page-loader v-if="isLoading"></page-loader>
      <base-dialog
        :show="pageErrors.length > 0 || successMsg !== ''"
        :title="alertTitle"
        @close="handleError"
      >
        <div v-if="pageErrors.length > 0 && successMsg === ''">
          <base-alert v-for="error in pageErrors" :key="error" type="danger">
            <span class="alert-inner--text">{{ error }}</span>
          </base-alert>
        </div>
        <div v-else>
          <base-alert type="success">
            <span class="alert-inner--text">{{ successMsg }}</span>
          </base-alert>
        </div>
      </base-dialog>
      <!-- Begin Uren's Breadcrumb Area -->
      <div class="breadcrumb-area">
        <div class="container">
          <div class="breadcrumb-content">
            <h2>Hello, Test User</h2>
            <ul>
              <li><a href="/">Home</a></li>
              <li class="active">My Account</li>
            </ul>
          </div>
        </div>
      </div>
      <!-- Uren's Breadcrumb Area End Here -->
      <!-- Begin Uren's Page Content Area -->
      <main class="page-content">
        <!-- Begin Uren's Account Page Area -->
        <div class="account-page-area">
          <div class="container-fluid">
            <div class="row">
              <div class="col-lg-3">
                <ul
                  class="nav myaccount-tab-trigger"
                  id="account-page-tab"
                  role="tablist"
                >
                  <li class="nav-item">
                    <a
                      class="nav-link active"
                      id="account-dashboard-tab"
                      data-toggle="tab"
                      href="#account-dashboard"
                      role="tab"
                      aria-controls="account-dashboard"
                      aria-selected="true"
                      >Dashboard</a
                    >
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      id="account-orders-tab"
                      data-toggle="tab"
                      href="#account-orders"
                      role="tab"
                      aria-controls="account-orders"
                      aria-selected="false"
                      >Wishlist</a
                    >
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      id="account-address-tab"
                      data-toggle="tab"
                      href="#account-address"
                      role="tab"
                      aria-controls="account-address"
                      aria-selected="false"
                      >Address</a
                    >
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      id="account-details-tab"
                      data-toggle="tab"
                      href="#account-details"
                      role="tab"
                      aria-controls="account-details"
                      aria-selected="false"
                      >Account Details</a
                    >
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      id="account-logout-tab"
                      @click="logout"
                      role="tab"
                      aria-selected="false"
                      >Logout</a
                    >
                  </li>
                </ul>
              </div>
              <div class="col-lg-9">
                <div
                  class="tab-content myaccount-tab-content"
                  id="account-page-tab-content"
                >
                  <div
                    class="tab-pane fade show active"
                    id="account-dashboard"
                    role="tabpanel"
                    aria-labelledby="account-dashboard-tab"
                  >
                    <div class="myaccount-dashboard">
                      <p>Hello <b>Test User</b></p>
                      <p>
                        From your account dashboard you can view your wish list
                        and
                        <a href="javascript:void(0)"
                          >edit your password and account details</a
                        >.
                      </p>
                    </div>
                  </div>
                  <div
                    class="tab-pane fade"
                    id="account-orders"
                    role="tabpanel"
                    aria-labelledby="account-orders-tab"
                  >
                    <div
                      class="myaccount-orders"
                      v-if="hasWishlist && wishlist.parts.length > 0"
                    >
                      <h4 class="small-title">MY WISHLIST</h4>
                      <div class="table-responsive">
                        <table class="table table-bordered table-hover">
                          <tbody>
                            <tr>
                              <th>PART</th>
                              <th>PART TYPE</th>
                              <th>VEHICLE TYPE</th>
                              <th>AVAILABILTY</th>
                              <th></th>
                            </tr>
                            <tr v-for="part in wishlist.parts" :key="part.id">
                              <td>
                                <a
                                  class="account-order-id"
                                  href="javascript:void(0)"
                                  >{{ part.name }}</a
                                >
                              </td>
                              <td>{{ part.type.toUpperCase() }}</td>
                              <td>
                                {{
                                  `${part.vehicleMake} ${part.vehicleModel} ${part.vehicleYear}`
                                }}
                              </td>
                              <td>
                                {{
                                  part.quantity > 0
                                    ? "In Stock"
                                    : "Not Available"
                                }}
                              </td>
                              <td>
                                <a
                                  href="javascript:void(0)"
                                  class="btn btn-sm btn-default"
                                  ><span>View</span></a
                                >
                                <a
                                  @click="deleteFromWishlist(part.id)"
                                  class="btn btn-sm btn-danger"
                                  ><i class="fa fa-trash"></i
                                ></a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div class="myaccount-orders" v-else>
                      <h4 class="small-title">
                        Your wishlist is currently empty
                      </h4>
                    </div>
                  </div>
                  <div
                    class="tab-pane fade"
                    id="account-address"
                    role="tabpanel"
                    aria-labelledby="account-address-tab"
                  >
                    <div class="myaccount-address">
                      <p>
                        The following addresses will be used to in your relative
                        searches.
                      </p>
                      <div class="row">
                        <div class="col" v-if="hasCustomer && customer.address">
                          <h4 class="small-title">ADDRESS</h4>

                          <!-- <address>
                            {{ customer.address }}
                          </address> -->
                          <Form
                            class="uren-form"
                            @submit="onSubmitUpdateCustomer"
                            :validation-schema="schemaA"
                            v-slot="{ errors }"
                          >
                            <div class="uren-form-inner">
                              <div class="single-input" v-if="hasCustomer">
                                <label
                                  :class="{
                                    'invalid-feedback': errors.address,
                                  }"
                                  for="account-details-address"
                                  >Address</label
                                >
                                <Field
                                  as="textarea"
                                  :value="customer.address"
                                  id="account-details-address"
                                  :class="{ 'is-invalid': errors.address }"
                                  name="address"
                                  @input="
                                    updateCustomerState('address', $event)
                                  "
                                  class="form-control"
                                />
                                <div class="invalid-feedback">
                                  {{ errors.address }}
                                </div>
                              </div>
                              <div class="single-input">
                                <button
                                  class="uren-btn uren-btn_dark"
                                  type="submit"
                                >
                                  <span>SAVE CHANGES</span>
                                </button>
                              </div>
                            </div>
                          </Form>
                        </div>
                        <div class="col" v-else>
                          <h4 class="small-title">
                            Customer has not added their address
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="tab-pane fade"
                    id="account-details"
                    role="tabpanel"
                    aria-labelledby="account-details-tab"
                  >
                    <div class="myaccount-details" v-if="isLoggedIn">
                      <Form
                        class="uren-form"
                        @submit="onSubmitUpdateUser"
                        :validation-schema="schema"
                        v-slot="{ errors }"
                      >
                        <div class="uren-form-inner">
                          <div class="single-input single-input-half">
                            <label
                              :class="{ 'invalid-feedback': errors.first_name }"
                              for="account-details-firstname"
                              >First Name*</label
                            >
                            <Field
                              name="first_name"
                              id="account-details-firstname"
                              class="form-control"
                              :class="{ 'is-invalid': errors.first_name }"
                              :value="loggedInUser.first_name"
                              type="text"
                              @input="updateState('first_name', $event)"
                              placeholder="First Name"
                            />
                            <div class="invalid-feedback">
                              {{ errors.first_name }}
                            </div>
                          </div>
                          <div class="single-input single-input-half">
                            <label
                              :class="{ 'invalid-feedback': errors.last_name }"
                              for="account-details-lastname"
                              >Last Name*</label
                            >
                            <Field
                              name="last_name"
                              id="account-details-lastname"
                              class="form-control"
                              :class="{ 'is-invalid': errors.last_name }"
                              :value="loggedInUser.last_name"
                              type="text"
                              @input="updateState('last_name', $event)"
                              placeholder="Last Name"
                            />
                            <div class="invalid-feedback">
                              {{ errors.last_name }}
                            </div>
                          </div>
                          <div class="single-input">
                            <label
                              :class="{ 'invalid-feedback': errors.email }"
                              for="account-details-email"
                              >Email*</label
                            >
                            <Field
                              name="email"
                              id="account-details-email"
                              class="form-control"
                              :class="{ 'is-invalid': errors.email }"
                              :value="loggedInUser.email"
                              type="email"
                              @input="updateState('email', $event)"
                              placeholder="Email Address"
                            />
                            <div class="invalid-feedback">
                              {{ errors.email }}
                            </div>
                          </div>
                          <div class="single-input">
                            <label
                              :class="{ 'invalid-feedback': errors.username }"
                              for="account-details-username"
                              >Username*</label
                            >
                            <Field
                              name="username"
                              id="account-details-username"
                              class="form-control"
                              :class="{ 'is-invalid': errors.username }"
                              :value="loggedInUser.username"
                              type="text"
                              @input="updateState('username', $event)"
                              placeholder="Username"
                            />
                            <div class="invalid-feedback">
                              {{ errors.username }}
                            </div>
                          </div>
                          <div class="single-input">
                            <button
                              class="uren-btn uren-btn_dark"
                              type="submit"
                            >
                              <span>SAVE CHANGES</span>
                            </button>
                          </div>
                        </div>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Uren's Account Page Area End Here -->
      </main>
      <!-- Uren's Page Content Area End Here -->
    </template>
  </base-wrapper>
</template>

<script>
import BaseWrapper from "../../components/website/BaseWrapper.vue";
import PageLoader from "../../components/website/PageLoader.vue";
import { Field, Form } from "vee-validate";
import * as yup from "yup";

export default {
  components: { BaseWrapper, PageLoader, Form, Field },
  data() {
    return {
      isLoading: false,
      pageErrors: [],
      successMsg: "",
    };
  },
  computed: {
    schema() {
      const schema = yup.object({
        first_name: yup.string().required().min(4),
        last_name: yup.string().required().min(4),
        username: yup.string().required().min(4),
        email: yup.string().required().email(),
      });

      return schema;
    },
    schemaA() {
      const schema = yup.object({
        address: yup.string().required(),
      });

      return schema;
    },
    alertTitle() {
      if (this.pageErrors.length > 0 && this.successMsg === "") {
        return this.pageErrors.length === 1
          ? "An Error has occurred"
          : "A couple of errors have occured";
      } else {
        return "Details Update";
      }
    },
    isLoggedIn() {
      return this.$store.getters.isAuthenticated;
    },
    loggedInUser() {
      return this.$store.getters.user;
    },
    hasCustomer() {
      return this.$store.getters["services/hasCustomer"];
    },
    hasWishlist() {
      return this.$store.getters["wishlist/hasWishlist"];
    },
    wishlist() {
      return this.$store.getters["wishlist/wishlist"];
    },
    customer() {
      return this.$store.getters["services/customer"];
    },
  },
  methods: {
    async loadCustomer() {
      try {
        await this.$store.dispatch("services/getCustomer", {
          customerId: this.loggedInUser.id,
        });
      } catch (error) {
        console.log(error);
      }
    },
    async loadWishlist() {
      try {
        await this.$store.dispatch("wishlist/getWishlist", {
          customerId: this.loggedInUser.id,
        });
      } catch (error) {
        console.log(error);
      }
    },
    async deleteFromWishlist(partId) {
      if (confirm("Are you sure you want to remove from your wishlist?")) {
        // Save it!
        this.isLoading = true;
        this.$store.dispatch("wishlist/deletePartWishist", {
          partId: partId,
        });
        try {
          await this.$store.dispatch("wishlist/patchWishlist", {
            slug: this.wishlist.customer,
            wishlist: this.wishlist,
          });
        } catch (error) {
          console.log(error);
        }
        // console.log("Thing was saved to the database.");
      } else {
        // Do nothing!
        console.log("Thing was not saved to the database.");
      }
      this.isLoading = false;
    },
    updateState(prop, value) {
      value = value.target.value;
      this.$store.dispatch("updateUser", { prop, value }).catch(() => {
        console.log("Could not update user data");
      });
    },
    updateCustomerState(prop, value) {
      value = value.target.value;
      this.$store
        .dispatch("services/updateCustomer", { prop, value })
        .catch(() => {
          console.log("Could not update user data");
        });
    },
    async onSubmitUpdateUser() {
      this.isLoading = true;
      try {
        await this.$store.dispatch("updateUserInfo", {
          user: this.loggedInUser,
        });
        this.successMsg = "User details have been successfully updated.";
      } catch (error) {
        console.log(error);
        console.log(error.response.data);
        if (error.response.data.non_field_errors) {
          this.pageErrors = error.response.data.non_field_errors;
        } else {
          for (const key in error.response.data) {
            this.pageErrors.push(
              error.response.data[key][0] + " (" + key.toUpperCase() + ")"
            );
          }
        }
      }
      this.isLoading = false;
      console.log(this.pageErrors);
    },
    async onSubmitUpdateCustomer() {
      this.isLoading = true;
      try {
        await this.$store.dispatch("services/updateCustomerInfo", {
          slug: this.loggedInUser.id,
          customer: this.customer,
        });
        this.successMsg = "Customer details have been successfully updated.";
      } catch (error) {
        console.log(error);
        console.log(error.response.data);
        if (error.response.data.non_field_errors) {
          this.pageErrors = error.response.data.non_field_errors;
        } else {
          for (const key in error.response.data) {
            this.pageErrors.push(
              error.response.data[key][0] + " (" + key.toUpperCase() + ")"
            );
          }
        }
      }
      this.isLoading = false;
      console.log(this.pageErrors);
    },
    logout() {
      this.$store.dispatch("logout");
      this.$router.replace("/");
    },
    handleError() {
      this.pageErrors = [];
      this.successMsg = "";
    },
  },
  async created() {
    document.title = "PSI Link | My Account";
    this.isLoading = true;
    await this.loadCustomer();
    await this.loadWishlist();
    this.isLoading = false;
  },
};
</script>
