<template>
  <div>
    <base-dialog :show="isLoading" title="Loading..." fixed>
      <base-spinner></base-spinner>
    </base-dialog>
    <base-header
      class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
      style="
        min-height: 600px;
        background-image: url(img/theme/car-img.jpg);
        background-size: cover;
        background-position: center top;
      "
    >
      <!-- Mask -->
      <span class="mask bg-gradient-success opacity-8"></span>
      <!-- Header container -->
      <div class="container-fluid d-flex align-items-center">
        <div class="row">
          <div class="col-lg-7 col-md-10"></div>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <!-- <div class="col-xl-4 order-xl-2 mb-5 mb-xl-0"></div> -->

        <div class="col-xl-12 order-xl-1">
          <card shadow type="secondary">
            <template v-slot:header>
              <div class="bg-white border-0">
                <div class="row align-items-center">
                  <div class="col-8">
                    <h3 class="mb-0">Add New Advert</h3>
                  </div>
                  <div class="col-4 text-right">
                    <a href="/admin-dashboard" class="btn btn-sm btn-primary"
                      >View All Adverts</a
                    >
                    <!-- <a href="/admin-adverts" class="btn btn-sm btn-warning"
                      >Upload Excel</a
                    > -->
                  </div>
                </div>
              </div>
            </template>

            <Form
              @submit="onSubmit"
              :validation-schema="schema"
              v-slot="{ errors }"
              enctype="multipart/form-data"
              id="addPartForm"
            >
              <h6 class="heading-small text-muted mb-4">Advert Information</h6>
              <div class="pl-lg-4">
                <div class="row">
                  <div class="col-lg-6 form-group">
                    <label :class="{ 'error-show': errors.name }"
                      >Advert Name</label
                    >
                    <Field
                      name="name"
                      class="form-control"
                      :class="{ 'is-invalid': errors.name }"
                      v-model="advert.name"
                      type="text"
                      placeholder="Advert Name"
                    />
                    <div class="invalid-feedback">
                      {{ errors.name }}
                    </div>
                  </div>
                  <div class="col-lg-6 form-group">
                    <label :class="{ 'error-show': errors.period }"
                      >Advert Period</label
                    >
                    <Field
                      name="period"
                      class="form-control"
                      :class="{ 'is-invalid': errors.period }"
                      v-model.number="advert.period"
                      type="number"
                      placeholder="Advert Period"
                    />
                    <div class="invalid-feedback">
                      {{ errors.period }}
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6 form-group">
                    <label :class="{ 'error-show': errors.advertType }"
                      >Advert Type</label
                    >
                    <Field
                      v-slot="{ field }"
                      name="advertType"
                      class="form-control"
                    >
                      <Multiselect
                        v-bind="field"
                        v-model="advert.advertType"
                        placeholder="Select Type"
                        value="small"
                        :options="[
                          { value: 'small', label: 'SMALL' },
                          { value: 'big', label: 'BIG' },
                          { value: 'multimedia', label: 'MULTIMEDIA' },
                        ]"
                      />
                    </Field>
                    <div class="invalid-feedback">
                      {{ errors.advertType }}
                    </div>
                  </div>
                  <div class="col-lg-6 form-group">
                    <label :class="{ 'error-show': errors.isActive }"
                      >Advert Active</label
                    >
                    <Field
                      name="isActive"
                      class="form-control"
                      :class="{ 'is-invalid': errors.isActive }"
                      v-model="advert.isActive"
                      type="checkbox"
                      placeholder="Advert Active"
                    />
                    <div class="invalid-feedback">
                      {{ errors.isActive }}
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6 form-group">
                    <label :class="{ 'error-show': errors.startDate }"
                      >Advert Start Date (YYYY-MM-DD)</label
                    >
                    <Field
                      name="startDate"
                      class="form-control"
                      :class="{ 'is-invalid': errors.startDate }"
                      v-model="advert.startDate"
                      type="text"
                      placeholder="Advert Start Date (YYYY-MM-DD)   "
                    />
                    <div class="invalid-feedback">
                      {{ errors.startDate }}
                    </div>
                  </div>
                  <div class="col-lg-6 form-group">
                    <label :class="{ 'error-show': errors.endDate }"
                      >Advert End Date (YYYY-MM-DD)</label
                    >
                    <Field
                      name="endDate"
                      class="form-control"
                      :class="{ 'is-invalid': errors.endDate }"
                      v-model="advert.endDate"
                      type="text"
                      placeholder="Advert End Date (YYYY-MM-DD)"
                    />
                    <div class="invalid-feedback">
                      {{ errors.endDate }}
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12 form-group">
                    <label :class="{ 'error-show': errors.description }"
                      >Advert Information</label
                    >
                    <Field
                      as="textarea"
                      v-model="advert.description"
                      name="description"
                      class="form-control"
                      rows="3"
                      placeholder="Advert Information..."
                    >
                    </Field>
                    <div
                      class="invalid-feedback"
                      :style="
                        errors.description
                          ? { display: 'block' }
                          : { display: 'none' }
                      "
                    >
                      {{ errors.description }}
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12 form-group">
                    <div v-if="loggedInUser.is_superuser" class="form-group">
                      <label :class="{ 'invalid-feedback': errors.service }"
                        >Select Service</label
                      >
                      <Field v-slot="{ field }" name="service">
                        <Multiselect
                          v-bind="field"
                          v-model="advert.service"
                          placeholder="Select Service"
                          :searchable="true"
                          :options="services"
                        />
                      </Field>
                      <div class="invalid-feedback">
                        {{ errors.service }}
                      </div>
                    </div>
                    <div v-else class="form-group">
                      <label :class="{ 'error-show': errors.service }"
                        >Select Service</label
                      >
                      <Field
                        disabled
                        name="service"
                        v-model="advert.service"
                        class="form-control"
                        placeholder="Service"
                      >
                      </Field>
                      <div class="invalid-feedback">
                        {{ errors.service }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr class="my-4" />
              <!-- Description -->
              <h6 class="heading-small text-muted mb-4">Advert Media</h6>
              <div class="pl-lg-4">
                <div class="form-group">
                  <label :class="{ 'error-show': errors.images }"
                    >Advert Media</label
                  >
                  <Field v-slot="{ field }" name="images" class="form-control">
                    <UploadImages
                      uploadMsg="Add Advert Media"
                      class="form-control"
                      v-bind="field"
                      :max="1"
                      maxError="Max files exceed. Only one file is allowed for upload."
                      @changed="onChange"
                    />
                  </Field>
                  <div class="invalid-feedback">
                    {{ errors.images }}
                  </div>
                </div>
              </div>
              <div class="pl-lg-4">
                <base-button
                  type="primary"
                  nativeType="submit"
                  block
                  icon="ni ni-bag-17"
                  >Save Advert</base-button
                >
              </div>
            </Form>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Field, Form } from "vee-validate";
import Multiselect from "@vueform/multiselect";
import UploadImages from "vue-upload-drop-images";
import * as yup from "yup";

export default {
  components: { Form, Field, Multiselect, UploadImages },
  data() {
    return {
      isLoading: false,
      pageErrors: [],
      advert: {
        name: "",
        advertType: "",
        description: "",
        startDate: "",
        endDate: "",
        isActive: false,
        period: 0,
        service: null,
        image: null,
        images: [],
      },
    };
  },
  computed: {
    typeValid() {
      return this.advert.advertType !== null;
    },
    serviceValid() {
      return this.advert.service !== null;
    },
    schema() {
      const errormsg =
        "must be a `number` type, but the final value was: `NaN`. Enter 0 if no value";
      const schema = yup.object({
        name: yup.string().required(),
        isActive: yup.object().nullable(),
        advertType: yup.string().required(),
        period: yup
          .number()
          .typeError("Period " + errormsg)
          .required()
          .min(1),
        startDate: yup.string().required(),
        endDate: yup.string().required(),
        service: yup.string().required(),
        images: yup.array().min(1).required(),
        description: yup.string().required(),
        // image: yup.object().required(),
      });

      return schema;
    },
    services() {
      const services = [];
      const servArr = this.$store.getters["services/services"];
      for (let index = 0; index < servArr.length; index++) {
        let element = { label: servArr[index].name, value: servArr[index].id };
        services.push(element);
      }
      return services;
    },
    service() {
      return this.$store.getters["services/service"];
    },
    isLoggedIn() {
      return this.$store.getters.isAuthenticated;
    },
    loggedInUser() {
      return this.$store.getters.user;
    },
    loggedInUserId() {
      return this.$store.getters.userId;
    },
  },
  async created() {
    if (this.loggedInUser.is_superuser) {
      await this.loadServices();
    } else {
      this.advert.service = this.loggedInUserId;
      await this.loadService();
    }
  },
  methods: {
    onChange(files) {
      // console.log(files);
      this.advert.images = files;
      console.log(this.advert.images);
    },
    async loadServices() {
      this.isLoading = true;
      try {
        await this.$store.dispatch("services/loadServices");
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;
    },
    async loadService() {
      this.isLoading = true;
      try {
        await this.$store.dispatch("services/getService", {
          serviceId: this.loggedInUser.id,
        });
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;
    },
    async onSubmit() {
      this.isLoading = true;
      const formData = new FormData();
      formData.append("name", this.advert.name);
      formData.append("advertType", this.advert.advertType);
      formData.append("isActive", this.advert.isActive ? true : false);
      formData.append("startDate", this.advert.startDate);
      formData.append("endDate", this.advert.endDate);
      formData.append("period", this.advert.period);
      formData.append("description", this.advert.description);
      formData.append("service", this.advert.service);

      formData.append("image", this.advert.images[0]);

      try {
        const success = await this.$store.dispatch("adverts/addAdvert", {
          form: formData,
        });
        if (success) {
          this.resetForm();
        }
      } catch (error) {
        console.log(error.response.data);
        if (error.response.data.non_field_errors) {
          this.pageErrors = error.response.data.non_field_errors;
        } else {
          for (const key in error.response.data) {
            this.pageErrors.push(
              error.response.data[key][0] + " (" + key.toUpperCase() + ")"
            );
          }
        }
      }
      this.isLoading = false;
    },
    resetForm() {
      this.advert = {
        name: "",
        advertType: "",
        description: "",
        startDate: "",
        endDate: "",
        isActive: false,
        period: 0,
        service: null,
        image: null,
        images: [],
      };
    },
  },
};
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped>
.error-show {
  color: #fb6340;
}
</style>
