import authService from "../../../services/auth.service";
import userService from "../../../services/user.service";

let timer;

export default {
  updateUser(context, payload) {
    context.commit("updateUser", payload);
  },
  async login(context, payload) {
    return context.dispatch("auth", {
      ...payload,
      mode: "login",
    });
  },
  async signup(context, payload) {
    if (payload.user.userOption === "is_customer") {
      payload.user.is_customer = true;
      payload.user.is_service = false;
    } else {
      payload.user.is_service = true;
      payload.user.is_customer = false;
    }
    return context.dispatch("auth", {
      ...payload,
      mode: "signup",
    });
  },
  async auth(context, payload) {
    const mode = payload.mode;
    let response = null;
    if (mode === "signup") {
      response = await authService.register(payload.user);
    } else {
      response = await authService.login(payload.login);
    }

    if (mode === "login") {
      const auth_token = response.data.auth_token;
      const resp = await userService.getMe(auth_token);

      const userId = resp.data.id;
      const user = resp.data;

      const expiresIn = 3600 * 1000; //one hour
      const expirationDate = new Date().getTime() + expiresIn;

      localStorage.setItem("token", auth_token);
      localStorage.setItem("userId", userId);
      localStorage.setItem("user", JSON.stringify(user));
      localStorage.setItem("tokenExpiration", expirationDate);

      timer = setTimeout(function () {
        context.dispatch("autoLogout");
      }, expiresIn);

      context.commit("setUser", {
        token: auth_token,
        userId: userId,
        user: user,
      });
    }
  },
  tryLogin(context) {
    const token = localStorage.getItem("token");
    const userId = localStorage.getItem("userId");
    const user = JSON.parse(localStorage.getItem("user"));

    const tokenExpiration = localStorage.getItem("tokenExpiration");
    const expiresIn = +tokenExpiration - new Date().getTime();

    if (expiresIn < 0) {
      return;
    }

    timer = setTimeout(function () {
      context.dispatch("autoLogout");
    }, expiresIn);

    if (token && userId) {
      context.commit("setUser", {
        token: token,
        userId: userId,
        user: user,
      });
    }
  },
  logout(context) {
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    localStorage.removeItem("user");
    localStorage.removeItem("tokenExpiration");

    clearTimeout(timer);

    context.commit("setUser", {
      token: null,
      userId: null,
      user: null,
    });
  },
  autoLogout(context) {
    context.dispatch("logout");
    context.commit("setAutoLogout");
  },
  async updateUserInfo(context, payload) {
    const token = context.rootGetters.token;
    const response = await userService.update(token, payload.user);
    // console.log(response.data);
    context.commit("setUser", {
      token: token,
      userId: response.data.id,
      user: response.data,
    });
    localStorage.setItem("user", JSON.stringify(response.data));
  },
  async activation(_, payload) {
    const response = await userService.activateEmail(payload);
    return response.data;
  },
  async resendActivation(_, payload) {
    const response = await userService.resendActivateEmail(payload);
    return response.data;
  },
  async contactUsPost(_, payload) {
    const response = await authService.contactUsPost(payload);
    return response.data;
  },

  async resetPassword(_, payload) {
    const response = await userService.resetPassword(payload);
    return response.data;
  },

  async changePassword(_, payload) {
    const response = await userService.changePassword(payload);
    return response.data;
  },
};
