import { createStore } from "vuex";

import authModule from "./modules/auth";
import partModule from "./modules/parts";
import categoryModule from "./modules/category";
import serviceModule from "./modules/service";
import subscriptionModule from "./modules/subscriptions";
import subscriptionTypeModule from "./modules/subscription_types";
import wishlistModule from "./modules/wishlist";
import advertModule from "./modules/adverts";

const store = createStore({
  modules: {
    auth: authModule,
    parts: partModule,
    categories: categoryModule,
    services: serviceModule,
    subscriptions: subscriptionModule,
    subscription_types: subscriptionTypeModule,
    wishlist: wishlistModule,
    adverts: advertModule,
  },
  state() {},
});

export default store;
