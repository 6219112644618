import subscriptionTypeService from "../../../services/subscription_type.service";

export default {
  async addSubscriptionType(context, data) {
    // const userId = context.rootGetters.userId;

    const token = context.rootGetters.token;
    const response = await subscriptionTypeService.createSubcriptionType(
      token,
      data.form
    );
    console.log(response);

    // context.commit("addPart", {
    //   ...coachData,
    //   id: userId,
    // });
  },

  async loadSubscriptionTypes(context) {
    // if (!payload.forceRefresh && !context.getters.shouldUpdate) {
    //   return;
    // }

    const token = context.rootGetters.token;
    const response = await subscriptionTypeService.getSubcriptionTypes(token);

    context.commit("setSubscriptionTypes", response.data);
    // context.commit("setFetchTimeStamp");
  },

  async getSubscriptionType(context, payload) {
    const token = context.rootGetters.token;
    const response = await subscriptionTypeService.getSubcriptionType(
      token,
      payload.packageId
    );

    context.commit("setSubscriptionType", response.data);
  },
};
