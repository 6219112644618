export default {
  wishlists(state) {
    return state.wishlists;
  },

  wishlist(state) {
    return state.wishlist;
  },

  hasWishlists(state) {
    return state.wishlists && state.wishlists.length > 0;
  },

  hasWishlist(state) {
    return state.wishlist !== null;
  },
};
