<template>
  <div class="main-wrapper">
    <website-header></website-header>
    <slot name="content"></slot>
    <website-footer></website-footer>
  </div>
</template>

<script>
import WebsiteHeader from "./WebsiteHeader.vue";
import WebsiteFooter from "./WebsiteFooter.vue";

export default {
  components: { WebsiteHeader, WebsiteFooter },
};
</script>
