<template>
  <!-- Begin Uren's Brand Area -->
  <div class="uren-brand_area">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-12">
          <div class="section-title_area">
            <span>Partner</span>
            <h3>Brands</h3>
          </div>
          <div
            class="brand-slider uren-slick-slider img-hover-effect_area"
            data-slick-options='{
                        "slidesToShow": 6
                        }'
            data-slick-responsive='[
                                                {"breakpoint":1200, "settings": {"slidesToShow": 5}},
                                                {"breakpoint":992, "settings": {"slidesToShow": 3}},
                                                {"breakpoint":767, "settings": {"slidesToShow": 3}},
                                                {"breakpoint":577, "settings": {"slidesToShow": 2}},
                                                {"breakpoint":321, "settings": {"slidesToShow": 1}}
                                            ]'
          >
            <div class="slide-item">
              <div class="inner-slide">
                <div class="single-product">
                  <a href="javascript:void(0)">
                    <img
                      src="@/assets/images/brand/1.jpg"
                      alt="Uren's Brand Image"
                    />
                  </a>
                </div>
              </div>
            </div>
            <div class="slide-item">
              <div class="inner-slide">
                <div class="single-product">
                  <a href="javascript:void(0)">
                    <img
                      src="@/assets/images/brand/2.jpg"
                      alt="Uren's Brand Image"
                    />
                  </a>
                </div>
              </div>
            </div>
            <div class="slide-item">
              <div class="inner-slide">
                <div class="single-product">
                  <a href="javascript:void(0)">
                    <img
                      src="@/assets/images/brand/3.jpg"
                      alt="Uren's Brand Image"
                    />
                  </a>
                </div>
              </div>
            </div>
            <div class="slide-item">
              <div class="inner-slide">
                <div class="single-product">
                  <a href="javascript:void(0)">
                    <img
                      src="@/assets/images/brand/4.jpg"
                      alt="Uren's Brand Image"
                    />
                  </a>
                </div>
              </div>
            </div>
            <div class="slide-item">
              <div class="inner-slide">
                <div class="single-product">
                  <a href="javascript:void(0)">
                    <img
                      src="@/assets/images/brand/5.jpg"
                      alt="Uren's Brand Image"
                    />
                  </a>
                </div>
              </div>
            </div>
            <div class="slide-item">
              <div class="inner-slide">
                <div class="single-product">
                  <a href="javascript:void(0)">
                    <img
                      src="@/assets/images/brand/6.jpg"
                      alt="Uren's Brand Image"
                    />
                  </a>
                </div>
              </div>
            </div>
            <div class="slide-item">
              <div class="inner-slide">
                <div class="single-product">
                  <a href="javascript:void(0)">
                    <img
                      src="@/assets/images/brand/1.jpg"
                      alt="Uren's Brand Image"
                    />
                  </a>
                </div>
              </div>
            </div>
            <div class="slide-item">
              <div class="inner-slide">
                <div class="single-product">
                  <a href="javascript:void(0)">
                    <img
                      src="@/assets/images/brand/7.jpg"
                      alt="Uren's Brand Image"
                    />
                  </a>
                </div>
              </div>
            </div>
            <div class="slide-item">
              <div class="inner-slide">
                <div class="single-product">
                  <a href="javascript:void(0)">
                    <img
                      src="@/assets/images/brand/2.jpg"
                      alt="Uren's Brand Image"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Uren's Brand Area End Here -->
</template>

<script>
export default {
  props: {
    partners: {
      type: Array,
      required: true,
    },
  },
};
</script>
