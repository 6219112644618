<template>
  <!-- Begin Uren's Header Main Area -->
  <header class="header-main_area bg--sapphire">
    <div class="header-top_area d-lg-block d-none">
      <div class="container-fluid">
        <div class="row">
          <div class="col-xl-7 col-lg-8">
            <div class="main-menu_area position-relative">
              <nav class="main-nav">
                <ul>
                  <li :class="{ active: $route.path === '/homepage' }">
                    <a href="/">Home</a>
                  </li>
                  <li :class="{ active: $route.path === '/shop' }">
                    <a href="/shop">Parts</a>
                  </li>
                  <!-- <li>
                    <a href="/shop">Parts</a>
                  </li> -->
                  <li :class="{ active: $route.path === '/about' }">
                    <a href="/about">About Us</a>
                  </li>
                  <li :class="{ active: $route.path === '/contact' }">
                    <a href="/contact">Contact</a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
          <div class="col-xl-5 col-lg-4">
            <div class="ht-right_area">
              <div class="ht-menu">
                <ul>
                  <li v-if="isLoggedIn">
                    <a href="javascript:void(0)">
                      Hi {{ loggedInUser.first_name }}
                      {{ loggedInUser.last_name }},
                    </a>
                  </li>
                  <li v-if="isLoggedIn">
                    <a href="javascript:void(0)"
                      >My Account<i class="fa fa-chevron-down"></i
                    ></a>
                    <ul class="ht-dropdown ht-my_account">
                      <li><a href="/customer-account">Dashboard</a></li>
                      <li class="active">
                        <a @click="logout">Logout</a>
                      </li>
                    </ul>
                  </li>
                  <li v-else>
                    <a href="/login "
                      >Login | Register<i class="fa fa-user"></i
                    ></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="header-top_area header-sticky bg--sapphire">
      <div class="container-fluid">
        <div class="row">
          <div class="col-xl-8 col-lg-7 d-lg-block d-none">
            <div class="main-menu_area position-relative">
              <nav class="main-nav">
                <ul>
                  <li class="active">
                    <a href="/">Home</a>
                  </li>
                  <li>
                    <a href="/shop">Parts</a>
                  </li>
                  <li class=""><a href="/about">About Us</a></li>
                  <li class=""><a href="/contact">Contact</a></li>
                </ul>
              </nav>
            </div>
          </div>
          <div class="col-sm-3 d-block d-lg-none">
            <div class="header-logo_area header-sticky_logo">
              <a href="/">
                <img src="assets/images/menu/logo/1.png" alt="Uren's Logo" />
              </a>
            </div>
          </div>
          <div class="col-xl-4 col-lg-5 col-sm-9">
            <div class="header-right_area">
              <ul>
                <li class="mobile-menu_wrap d-flex d-lg-none">
                  <a
                    href="#mobileMenu"
                    class="mobile-menu_btn toolbar-btn color--white"
                  >
                    <i class="ion-navicon"></i>
                  </a>
                </li>
                <!-- <li class="minicart-wrap">
                  <h3 style="line-height: 3">Call Us</h3>
                </li> -->
                <li class="contact-us_wrap">
                  <a href="tel://+27788934978"
                    ><i class="ion-android-call"></i>+27 78 893 4978</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="header-middle_area">
      <div class="container-fluid">
        <div class="row">
          <div class="custom-logo_col col-12">
            <div class="header-logo_area">
              <a href="/">
                <img src="assets/images/menu/logo/1.png" alt="Uren's Logo" />
              </a>
            </div>
          </div>
          <div class="custom-category_col col-12">
            <div class="category-menu category-menu-hidden">
              <div class="category-heading">
                <h2 class="categories-toggle">
                  <span>Find By</span>
                  <span>Category</span>
                </h2>
              </div>
              <div id="cate-toggle" class="category-menu-list">
                <ul v-if="hasCategories">
                  <li
                    v-for="cat in categories"
                    :key="cat.id"
                    class="right-menu"
                  >
                    <a :href="'/category-' + cat.slug">{{ cat.name }}</a>
                    <ul class="cat-mega-menu">
                      <li class="right-menu cat-mega-title">
                        <!-- <a href="#">{{ cat.name }}</a> -->
                        <a href="#"><strong>First Group</strong></a>
                        <ul>
                          <li
                            v-for="sub_cat in splitSubs(cat.subcategories, 1)"
                            :key="sub_cat.id"
                          >
                            <a :href="'/sub-category-' + sub_cat.slug">{{
                              sub_cat.name
                            }}</a>
                          </li>
                        </ul>
                      </li>
                      <li
                        v-if="splitSubs(cat.subcategories, 2) !== false"
                        class="right-menu cat-mega-title"
                      >
                        <!-- <a href="#">{{ cat.name }}</a> -->
                        <a href="#"><strong>Second Group</strong></a>
                        <ul>
                          <li
                            v-for="sub_cat in splitSubs(cat.subcategories, 2)"
                            :key="sub_cat.id"
                          >
                            <a :href="'/sub-category-' + sub_cat.slug">{{
                              sub_cat.name
                            }}</a>
                          </li>
                        </ul>
                      </li>
                      <li
                        v-if="splitSubs(cat.subcategories, 3) !== false"
                        class="right-menu cat-mega-title"
                      >
                        <!-- <a href="#">{{ cat.name }}</a> -->
                        <a href="#"><strong>Third Group</strong></a>
                        <ul>
                          <li
                            v-for="sub_cat in splitSubs(cat.subcategories, 3)"
                            :key="sub_cat.id"
                          >
                            <a :href="'/sub-category-' + sub_cat.slug">{{
                              sub_cat.name
                            }}</a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="custom-vmake_col col-12">
            <div class="vmake-menu vmake-menu-hidden">
              <div class="vmake-heading">
                <h2 class="categories-toggle">
                  <span>Find By</span>
                  <span>Vehicle Make</span>
                </h2>
              </div>
              <div id="vmake-toggle" class="vmake-menu-list">
                <ul v-if="hasVehicleMakes">
                  <li
                    v-for="vmake in vehicleMakesInitial"
                    :key="vmake.id"
                    class="right-menu"
                  >
                    <a :href="'/vehicle-make-' + vmake.slug">{{
                      vmake.name
                    }}</a>
                    <ul class="cat-mega-menu">
                      <li class="right-menu cat-mega-title">
                        <!-- <a href="#">{{ vmake.name }}</a> -->
                        <a href="#"><strong>First Group</strong></a>
                        <ul>
                          <li
                            v-for="vmodel in splitSubs(vmake.vehicleModels, 1)"
                            :key="vmodel.id"
                          >
                            <a :href="'/vehicle-model-' + vmodel.slug">{{
                              vmodel.name
                            }}</a>
                          </li>
                        </ul>
                      </li>
                      <li
                        v-if="splitSubs(vmake.vehicleModels, 2) !== false"
                        class="right-menu cat-mega-title"
                      >
                        <!-- <a href="#">{{ vmake.name }}</a> -->
                        <a href="#"><strong>Second Group</strong></a>
                        <ul>
                          <li
                            v-for="vmodel in splitSubs(vmake.vehicleModels, 2)"
                            :key="vmodel.id"
                          >
                            <a :href="'/vehicle-model-' + vmodel.slug">{{
                              vmodel.name
                            }}</a>
                          </li>
                        </ul>
                      </li>
                      <li
                        v-if="splitSubs(vmake.vehicleModels, 3) !== false"
                        class="right-menu cat-mega-title"
                      >
                        <!-- <a href="#">{{ vmake.name }}</a> -->
                        <a href="#"><strong>Third Group</strong></a>
                        <ul>
                          <li
                            v-for="vmodel in splitSubs(vmake.vehicleModels, 3)"
                            :key="vmodel.id"
                          >
                            <a :href="'/vehicle-model-' + vmodel.slug">{{
                              vmodel.name
                            }}</a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                  <!-- <li><a href="javascript:void(0)"></a></li> -->
                  <li>
                    <a
                      href="javascript:void(0)"
                      class="btn btn-outline btn-block"
                      @click="changeIndex()"
                      >VIEW NEXT</a
                    >
                  </li>
                  <li>
                    <a
                      v-if="index > 0"
                      href="javascript:void(0)"
                      class="btn btn-outline btn-block"
                      @click="changeIndexNEG()"
                      >VIEW PREVIOUS</a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="custom-search_col col-12">
            <div class="hm-form_area">
              <form @submit.prevent="search" class="hm-searchbox">
                <!-- <select class="nice-select select-search-category">
                  <option value="0">All Categories</option>
                  <option value="10">Laptops</option>
                  <option value="17">Prime Video</option>
                </select> -->
                <input
                  type="text"
                  v-model="q"
                  placeholder="Enter your search key ..."
                />
                <button class="header-search_btn" type="submit">
                  <i class="ion-ios-search-strong"><span>Search</span></i>
                </button>
              </form>
            </div>
          </div>
          <div class="custom-cart_col col-12">
            <div class="header-right_area">
              <ul>
                <li
                  style="width: 20%"
                  class="mobile-menu_wrap d-flex d-lg-none"
                >
                  <a
                    href="#mobileMenu"
                    class="mobile-menu_btn toolbar-btn color--white"
                  >
                    <i class="ion-navicon"></i>
                  </a>
                </li>
                <li
                  style="width: 70%; margin-right: 0"
                  v-if="isLoggedIn"
                  class="mobile-menu_wrap d-flex d-lg-none"
                >
                  <a href="#" class="mobile-menu_btn toolbar-btn color--white">
                    <!-- <i class="ion-person"></i> -->
                    <small style="font-size: 18px"
                      >Hi,
                      {{
                        loggedInUser.first_name + " " + loggedInUser.last_name
                      }}</small
                    >
                  </a>
                </li>
                <li style="width: 10%"></li>
                <!-- <li class="minicart-wrap">
                  <h3 style="line-height: 3">Call Us</h3>
                </li> -->
                <!-- <li class="contact-us_wrap">
                  <a href="tel://+27788934978"
                    ><i class="ion-android-call"></i>+27 78 893 4978</a
                  >
                </li> -->
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="offcanvas-minicart_wrapper" id="miniCart">
      <div class="offcanvas-menu-inner">
        <a href="#" class="btn-close"><i class="ion-android-close"></i></a>
        <div class="minicart-content">
          <div class="minicart-heading">
            <h4>Shopping Cart</h4>
          </div>
          <ul class="minicart-list">
            <li class="minicart-product">
              <a class="product-item_remove" href="javascript:void(0)"
                ><i class="ion-android-close"></i
              ></a>
              <div class="product-item_img">
                <img
                  src="assets/images/product/small-size/1.jpg"
                  alt="Uren's Product Image"
                />
              </div>
              <div class="product-item_content">
                <a class="product-item_title" href="shop-left-sidebar.html"
                  >Body Part</a
                >
                <span class="product-item_quantity">1 x R145.80</span>
              </div>
            </li>
            <li class="minicart-product">
              <a class="product-item_remove" href="javascript:void(0)"
                ><i class="ion-android-close"></i
              ></a>
              <div class="product-item_img">
                <img
                  src="assets/images/product/small-size/2.jpg"
                  alt="Uren's Product Image"
                />
              </div>
              <div class="product-item_content">
                <a class="product-item_title" href="/">Engine Part</a>
                <span class="product-item_quantity">1 x R150.80</span>
              </div>
            </li>
            <li class="minicart-product">
              <a class="product-item_remove" href="javascript:void(0)"
                ><i class="ion-android-close"></i
              ></a>
              <div class="product-item_img">
                <img
                  src="assets/images/product/small-size/3.jpg"
                  alt="Uren's Product Image"
                />
              </div>
              <div class="product-item_content">
                <a class="product-item_title" href="/">Roof Part</a>
                <span class="product-item_quantity">1 x R165.80</span>
              </div>
            </li>
          </ul>
        </div>
        <div class="minicart-item_total">
          <span>Subtotal</span>
          <span class="ammount">R462.4‬0</span>
        </div>
        <div class="minicart-btn_area">
          <a href="/" class="uren-btn uren-btn_dark uren-btn_fullwidth"
            >Minicart</a
          >
        </div>
        <div class="minicart-btn_area">
          <a href="/" class="uren-btn uren-btn_dark uren-btn_fullwidth"
            >Checkout</a
          >
        </div>
      </div>
    </div>
    <div class="mobile-menu_wrapper" id="mobileMenu">
      <div class="offcanvas-menu-inner">
        <div class="container">
          <a href="#" class="btn-close"><i class="ion-android-close"></i></a>
          <div class="offcanvas-inner_search">
            <form action="#" class="inner-searchbox">
              <input type="text" placeholder="Search for item..." />
              <button class="search_btn" type="submit">
                <i class="ion-ios-search-strong"></i>
              </button>
            </form>
          </div>
          <nav class="offcanvas-navigation">
            <ul class="mobile-menu">
              <li class="active">
                <a href="/"><span class="mm-text">Home</span></a>
              </li>
              <!-- <li class="menu-item-has-children">
                <a href="/">
                  <span class="mm-text">Pages</span>
                </a>
                <ul class="sub-menu">
                  <li>
                    <a href="/customer-account">
                      <span class="mm-text">My Account</span>
                    </a>
                  </li>
                  <li>
                    <a href="/login">
                      <span class="mm-text">Login | Register</span>
                    </a>
                  </li>
                  <li>
                    <a href="/wishlist">
                      <span class="mm-text">Wishlist</span>
                    </a>
                  </li>
                  <li>
                    <a href="/faq">
                      <span class="mm-text">FAQ</span>
                    </a>
                  </li>
                </ul>
              </li> -->
              <li class="">
                <a href="/shop"><span class="mm-text">Parts</span></a>
              </li>
              <li class="">
                <a href="/about"><span class="mm-text">About</span></a>
              </li>
              <li class="">
                <a href="/contact"><span class="mm-text">Contact</span></a>
              </li>
            </ul>
          </nav>
          <nav class="offcanvas-navigation user-setting_area">
            <ul class="mobile-menu">
              <li class="menu-item-has-children active">
                <a href="javascript:void(0)"
                  ><span class="mm-text">User Setting</span></a
                >
                <ul class="sub-menu">
                  <li v-if="isLoggedIn">
                    <a href="/customer-account">
                      <span class="mm-text">My Account</span>
                    </a>
                  </li>
                  <li v-else>
                    <a href="/login">
                      <span class="mm-text">Login | Register</span>
                    </a>
                  </li>
                  <li v-if="isLoggedIn">
                    <a @click="logout">
                      <span class="mm-text">Logout</span>
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </header>
  <!-- Uren's Header Main Area End Here -->
</template>

<script>
import _ from "lodash";

export default {
  data() {
    return {
      q: "",
      index: 0,
    };
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters.isAuthenticated;
    },
    loggedInUser() {
      return this.$store.getters.user;
    },
    hasCategories() {
      return this.$store.getters["categories/hasCategories"];
    },
    hasVehicleMakes() {
      return this.$store.getters["categories/hasVehicleMakes"];
    },
    categories() {
      return this.$store.getters["categories/categories"];
    },
    vehicleMakes() {
      return this.$store.getters["categories/vehicleMakes"];
    },
    vehicleMakesInitial() {
      return this.splitModelsArray(this.index);
    },
  },
  methods: {
    search() {
      console.log(this.q);
      console.log(this.$route.path);
      if (this.q === "") {
        return;
      }
      // this.$router.push({ path: "/shop-search-" + this.q });
      // this.$route.go('/');
      const link = document.createElement("a");
      link.href = "/shop-search-" + this.q;
      link.click();
    },
    splitModelsArray(index) {
      const arraysplit = _.chunk(this.vehicleMakes, 19);
      if (typeof arraysplit[index] === "undefined") {
        index = 0;
      }
      // console.log(arraysplit);
      return arraysplit[index];
    },
    changeIndex() {
      this.index = this.index + 1;
    },
    changeIndexNEG() {
      this.index = this.index - 1;
    },
    async loadCategories() {
      try {
        await this.$store.dispatch("categories/loadCategories");
      } catch (error) {
        console.log(error);
      }
    },
    async loadVehicleMakes() {
      try {
        await this.$store.dispatch("categories/loadVehicleMakes");
      } catch (error) {
        console.log(error);
      }
    },
    splitSubs(array, whichNumber) {
      if (array.length <= 5) {
        if (whichNumber === 1) {
          return array;
        }

        return false;
      } else if (array.length > 5 && array.length <= 12) {
        let middleIndex = Math.ceil(array.length / 2);
        const oddeven = array.length % 2 == 0;
        const firstHalf = array.slice().splice(0, middleIndex);
        if (!oddeven) {
          middleIndex = middleIndex - 1;
        }
        const secondHalf = array.slice().splice(-middleIndex);
        if (whichNumber === 1) {
          return firstHalf;
        } else if (whichNumber === 2) {
          return secondHalf;
        } else {
          return false;
        }
      } else {
        const list = [...array];
        const threePartIndex = Math.ceil(list.length / 5);
        const thirdPart = list.splice(-threePartIndex);
        const secondPart = list.splice(-threePartIndex);
        const firstPart = list;

        if (whichNumber === 1) {
          return firstPart;
        } else if (whichNumber === 2) {
          return secondPart;
        } else if (whichNumber === 3) {
          return thirdPart;
        } else {
          return false;
        }
      }
    },
    logout() {
      this.$store.dispatch("logout");
      this.$router.replace("/");
    },
  },
  mounted() {
    this.loadVehicleMakes().then(() => {
      console.log("SUCCESS: VMAKS");
    });

    this.loadCategories().then(() => {
      console.log("SUCCESS: CATS");
    });
  },
};
</script>

<style scoped>
.bg--sapphire {
  /* background-color: #0c2a5c; */
  background-color: #222222;
}
</style>
