import { createRouter, createWebHistory } from "vue-router";

import DashboardLayout from "@/layout/DashboardLayout";
// import AuthLayout from "@/layout/AuthLayout";

import Dashboard from "../views/Dashboard.vue";
import Icons from "../views/Icons.vue";
import Maps from "../views/Maps.vue";
import Profile from "../views/UserProfile.vue";
// import Tables from "../views/Tables.vue";
import Parts from "../views/Dashboard/Parts.vue";
import Advert from "../views/Dashboard/Advert.vue";
import Subscribe from "../views/Dashboard/Subscribe.vue";
import SubscribeSuccess from "../views/Dashboard/SubscribeSuccess.vue";
import SubscribeCancel from "../views/Dashboard/SubscribeCancel.vue";
import AddPart from "../views/Dashboard/AddPart.vue";
import EditPart from "../views/Dashboard/EditPart.vue";
import UploadBulkPart from "../views/Dashboard/UploadBulkPart.vue";
import AddAdvert from "../views/Dashboard/AddAdvert.vue";
import Part from "../views/Dashboard/Parts.vue";
import NotFound from "../views/NotFound.vue";

// import Login from "../views/Login.vue";
// import Register from "../views/Register.vue";
import About from "../views/Website/About.vue";
import Index from "../views/Website/Index.vue";
import Contact from "../views/Website/Contact.vue";
import Activation from "../views/Website/Activation.vue";
import Shop from "../views/Website/Shop.vue";
import SearchResult from "../views/Website/SearchResult.vue";
import CategoryProds from "../views/Website/CategoryProds.vue";
import Account from "../views/Website/Account.vue";
import LoginRegister from "../views/Website/LoginRegister.vue";
import ForgotPassword from "../views/Website/ForgotPassword.vue";
import ResetPassword from "../views/Website/ResetPassword.vue";
import TermsConditions from "../views/Website/TermsConditions.vue";
import store from "../store";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: "/", redirect: "/homepage" },
    { path: "/homepage", component: Index },
    {
      path: "/admin",
      redirect: "/admin-dashboard",
      component: DashboardLayout,
      meta: { auth: true, isService: true },
      children: [
        {
          path: "/admin-dashboard",
          name: "admin-dashboard",
          components: { default: Dashboard },
          meta: { auth: true, isService: true },
        },
        {
          path: "/admin-icons",
          name: "admin-icons",
          components: { default: Icons },
          meta: { auth: true, isService: true, sub: true },
        },
        {
          path: "/admin-maps",
          name: "admin-maps",
          components: { default: Maps },
          meta: { auth: true, isService: true, sub: true },
        },
        {
          path: "/admin-profile",
          name: "admin-profile",
          components: { default: Profile },
          meta: { auth: true, isService: true },
        },
        {
          path: "/admin-tables",
          name: "admin-tables",
          components: { default: Parts },
          meta: { auth: true, isService: true, sub: true },
        },
        {
          path: "/admin-parts",
          name: "admin-parts",
          components: { default: Part },
          meta: { auth: true, isService: true, sub: true },
        },
        {
          path: "/admin-add-part",
          name: "admin-add-part",
          components: { default: AddPart },
          meta: { auth: true, isService: true, sub: true },
        },
        {
          path: "/admin-adverts",
          name: "admin-adverts",
          components: { default: Advert },
          meta: { auth: true, isService: true, sub: true },
        },
        {
          path: "/admin-add-advert",
          name: "admin-add-advert",
          components: { default: AddAdvert },
          meta: { auth: true, isService: true, sub: true },
        },
        {
          path: "/admin-upload-parts",
          name: "admin-upload-parts",
          components: { default: UploadBulkPart },
          meta: { auth: true, isService: true, sub: true },
        },
        {
          path: "/admin-edit-part-:id",
          name: "admin-edit-part",
          components: { default: EditPart },
          meta: { auth: true, isService: true, sub: true },
        },
        {
          path: "/admin-subscribe-:packageId",
          name: "admin-subscribe",
          components: { default: Subscribe },
          props: true,
          meta: { auth: true, isService: true },
        },
        {
          path: "/admin-subscription-success-:packageId",
          name: "admin-subscription-success",
          components: { default: SubscribeSuccess },
          props: true,
          meta: { auth: true, isService: true },
        },
        {
          path: "/admin-subscription-cancel-:packageId",
          name: "admin-subscription-cancel",
          components: { default: SubscribeCancel },
          props: true,
          meta: { auth: true, isService: true },
        },
      ],
    },
    { path: "/login", component: LoginRegister, meta: { noAuth: true } },
    { path: "/about", component: About },
    { path: "/contact", component: Contact },
    { path: "/shop", component: Shop },
    { path: "/forgot-password", component: ForgotPassword },
    { path: "/terms-and-conditions", component: TermsConditions },
    { path: "/shop-search-:q", component: SearchResult, props: true },
    { path: "/category-:cat", component: CategoryProds, props: true },
    { path: "/sub-category-:sub", component: CategoryProds, props: true },
    { path: "/vehicle-make-:make", component: CategoryProds, props: true },
    { path: "/vehicle-model-:model", component: CategoryProds, props: true },
    { path: "/activation-:uid-:token", component: Activation, props: true },
    {
      path: "/password-reset-:uid-:token",
      component: ResetPassword,
      props: true,
    },
    {
      path: "/customer-account",
      component: Account,
      meta: { auth: true, isCustomer: true },
    },
    // { path: '/auth', component: UserAuth, meta: { noAuth: true } },
    { path: "/:notFound(.*)", component: NotFound },
  ],
});

router.beforeEach(function (to, _, next) {
  if (to.meta.auth && !store.getters.isAuthenticated) {
    next("/login");
  } else if (to.meta.noAuth && store.getters.isAuthenticated) {
    next("/homepage");
  } else if (
    to.meta.isCustomer &&
    store.getters.isAuthenticated &&
    !store.getters.user.is_customer
  ) {
    next("/admin-dashboard");
  } else if (
    to.meta.isService &&
    store.getters.isAuthenticated &&
    !store.getters.user.is_service &&
    !store.getters.user.is_superuser
  ) {
    next("/customer-account");
  } else if (
    to.meta.isService &&
    to.meta.sub &&
    store.getters.isAuthenticated &&
    store.getters.user.is_service &&
    !store.getters.user.subscribed_service
  ) {
    next("/admin-profile");
  } else {
    next();
  }
});

export default router;
