export default {
  services(state) {
    return state.services;
  },

  serviceAddresses(state) {
    return state.serviceAddresses;
  },

  customers(state) {
    return state.customers;
  },

  service(state) {
    return state.service;
  },

  customer(state) {
    return state.customer;
  },

  hasService(state) {
    return state.service !== null;
  },

  hasCustomer(state) {
    return state.customer !== null;
  },

  hasServices(state) {
    return state.services && state.services.length > 0;
  },

  hasServiceAddresses(state) {
    return state.serviceAddresses && state.serviceAddresses.length > 0;
  },

  hasCustomers(state) {
    return state.customers && state.customers.length > 0;
  },
};
