import axios from "axios";

const API_URL = process.env.VUE_APP_API_URL + "wishlists/";

class WishlistService {
  getConfig(token) {
    return {
      headers: { Authorization: `Token ${token}` },
    };
  }

  async createWishlist(token, data) {
    return await axios.post(API_URL, data, this.getConfig(token));
  }

  async loadWishlists(token) {
    return await axios.get(API_URL, this.getConfig(token));
  }

  async getWishlist(token, slug) {
    return await axios.get(API_URL + slug, this.getConfig(token));
  }

  async patchWishlist(token, payload) {
    return await axios.patch(
      API_URL + payload.slug,
      payload.wishlist,
      this.getConfig(token)
    );
  }
}

export default new WishlistService();
3;
