export default {
  addCategory(state, payload) {
    state.categories.push(payload);
  },
  setCategories(state, payload) {
    state.categories = payload;
  },
  addSubCategory(state, payload) {
    state.sub_categories.push(payload);
  },
  setSubCategories(state, payload) {
    state.sub_categories = payload;
  },
  addVehicleMake(state, payload) {
    state.vehicleMakes.push(payload);
  },
  setVehicleMakes(state, payload) {
    state.vehicleMakes = payload;
  },
  addVehicleModel(state, payload) {
    state.vehicleModels.push(payload);
  },
  setVehicleModels(state, payload) {
    state.vehicleModels = payload;
  },
  addPartType(state, payload) {
    state.partTypes.push(payload);
  },
  setPartTypes(state, payload) {
    state.partTypes = payload;
  },
};
