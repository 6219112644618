export default {
  subscriptions(state) {
    return state.subscriptions;
  },

  subscription(state) {
    return state.subscription;
  },

  hasSubscriptions(state) {
    return state.subscriptions && state.subscriptions.length > 0;
  },

  hasSubscription(state) {
    return state.subscription !== null;
  },
};
