import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";

export default {
  namespaced: true,
  state() {
    return {
      // parts: {
      //   count: 0,
      //   next: null,
      //   previous: null,
      //   results: [],
      //   total_pages: 0
      // },
      parts: {},
      part: null,
    };
  },
  mutations,
  actions,
  getters,
};
