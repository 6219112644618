<template>
  <base-wrapper>
    <template v-slot:content>
      <template v-if="hasParts"> </template>
      <!-- Begin Uren's Breadcrumb Area -->
      <div class="breadcrumb-area">
        <div class="container">
          <div class="breadcrumb-content">
            <ul>
              <li><a href="/">Home</a></li>
              <li class="active">Search Result</li>
              <li class="active">Search Term: "{{ this.$route.params.q }}"</li>
            </ul>
          </div>
        </div>
      </div>
      <!-- Uren's Breadcrumb Area End Here -->

      <!-- Begin Uren's Shop List Left sidebar Area -->
      <div class="shop-content_wrapper">
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-3 col-md-5 order-2 order-lg-1 order-md-1">
              <div class="uren-sidebar-catagories_area">
                <!-- <div class="category-module uren-sidebar_categories">
                <div class="category-module_heading">
                  <h5>Categories</h5>
                </div>
                <div class="module-body">
                  <ul class="module-list_item">
                    <li>
                      <a href="javascript:void(0)"
                        >Games & Consoles <span>(12)</span></a
                      >
                      <a href="javascript:void(0)"
                        >Appliances <span>(0)</span></a
                      >
                      <a href="javascript:void(0)">Audio <span>(0)</span></a>
                      <a href="javascript:void(0)">Bakeware <span>(0)</span></a>
                      <a href="javascript:void(0)"
                        >Body Parts <span>(12)</span></a
                      >
                      <a href="javascript:void(0)"
                        >Cameras & Camcoders <span>(12)</span></a
                      >
                      <a href="javascript:void(0)"
                        >Car Parts <span>(16)</span></a
                      >
                      <a href="javascript:void(0)"
                        >Cookies & Crackers <span>(2)</span></a
                      >
                      <a href="javascript:void(0)">Interior <span>(0)</span></a>
                      <a class="active" href="javascript:void(0)"
                        >Shop <span>(18)</span></a
                      >
                      <ul class="module-sub-list_item">
                        <li>
                          <a href="javascript:void(0)"
                            >Brakes & Rotors <span>(8)</span></a
                          >
                          <a href="javascript:void(0)"
                            >Lighting <span>(8)</span></a
                          >
                          <a href="javascript:void(0)"
                            >Perfomance <span>(13)</span></a
                          >
                          <a href="javascript:void(0)"
                            >Wheels & Tires <span>(13)</span></a
                          >
                        </li>
                      </ul>
                    </li>
                    <li>
                      <a href="javascript:void(0)"
                        >Smartwatch <span>(9)</span></a
                      >
                      <a href="javascript:void(0)"
                        >Suspension Systems <span>(15)</span></a
                      >
                      <a href="javascript:void(0)"
                        >Tools & Accessories <span>(0)</span></a
                      >
                      <a href="javascript:void(0)"
                        >Turbo System <span>(18)</span></a
                      >
                      <a href="javascript:void(0)"
                        >TV & Audio <span>(0)</span></a
                      >
                      <a href="javascript:void(0)">Exterior <span>(0)</span></a>
                      <a href="javascript:void(0)"
                        >Oils & Fluids <span>(18)</span></a
                      >
                      <a href="javascript:void(0)"
                        >Accessories <span>(12)</span></a
                      >
                      <a href="javascript:void(0)"
                        >Breakfast <span>(0)</span></a
                      >
                    </li>
                  </ul>
                </div>
              </div> -->
                <!-- <div class="uren-sidebar_categories">
                <div class="uren-categories_title">
                  <h5>Price</h5>
                </div>
                <div class="price-filter">
                  <div id="slider-range"></div>
                  <div class="price-slider-amount">
                    <div class="label-input">
                      <label>price : </label>
                      <input
                        type="text"
                        id="amount"
                        name="price"
                        placeholder="Add Your Price"
                      />
                    </div>
                  </div>
                </div>
              </div> -->
                <!-- <div class="uren-sidebar_categories">
                <div class="uren-categories_title">
                  <h5>Color</h5>
                </div>
                <ul class="sidebar-checkbox_list">
                  <li>
                    <a href="javascript:void(0)">Black <span>(6)</span></a>
                  </li>
                  <li>
                    <a href="javascript:void(0)">Blue <span>(2)</span></a>
                  </li>
                  <li>
                    <a href="javascript:void(0)">Red <span>(3)</span></a>
                  </li>
                  <li>
                    <a href="javascript:void(0)">Yellow <span>(0)</span></a>
                  </li>
                </ul>
              </div> -->
                <!-- <div class="uren-sidebar_categories">
                  <div class="uren-categories_title">
                    <h5>Parts Category</h5>
                  </div>
                  <ul class="sidebar-checkbox_list">
                    <li>
                      <a href="javascript:void(0)">Engine Parts <span>(10)</span></a>
                    </li>
                    <li>
                      <a href="javascript:void(0)">Gearbox Parts <span>(2)</span></a>
                    </li>
                    <li>
                      <a href="javascript:void(0)">Body parts <span>(1)</span></a>
                    </li>
                    <li>
                      <a href="javascript:void(0)">More Parts <span>(3)</span></a>
                    </li>
                    <li>
                      <a href="javascript:void(0)">More Parts <span>(0)</span></a>
                    </li>
                  </ul>
                </div> -->

                <!-- <div class="uren-sidebar_categories">
                  <div class="uren-categories_title">
                    <h5>Service Provider</h5>
                  </div>
                  <ul class="sidebar-checkbox_list">
                    <li>
                      <a href="javascript:void(0)">Midas <span>(10)</span></a>
                    </li>
                    <li>
                      <a href="javascript:void(0)">Jack Service <span>(2)</span></a>
                    </li>
                    <li>
                      <a href="javascript:void(0)">New Company <span>(1)</span></a>
                    </li>
                    <li>
                      <a href="javascript:void(0)">New Company <span>(3)</span></a>
                    </li>
                    <li>
                      <a href="javascript:void(0)">More Company <span>(0)</span></a>
                    </li>
                  </ul>
                </div> -->
              </div>
              <div class="sidebar-banner_area">
                <div class="banner-item img-hover_effect">
                  <a href="javascript:void(0)">
                    <img
                      src="@/assets/images/shop/1.jpg"
                      alt="Uren's Shop Banner Image"
                    />
                  </a>
                </div>
              </div>
              <div class="sidebar-banner_area">
                <div class="banner-item img-hover_effect">
                  <a href="javascript:void(0)">
                    <img
                      src="@/assets/images/shop/1.jpg"
                      alt="Uren's Shop Banner Image"
                    />
                  </a>
                </div>
              </div>
            </div>
            <div class="col-lg-9 col-md-7 order-1 order-lg-2 order-md-2">
              <div class="shop-toolbar">
                <div class="product-view-mode">
                  <!-- <a
                    class="grid-1"
                    data-target="gridview-1"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="1"
                    >1</a
                  >
                  <a
                    class="grid-2"
                    data-target="gridview-2"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="2"
                    >2</a
                  >
                  <a
                    class="grid-3"
                    data-target="gridview-3"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="3"
                    >3</a
                  >
                  <a
                    class="grid-4"
                    data-target="gridview-4"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="4"
                    >4</a
                  > -->
                  <a
                    class="grid-5"
                    data-target="gridview-5"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="5"
                    >Grid</a
                  >
                  <a
                    class="active list"
                    data-target="listview"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="List"
                    ><i class="fa fa-th-list"></i
                  ></a>
                </div>
                <div class="product-item-selection_area" v-if="hasParts">
                  <h4>Total Results: {{ parts.count }}</h4>
                </div>
              </div>
              <div
                v-if="hasParts"
                class="shop-product-wrap grid listview img-hover-effect_area row"
              >
                <div
                  v-for="part in parts.results"
                  :key="part.id"
                  class="col-lg-4"
                >
                  <div class="product-slide_item">
                    <div class="inner-slide">
                      <div class="single-product">
                        <div class="product-img">
                          <a href="#">
                            <img
                              v-for="(img, index) in part.images"
                              :key="img.id"
                              :class="
                                index === 0 ? 'primary-img' : 'secondary-img'
                              "
                              :src="img.get_image"
                              :alt="part.name"
                            />
                          </a>
                          <div class="sticker-area-2">
                            <span v-if="part.discount !== '0'" class="sticker-2"
                              >{{ part.discount }}%</span
                            >
                            <span
                              v-if="hasValue('new', part.tags)"
                              class="sticker"
                              >New</span
                            >
                          </div>
                          <div class="add-actions">
                            <ul>
                              <li>
                                <a
                                  class="uren-wishlist"
                                  :href="!isLoggedIn ? '/login' : '#'"
                                  @click="addToWishlist()"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Add To Wishlist"
                                  ><i class="ion-android-favorite-outline"></i
                                ></a>
                              </li>
                              <li
                                class="quick-view-btn"
                                data-toggle="modal"
                                :data-target="`#customModalCenter-${part.id}`"
                              >
                                <a
                                  href="javascript:void(0)"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Quick View"
                                  ><i class="ion-android-open"></i
                                ></a>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div class="product-content">
                          <div class="product-desc_info">
                            <!-- <div class="rating-box">
                              <ul>
                                <li><i class="ion-android-star"></i></li>
                                <li><i class="ion-android-star"></i></li>
                                <li class="silver-color">
                                  <i class="ion-android-star"></i>
                                </li>
                                <li class="silver-color">
                                  <i class="ion-android-star"></i>
                                </li>
                                <li class="silver-color">
                                  <i class="ion-android-star"></i>
                                </li>
                              </ul>
                            </div> -->
                            <h6>
                              <a class="product-name" href="#">{{
                                part.name
                              }}</a>
                            </h6>
                            <div class="price-box">
                              <span class="new-price new-price-2">{{
                                part.price == -1
                                  ? "Contact for price"
                                  : `R${part.price}`
                              }}</span>
                              <!-- <span
                                v-if="part.discount !== '0'"
                                class="old-price"
                                >R{{ part.oldprice }}</span
                              > -->
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="list-slide_item">
                    <div class="single-product">
                      <div class="product-img">
                        <a href="#">
                          <img
                            v-for="(img, index) in part.images"
                            :key="img.id"
                            :class="
                              index === 0 ? 'primary-img' : 'secondary-img'
                            "
                            :src="img.get_image"
                            :alt="part.name"
                          />
                        </a>
                        <div class="sticker-area-2">
                          <span v-if="part.discount !== '0'" class="sticker-2"
                            >{{ part.discount }}%</span
                          >
                          <span
                            v-if="hasValue('new', part.tags)"
                            class="sticker"
                            >New</span
                          >
                        </div>
                      </div>
                      <div class="product-content">
                        <div class="product-desc_info">
                          <!-- <div class="rating-box">
                            <ul>
                              <li><i class="ion-android-star"></i></li>
                              <li><i class="ion-android-star"></i></li>
                              <li class="silver-color">
                                <i class="ion-android-star"></i>
                              </li>
                              <li class="silver-color">
                                <i class="ion-android-star"></i>
                              </li>
                              <li class="silver-color">
                                <i class="ion-android-star"></i>
                              </li>
                            </ul>
                          </div> -->
                          <div class="row">
                            <div class="col-xl-8 col-md-12">
                              <h2>
                                <a class="product-name" href="#">{{
                                  part.name
                                }}</a>
                              </h2>
                              <div class="price-box">
                                <span class="new-price new-price-2">{{
                                  part.price == -1
                                    ? "Contact for price"
                                    : `R${part.price}`
                                }}</span>
                                <!-- <span
                                  v-if="part.discount !== '0'"
                                  class="old-price"
                                  >R{{ part.oldprice }}</span
                                > -->
                              </div>
                              <div class="product-short_desc">
                                <p style="margin: 0; padding: 0 !important">
                                  Part Type:
                                  <strong
                                    style="
                                      text-transform: uppercase;
                                      font-weight: 600;
                                    "
                                    >{{ part.type }}</strong
                                  >
                                </p>
                                <p style="margin: 0; padding: 0 !important">
                                  Part Category:
                                  <strong style="font-weight: 600">{{
                                    findCat(part.category)
                                  }}</strong>
                                </p>
                                <p style="margin: 0; padding: 0 !important">
                                  Availability:
                                  <strong style="font-weight: 600"
                                    >{{
                                      part.quantity > 0 ? "In" : "Out of"
                                    }}
                                    Stock</strong
                                  >
                                </p>
                                <p style="margin: 0; padding: 0 !important">
                                  Vehicle Make:
                                  <strong style="font-weight: 600">{{
                                    findMake(part.vehicleMake)
                                  }}</strong>
                                </p>
                                <p style="margin: 0; padding: 0 !important">
                                  Vehicle Model:
                                  <strong style="font-weight: 600">{{
                                    findModel(
                                      part.vehicleMake,
                                      part.vehicleModel
                                    )
                                  }}</strong>
                                </p>
                                <br />
                                <p style="margin: 0; padding: 0 !important">
                                  {{ part.description.substring(0, 90) }}...
                                </p>
                              </div>
                            </div>
                            <div class="col-xl-4 col-md-12">
                              <div class="add-actions" style="margin-top: 35px">
                                <ul>
                                  <li style="margin-bottom: 10px">
                                    <a
                                      class="uren-wishlist"
                                      :href="!isLoggedIn ? '/login' : '#'"
                                      @click="addToWishlist()"
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="Add To Wishlist"
                                      ><i
                                        class="ion-android-favorite-outline"
                                        style="font-size: 50px"
                                      ></i
                                    ></a>
                                  </li>
                                  <li
                                    class="quick-view-btn"
                                    data-toggle="modal"
                                    :data-target="`#customModalCenter-${part.id}`"
                                  >
                                    <a
                                      href="javascript:void(0)"
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="Quick View"
                                      ><i
                                        class="ion-android-open"
                                        style="font-size: 53px"
                                      ></i
                                    ></a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <div class="uren-paginatoin-area">
                    <div class="row">
                      <div class="col-lg-12">
                        <BaseWPagination
                          v-if="hasParts"
                          :currentPage="currentPage"
                          :pageCount="pageCount"
                          :visiblePagesCount="visiblePages"
                          @nextPage="pageChangeHandle('next')"
                          @previousPage="pageChangeHandle('previous')"
                          @loadPage="pageChangeHandle"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <template v-if="!isLoading">
        <product-info-modal
          v-for="part in parts.results"
          :key="part.id"
          :part="part"
        ></product-info-modal>
      </template>
      <!-- Uren's Shop List Left sidebar Area End Here -->
      <page-loader v-if="isLoading"></page-loader>
      <load-script v-if="!isLoading && hasParts"></load-script>
    </template>
  </base-wrapper>
</template>

<script>
import BaseWrapper from "../../components/website/BaseWrapper.vue";
import BaseWPagination from "../../components/website/BaseWPagination.vue";
import PageLoader from "../../components/website/PageLoader.vue";
import ProductInfoModal from "../../components/website/ProductInfoModal.vue";
import LoadScript from "../../components/website/LoadScript.vue";

export default {
  components: {
    BaseWrapper,
    BaseWPagination,
    PageLoader,
    ProductInfoModal,
    LoadScript,
  },
  data() {
    return {
      isLoading: false,
      currentPage: 1,
      params: {
        search: "",
        page_size: 8,
        p: 1,
      },
    };
  },
  computed: {
    parts() {
      return this.$store.getters["parts/parts"];
    },
    pageCount() {
      return this.hasParts ? this.parts.total_pages : 1;
    },
    visiblePages() {
      return this.pageCount > 5 ? 5 : this.pageCount;
    },
    hasParts() {
      return !this.isLoading && this.$store.getters["parts/hasParts"];
    },
    isLoggedIn() {
      return this.$store.getters.isAuthenticated;
    },
    vehicleMakes() {
      return this.$store.getters["categories/vehicleMakes"];
    },
    vehicleModels() {
      return this.$store.getters["categories/vehicleModels"];
    },
    categories() {
      return this.$store.getters["categories/categories"];
    },
  },
  methods: {
    async pageChangeHandle(value) {
      switch (value) {
        case "next":
          this.currentPage += 1;
          break;
        case "previous":
          this.currentPage -= 1;
          break;
        default:
          this.currentPage = value;
      }
      let params = { ...this.params };
      params.p = this.currentPage;
      await this.$store.dispatch("parts/loadPartsWeb", {
        params: params,
      });
    },
    async loadParts() {
      this.isLoading = true;
      try {
        this.params.search = this.$route.params.q;
        await this.$store.dispatch("parts/loadPartsWeb", {
          params: this.params,
        });
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;
    },
    hasValue(q, haystack) {
      return haystack.includes(q);
    },
    findCat(id) {
      let value = this.categories.find((o) => o.id === id);
      return value ? value.name : id;
    },
    findMake(id) {
      let value = this.vehicleMakes.find((o) => o.id === id);
      return value ? value.name : id;
    },
    findModel(make, id) {
      if (id === null) {
        return "";
      }
      let vmake = this.vehicleMakes.find((o) => o.id === make);
      if (vmake) {
        let value = vmake.vehicleModels.find((c) => c.id === id);
        return value ? value.name : id;
      }
      return "";
    },
    addToWishlist() {
      console.log("logged in");
    },
  },
  mounted() {
    document.title = "PSI Link | Find Parts";
    this.loadParts().then(() => {
      console.log("SUCCESS");
    });
  },
};
</script>
