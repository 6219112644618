<template>
  <ul class="uren-pagination-box primary-color" v-if="pageCount > 1">
    <li v-if="!isPreviousButtonDisabled">
      <a class="Next" @click="previousPage" href="javascript:void(0)">
        Previous
      </a>
    </li>
    <BasePaginationTrigger
      v-for="paginationTrigger in paginationTriggers"
      :activeClass="paginationTrigger === currentPage"
      :key="paginationTrigger"
      :pageNumber="paginationTrigger"
      class="base-pagination__description"
      @loadPage="onLoadPage"
    />
    <li v-if="!isNextButtonDisabled">
      <a class="Next" @click="nextPage" href="javascript:void(0)"> Next </a>
    </li>
  </ul>
</template>

<script>
import BasePaginationTrigger from "./BasePaginationTrigger.vue";

export default {
  components: { BasePaginationTrigger },
  props: {
    currentPage: {
      type: Number,
      required: true,
    },
    pageCount: {
      type: Number,
      required: true,
    },
    visiblePagesCount: {
      type: Number,
      default: 5,
    },
  },
  computed: {
    isPreviousButtonDisabled() {
      return this.currentPage === 1;
    },
    isNextButtonDisabled() {
      return this.currentPage === this.pageCount;
    },
    paginationTriggers() {
      const currentPage = this.currentPage;
      const pageCount = this.pageCount;
      const visiblePagesCount = this.visiblePagesCount;
      const visiblePagesThreshold = Math.round((visiblePagesCount - 1) / 2);
      const pagintationTriggersArray = Array(this.visiblePagesCount - 1).fill(
        0
      );

      if (currentPage <= visiblePagesThreshold + 1) {
        pagintationTriggersArray[0] = 1;
        const pagintationTriggers = pagintationTriggersArray.map((_, index) => {
          return pagintationTriggersArray[0] + index;
        });
        pagintationTriggers.push(pageCount);
        return pagintationTriggers;
      }

      if (currentPage >= pageCount - visiblePagesThreshold + 1) {
        const pagintationTriggers = pagintationTriggersArray.map((_, index) => {
          return pageCount - index;
        });
        pagintationTriggers.reverse().unshift(1);
        return pagintationTriggers;
      }

      pagintationTriggersArray[0] = currentPage - visiblePagesThreshold + 1;
      const pagintationTriggers = pagintationTriggersArray.map((_, index) => {
        return pagintationTriggersArray[0] + index;
      });
      pagintationTriggers.unshift(1);
      pagintationTriggers[pagintationTriggers.length - 1] = pageCount;
      return pagintationTriggers;
    },
  },
  methods: {
    nextPage() {
      this.$emit("nextPage");
    },
    previousPage() {
      this.$emit("previousPage");
    },
    onLoadPage(value) {
      this.$emit("loadPage", value);
    },
  },
};
</script>
