<template>
  <!-- Begin Uren's Banner Area -->
  <div class="uren-banner_area">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-6">
          <div class="banner-item img-hover_effect">
            <div
              class="banner-img-1"
              :style="`background-image: url(${first_ad.ad_image})`"
            ></div>
            <!-- override inline with background-image: url(../images/banner/1-1.jpg); -->
            <div class="banner-content">
              <span class="offer">{{ first_ad.ad_heading }}</span>
              <h4>{{ first_ad.title }}</h4>
              <h3 v-if="first_ad.ad_heading">{{ first_ad.subtitle }}</h3>
              <p v-html="first_ad.description"></p>
              <div class="uren-btn-ps_left" v-if="first_ad.company_url !== ''">
                <a class="uren-btn" :href="first_ad.company_url">{{
                  first_ad.btn_text
                }}</a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6" v-if="second_ad">
          <div class="banner-item img-hover_effect">
            <div
              class="banner-img-1 banner-img-2"
              :style="`background-image: url(${second_ad.ad_image})`"
            ></div>
            <div class="banner-content">
              <span class="offer">{{ second_ad.ad_heading }}</span>
              <h4>{{ second_ad.title }}</h4>
              <h3 v-if="second_ad.ad_heading">{{ second_ad.subtitle }}</h3>
              <p v-html="second_ad.description"></p>
              <div class="uren-btn-ps_left" v-if="second_ad.company_url !== ''">
                <a class="uren-btn" :href="second_ad.company_url">{{
                  second_ad.btn_text
                }}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Uren's Banner Area End Here -->
</template>

<script>
export default {
  props: {
    first_ad: {
      type: Object, // fields ad Heading  image_url, title, subtitle, description, company_url
      require: true,
    },
    second_ad: {
      type: Object, // fields ad Heading image_url, title, subtitle, description, company_url
      required: false,
    },
  },
};
</script>
