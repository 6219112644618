<template>
  <div>
    <base-dialog :show="isLoading" title="Loading..." fixed>
      <base-spinner></base-spinner>
    </base-dialog>
    <admin-header></admin-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col">
          <div class="card shadow bg-default">
            <div class="card-header border-0 bg-transparent">
              <div class="row align-items-center">
                <div class="col">
                  <h3 class="mb-0 text-white" v-if="selectedPackage">
                    You are about to subscribe for {{ selectedPackage.name }}.
                  </h3>
                  <!-- <a href="/admin-add-part" class="btn btn-md btn-block btn-primary"
                      >Pay for Subscription</a
                    > -->
                </div>
                <div class="col text-right">
                  <base-button
                    type="primary"
                    @click="showDialog = true"
                    size="md"
                    block
                    icon="ni ni-shop"
                    >Pay for Subscription
                  </base-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <payment-dialog
      :show="showDialog"
      title="PAY FOR SUBSCRIPTION"
      @close="closeDialog"
    >
      <template v-slot:default>
        <div class="nav-wrapper">
          <ul
            class="nav nav-pills nav-fill"
            role="tablist"
            style="margin-bottom: 20px"
          >
            <li class="nav-item" :class="{ active: tabActive === 'pd' }">
              <a
                data-toggle="tab"
                role="tab"
                class="nav-link"
                :class="{ active: tabActive === 'pd' }"
                @click="test('pd')"
              >
                <div>Personal Details</div>
              </a>
            </li>
            <li class="nav-item" :class="{ active: tabActive === 'cp' }">
              <a
                data-toggle="tab"
                role="tab"
                class="nav-link"
                :class="{ active: tabActive === 'cp' }"
                @click="test('cp')"
              >
                <div>Complete Payment</div>
              </a>
            </li>
          </ul>

          <div class="tab-content">
            <div class="card shadow custom-h">
              <div class="card-body">
                <div
                  id="personal_details"
                  class="tab-pane fade in"
                  :class="{ active: tabActive === 'pd' }"
                >
                  <div class="description">
                    <h3 class="mb-4">
                      Please enter/confirm your details before payment
                    </h3>
                    <Form
                      class="uren-form"
                      :validation-schema="schema"
                      v-slot="{ errors }"
                    >
                      <div class="uren-form-inner">
                        <div class="single-input single-input-half">
                          <label
                            :class="{ 'invalid-feedback': errors.first_name }"
                            for="account-details-firstname"
                            >First Name*</label
                          >
                          <Field
                            name="first_name"
                            id="account-details-firstname"
                            class="form-control"
                            :class="{ 'is-invalid': errors.first_name }"
                            v-model="paymentDetails.first_name"
                            type="text"
                            placeholder="First Name"
                          />
                          <div class="invalid-feedback">
                            {{ errors.first_name }}
                          </div>
                        </div>
                        <div class="single-input single-input-half">
                          <label
                            :class="{ 'invalid-feedback': errors.last_name }"
                            for="account-details-lastname"
                            >Last Name*</label
                          >
                          <Field
                            name="last_name"
                            id="account-details-lastname"
                            class="form-control"
                            :class="{ 'is-invalid': errors.last_name }"
                            v-model="paymentDetails.last_name"
                            type="text"
                            placeholder="Last Name"
                          />
                          <div class="invalid-feedback">
                            {{ errors.last_name }}
                          </div>
                        </div>
                        <div class="single-input single-input-half">
                          <label
                            :class="{ 'invalid-feedback': errors.email }"
                            for="account-details-email"
                            >Email*</label
                          >
                          <Field
                            name="email"
                            id="account-details-email"
                            class="form-control"
                            :class="{ 'is-invalid': errors.email }"
                            v-model="paymentDetails.email"
                            type="email"
                            placeholder="Email Address"
                          />
                          <div class="invalid-feedback">{{ errors.email }}</div>
                        </div>
                        <div class="single-input single-input-half">
                          <label
                            :class="{ 'invalid-feedback': errors.recurring }"
                            for="account-details-recurring"
                            >Recurring Payment</label
                          >
                          <Field
                            as="base-checkbox"
                            :value="true"
                            v-model="paymentDetails.recurring"
                            type="checkbox"
                            id="account-details-recurring"
                            :class="{ 'is-invalid': errors.recurring }"
                            name="recurring"
                            class="mb-3"
                          />
                          <div class="invalid-feedback">
                            {{ errors.recurring }}
                          </div>
                        </div>
                        <div class="single-input">
                          <label
                            :class="{ 'invalid-feedback': errors.address }"
                            for="account-details-address"
                            >Address</label
                          >
                          <Field
                            name="address"
                            id="account-details-lastname"
                            class="form-control"
                            :class="{ 'is-invalid': errors.address }"
                            v-model="paymentDetails.address"
                            type="text"
                            placeholder="Address"
                          />
                          <div class="invalid-feedback">
                            {{ errors.address }}
                          </div>
                        </div>
                        <div class="single-input">
                          <base-button
                            @click="test('cp')"
                            size="md"
                            type="primary"
                            class="float-right"
                            >NEXT</base-button
                          >
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
                <div
                  id="complete_payment"
                  class="tab-pane fade in"
                  :class="{ active: tabActive === 'cp' }"
                >
                  <div class="description text-center">
                    <h3>You are about to pay</h3>
                    <br />
                    <h1>R{{ paymentDetails.amount }}</h1>
                    <input
                      @click="testing()"
                      class="btn btn-primary btn-md btn-block"
                      type="button"
                      value="PAY"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </payment-dialog>
  </div>
</template>
<script>
import { Field, Form } from "vee-validate";
import * as yup from "yup";
import postToURL from "../../utils/payfast-utils";

export default {
  components: { Form, Field },
  props: ["packageId"],
  data() {
    return {
      isLoading: false,
      showDialog: false,
      selectedPackage: null,
      currentTab: "pd",
      paymentDetails: {
        first_name: "",
        last_name: "",
        email: "",
        companyName: "",
        address: "",
        recurring: true,
      },
    };
  },
  methods: {
    closeDialog() {
      this.showDialog = false;
    },
    test(tabName) {
      this.currentTab = tabName;
    },
    testing() {
      // console.log(process.env.VUE_APP_PAYFAST_URL);
      const payFastUrl = process.env.VUE_APP_PAYFAST_URL;
      const merchantId = process.env.VUE_APP_MERCHANT_ID;
      const merchantKey = process.env.VUE_APP_MERCHANT_KEY;
      const returnUrl = process.env.VUE_APP_RETURN_URL;
      const cancelUrl = process.env.VUE_APP_CANCEL_URL;
      const notifyUrl = process.env.VUE_APP_NOTIFY_URL;
      const emailConfirmation = process.env.VUE_APP_EMAIL_CONFIRMATION;
      const confirmationAddress = process.env.VUE_APP_CONFIMATION_ADDRESS;

      postToURL(payFastUrl, {
        merchant_id: merchantId,
        merchant_key: merchantKey,
        return_url: returnUrl + `-${this.selectedPackage.id}`,
        cancel_url: cancelUrl + `-${this.selectedPackage.id}`,
        notify_url: notifyUrl,
        name_first: this.paymentDetails.first_name,
        name_last: this.paymentDetails.last_name,
        email_address: this.paymentDetails.email,
        m_payment_id: "",
        amount: this.paymentDetails.amount,
        item_name: this.selectedPackage.name,
        item_description: this.selectedPackage.description,
        email_confirmation: emailConfirmation,
        confirmation_address: confirmationAddress,
        custom_int1: this.selectedPackage.id,
        custom_int2: this.paymentDetails.recurring === null ? 0 : 1,
        custom_str1: this.service.slug,
      });
    },
    async loadService() {
      this.isLoading = true;
      try {
        await this.$store.dispatch("services/getService", {
          serviceId: this.loggedInUser.id,
        });
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;
    },
    async loadPackages() {
      this.isLoading = true;
      try {
        await this.$store.dispatch("subscription_types/loadSubscriptionTypes");
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;
    },
  },
  computed: {
    tabActive() {
      return this.currentTab;
    },
    schema() {
      const schema = yup.object({
        first_name: yup.string().required().min(4),
        last_name: yup.string().required().min(4),
        recurring: yup.string().required(),
        email: yup.string().required().email(),
        address: yup.string().required(),
      });

      return schema;
    },
    loggedInUser() {
      return this.$store.getters.user;
    },
    service() {
      return this.$store.getters["services/service"];
    },
    hasService() {
      return this.$store.getters["services/hasService"];
    },
    subscription_types() {
      return this.$store.getters["subscription_types/subscription_types"];
    },
    hasSubscriptionTypes() {
      return this.$store.getters["subscription_types/hasSubscriptionTypes"];
    },
  },
  async created() {
    document.title = "PSI Link | Admin Adverts";
    await this.loadService();

    this.selectedPackage = this.$store.getters[
      "subscription_types/subscription_types"
    ].find((p) => p.id === parseInt(this.packageId));

    // console.log(this.service.addresses);

    this.paymentDetails.first_name = this.loggedInUser.first_name;
    this.paymentDetails.last_name = this.loggedInUser.last_name;
    this.paymentDetails.email = this.loggedInUser.email;
    this.paymentDetails.companyName = this.service.companyName || "";
    this.paymentDetails.address =
      this.service.addresses.length > 0 ? this.service.addresses[0] : "";
    this.paymentDetails.amount = this.selectedPackage.price;
    console.log(this.paymentDetails);
  },
};
</script>
<style scoped>
.custom-h {
  min-height: 1px;
  max-height: 400px;
  overflow: scroll;
}
</style>
