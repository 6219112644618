<template>
  <!-- Begin Loading Area -->
  <div class="loading">
    <div class="text-center middle">
      <div class="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </div>
  <!-- Loading Area End Here -->
</template>

<script>
export default {};
</script>
