import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";

export default {
  namespaced: true,
  state() {
    return {
      categories: [],
      sub_categories: [],
      partTypes: [],
      vehicleMakes: [],
      vehicleModels: [],
    };
  },
  mutations,
  actions,
  getters,
};
