<template>
  <div>
    <base-dialog :show="isLoading" title="Loading..." fixed>
      <base-spinner></base-spinner>
    </base-dialog>
    <base-dialog
      :show="pageErrors.length > 0 || successMsg !== ''"
      :title="alertTitle"
      @close="handleError"
    >
      <div v-if="pageErrors.length > 0 && successMsg === ''">
        <base-alert v-for="error in pageErrors" :key="error" type="danger">
          <span class="alert-inner--text">{{ error }}</span>
        </base-alert>
      </div>
      <div v-else>
        <base-alert type="success">
          <span class="alert-inner--text">{{ successMsg }}</span>
        </base-alert>
      </div>
    </base-dialog>
    <base-header
      class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
      style="
        min-height: 600px;
        background-image: url(img/theme/car-img.jpg);
        background-size: cover;
        background-position: center top;
      "
    >
      <!-- Mask -->
      <span class="mask bg-gradient-success opacity-8"></span>
      <!-- Header container -->
      <div class="container-fluid d-flex align-items-center">
        <div class="row">
          <div class="col-lg-7 col-md-10"></div>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <!-- <div class="col-xl-4 order-xl-2 mb-5 mb-xl-0"></div> -->

        <div class="col-xl-12 order-xl-1">
          <card shadow type="secondary">
            <template v-slot:header>
              <div class="bg-white border-0">
                <div class="row align-items-center">
                  <div class="col-8">
                    <h3 class="mb-0">Add New Part</h3>
                  </div>
                  <div class="col-4 text-right">
                    <a href="/admin-parts" class="btn btn-sm btn-primary"
                      >View All Parts</a
                    >
                    <a href="/admin-upload-parts" class="btn btn-sm btn-warning"
                      >Upload Excel</a
                    >
                  </div>
                </div>
              </div>
            </template>

            <Form
              @submit="onSubmit"
              :validation-schema="schema"
              v-slot="{ errors }"
              enctype="multipart/form-data"
              id="addPartForm"
            >
              <h6 class="heading-small text-muted mb-4">Part Information</h6>
              <div class="pl-lg-4">
                <div class="row">
                  <div class="col-lg-6 form-group">
                    <label :class="{ 'error-show': errors.name }"
                      ><strong>Part Name</strong></label
                    >
                    <Field
                      name="name"
                      class="form-control"
                      :class="{ 'is-invalid': errors.name }"
                      v-model="part.name"
                      type="text"
                      placeholder="Part Name"
                    />
                    <div class="invalid-feedback">
                      {{ errors.name }}
                    </div>
                  </div>
                  <div class="col-lg-6 form-group">
                    <label :class="{ 'error-show': errors.oePartNumber }"
                      ><strong>Part Number</strong></label
                    >
                    <Field
                      name="oePartNumber"
                      class="form-control"
                      :class="{ 'is-invalid': errors.oePartNumber }"
                      v-model="part.oePartNumber"
                      type="text"
                      placeholder="Part Number"
                    />
                    <div class="invalid-feedback">
                      {{ errors.oePartNumber }}
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6 form-group">
                    <label :class="{ 'error-show': errors.category }"
                      ><strong>Select Main Category</strong></label
                    >
                    <Field
                      v-slot="{ field }"
                      name="category"
                      class="form-control"
                    >
                      <Multiselect
                        v-bind="field"
                        placeholder="Select Category"
                        v-model="partCategory"
                        :closeOnSelect="true"
                        :searchable="true"
                        :options="categories"
                      />
                    </Field>
                    <div class="invalid-feedback">
                      {{ errors.category }}
                    </div>
                  </div>
                  <div class="col-lg-6 form-group">
                    <label :class="{ 'error-show': errors.subCategory }"
                      ><strong>Select Sub-Category</strong></label
                    >
                    <Field
                      v-slot="{ field }"
                      name="subCategory"
                      class="form-control"
                    >
                      <Multiselect
                        v-bind="field"
                        placeholder="Select Sub-category"
                        v-model="part.subCategory"
                        mode="tags"
                        :closeOnSelect="false"
                        :searchable="true"
                        :options="sub_categories"
                      />
                    </Field>
                    <div class="invalid-feedback">
                      {{ errors.subCategory }}
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6 form-group">
                    <label :class="{ 'error-show': errors.vehicleMake }"
                      ><strong>Vehicle Make</strong></label
                    >
                    <!-- <Field
                      name="vehicleMake"
                      class="form-control"
                      :class="{ 'is-invalid': errors.vehicleMake }"
                      v-model="part.vehicleMake"
                      type="text"
                      placeholder="Vehicle Make"
                    /> -->
                    <Field
                      v-slot="{ field }"
                      name="vehicleMake"
                      class="form-control"
                    >
                      <Multiselect
                        v-bind="field"
                        v-model="partMake"
                        placeholder="Select Vehicle Make"
                        value="1"
                        :searchable="true"
                        :options="vehicleMakes"
                      />
                    </Field>
                    <div class="invalid-feedback">
                      {{ errors.vehicleMake }}
                    </div>
                  </div>
                  <div class="col-lg-6 form-group">
                    <label :class="{ 'error-show': errors.vehicleModel }"
                      ><strong>Vehicle Model</strong></label
                    >
                    <!-- <Field
                      name="vehicleModel"
                      class="form-control"
                      :class="{ 'is-invalid': errors.vehicleModel }"
                      v-model="part.vehicleModel"
                      type="text"
                      placeholder="Vehicle Model"
                    /> -->
                    <Field
                      v-slot="{ field }"
                      name="vehicleModel"
                      class="form-control"
                    >
                      <Multiselect
                        v-bind="field"
                        v-model="part.vehicleModel"
                        placeholder="Select Vehicle Model"
                        value="1"
                        :searchable="true"
                        :options="vehicleModels"
                      />
                    </Field>
                    <div class="invalid-feedback">
                      {{ errors.vehicleModel }}
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6 form-group">
                    <label :class="{ 'error-show': errors.vehicleYear }"
                      ><strong>Vehicle Year</strong></label
                    >
                    <Field
                      name="vehicleYear"
                      class="form-control"
                      :class="{ 'is-invalid': errors.vehicleYear }"
                      v-model="part.vehicleYear"
                      type="text"
                      placeholder="Vehicle Year"
                    />
                    <div class="invalid-feedback">
                      {{ errors.vehicleYear }}
                    </div>
                  </div>
                  <div class="col-lg-6 form-group">
                    <div v-if="loggedInUser.is_superuser" class="form-group">
                      <label :class="{ 'invalid-feedback': errors.service }"
                        ><strong>Select Service</strong></label
                      >
                      <Field v-slot="{ field }" name="service">
                        <Multiselect
                          v-bind="field"
                          v-model="part.service"
                          placeholder="Select Service"
                          :searchable="true"
                          :options="services"
                        />
                      </Field>
                      <div class="invalid-feedback">
                        {{ errors.service }}
                      </div>
                    </div>
                    <div v-else class="form-group">
                      <label :class="{ 'error-show': errors.service }"
                        >Select Service</label
                      >
                      <Field
                        disabled
                        name="service"
                        v-model="part.service"
                        class="form-control"
                        placeholder="Service"
                      >
                      </Field>
                      <div class="invalid-feedback">
                        {{ errors.service }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6 form-group">
                    <label :class="{ 'error-show': errors.type }"
                      ><strong>Select Type</strong></label
                    >
                    <Field v-slot="{ field }" name="type" class="form-control">
                      <Multiselect
                        v-bind="field"
                        v-model="part.type"
                        placeholder="Select Type"
                        value="oe"
                        :options="[
                          { value: 'oe', label: 'OE' },
                          { value: 'refurbished', label: 'Refurbished' },
                          { value: 'alternative', label: 'Alternative' },
                          { value: 'used', label: 'Used' },
                        ]"
                      />
                    </Field>
                    <div class="invalid-feedback">
                      {{ errors.type }}
                    </div>
                  </div>
                  <div class="col-lg-6 form-group">
                    <label
                      :class="{ 'error-show': errors.alternativePartNumber }"
                      ><strong>Alternative Part Number</strong></label
                    >
                    <Field
                      name="alternativePartNumber"
                      class="form-control"
                      :class="{ 'is-invalid': errors.alternativePartNumber }"
                      v-model="part.alternativePartNumber"
                      type="text"
                      placeholder="Alternative Part Number"
                    />
                    <div class="invalid-feedback">
                      {{ errors.alternativePartNumber }}
                    </div>
                  </div>
                  <!-- <div class="col-lg-6 form-group">
                    <label :class="{ 'error-show': errors.partType }"
                      ><strong>Select Part Type</strong></label
                    >
                    <Field
                      v-slot="{ field }"
                      name="partType"
                      class="form-control"
                    >
                      <Multiselect
                        v-bind="field"
                        v-model="part.partType"
                        placeholder="Select Part Type"
                        :searchable="true"
                        :options="partTypes"
                      />
                    </Field>
                    <div class="invalid-feedback">
                      {{ errors.partType }}
                    </div>
                  </div> -->
                  <div class="col-lg-12 form-group">
                    <label :class="{ 'error-show': errors.description }"
                      ><strong>Part Description</strong></label
                    >
                    <Field
                      as="textarea"
                      v-model="part.description"
                      name="description"
                      class="form-control"
                      rows="4"
                      placeholder="Part Description..."
                    >
                    </Field>
                    <div
                      class="invalid-feedback"
                      :style="
                        errors.description
                          ? { display: 'block' }
                          : { display: 'none' }
                      "
                    >
                      {{ errors.description }}
                    </div>
                  </div>
                  <!-- <div class="col-lg-12 form-group">
                    <label :class="{ 'error-show': errors.categories }"
                      ><strong>Tags</strong></label
                    >
                    <Field v-slot="{ field }" name="tags" class="form-control">
                      <Multiselect
                        v-model="part.tags"
                        mode="tags"
                        :closeOnSelect="false"
                        :searchable="true"
                        :createTag="true"
                        v-bind="field"
                        :options="tagOptions"
                      />
                    </Field>
                    <div class="invalid-feedback">
                      {{ errors.tags }}
                    </div>
                  </div> -->
                </div>
                <!-- <div class="row">
                  <div class="col-lg-6 form-group">
                    <label :class="{ 'error-show': errors.categories }"
                      >Select Categories</label
                    >
                    <Field
                      v-slot="{ field }"
                      name="categories"
                      class="form-control"
                    >
                      <Multiselect
                        v-bind="field"
                        placeholder="Select Categories"
                        v-model="part.categories"
                        mode="tags"
                        :closeOnSelect="false"
                        :searchable="true"
                        :options="categories"
                      />
                    </Field>
                    <div class="invalid-feedback">
                      {{ errors.categories }}
                    </div>
                  </div>
                  <div class="col-lg-6 form-group">
                    <div v-if="loggedInUser.is_superuser" class="form-group">
                      <label :class="{ 'invalid-feedback': errors.service }"
                        >Select Service</label
                      >
                      <Field v-slot="{ field }" name="service">
                        <Multiselect
                          v-bind="field"
                          v-model="part.service"
                          placeholder="Select Service"
                          :searchable="true"
                          :options="services"
                        />
                      </Field>
                      <div class="invalid-feedback">
                        {{ errors.service }}
                      </div>
                    </div>
                    <div v-else class="form-group">
                      <label :class="{ 'error-show': errors.service }"
                        >Select Service</label
                      >
                      <Field
                        disabled
                        name="service"
                        v-model="part.service"
                        class="form-control"
                        placeholder="Service"
                      >
                      </Field>
                      <div class="invalid-feedback">
                        {{ errors.service }}
                      </div>
                    </div>
                  </div>
                </div> -->
              </div>
              <hr class="my-4" />
              <!-- Address -->
              <h6 class="heading-small text-muted mb-4">Part Pricing</h6>
              <div class="pl-lg-4">
                <div class="row">
                  <div class="col-lg-6 form-group">
                    <label :class="{ 'error-show': errors.quantity }"
                      ><strong>Part Quantity</strong></label
                    >
                    <Field
                      name="quantity"
                      class="form-control"
                      :class="{ 'is-invalid': errors.quantity }"
                      v-model="part.quantity"
                      type="number"
                      min="0"
                      placeholder="Part Quantity"
                    />
                    <div class="invalid-feedback">
                      {{ errors.quantity }}
                    </div>
                  </div>
                  <div class="col-lg-6 form-group">
                    <label :class="{ 'error-show': errors.discount }"
                      ><strong>Part Discount</strong></label
                    >
                    <Field
                      name="discount"
                      class="form-control"
                      :class="{ 'is-invalid': errors.discount }"
                      v-model="part.discount"
                      type="number"
                      min="0"
                      max="100"
                      placeholder="Part Discount"
                    />
                    <div class="invalid-feedback">
                      {{ errors.discount }}
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6 form-group">
                    <label :class="{ 'error-show': errors.price }"
                      ><strong>Part Price</strong></label
                    >
                    <Field
                      name="price"
                      class="form-control"
                      :class="{ 'is-invalid': errors.price }"
                      v-model="part.price"
                      type="number"
                      min="-1.00"
                      placeholder="Part Price"
                    />
                    <div class="invalid-feedback">
                      {{ errors.price }}
                    </div>
                  </div>
                  <!-- <div class="col-lg-6 form-group">
                    <label :class="{ 'error-show': errors.oldprice }"
                      ><strong>Part Oldprice</strong></label
                    >
                    <Field
                      name="oldprice"
                      class="form-control"
                      :class="{ 'is-invalid': errors.oldprice }"
                      v-model="part.oldprice"
                      type="number"
                      min="0.01"
                      placeholder="Part Oldprice"
                    />
                    <div class="invalid-feedback">
                      {{ errors.oldprice }}
                    </div>
                  </div> -->
                </div>
              </div>
              <hr class="my-4" />
              <!-- Description -->
              <h6 class="heading-small text-muted mb-4">Part Images</h6>
              <div class="pl-lg-4">
                <div class="col-lg-12 form-group">
                  <label :class="{ 'error-show': errors.images }"
                    ><strong>Part Media</strong></label
                  >
                  <Field v-slot="{ field }" name="images" class="form-control">
                    <UploadImages
                      uploadMsg="Add Part Images"
                      class="form-control"
                      v-bind="field"
                      @changed="onChange"
                    />
                  </Field>
                  <div
                    class="invalid-feedback"
                    :style="
                      errors.images ? { display: 'block' } : { display: 'none' }
                    "
                  >
                    {{ errors.images }}
                  </div>
                </div>
              </div>
              <div class="pl-lg-4">
                <base-button
                  type="primary"
                  nativeType="submit"
                  block
                  icon="ni ni-bag-17"
                  >Save Part</base-button
                >
              </div>
            </Form>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Field, Form } from "vee-validate";
import Multiselect from "@vueform/multiselect";
import UploadImages from "vue-upload-drop-images";
import * as yup from "yup";

export default {
  components: { Form, Field, Multiselect, UploadImages },
  data() {
    return {
      isLoading: false,
      tagOptions: [
        { value: "new", label: "New" },
        { value: "hot", label: "Hot" },
        { value: "exclusive", label: "Exclusive" },
      ],
      pageErrors: [],
      successMsg: "",
      partCategory: null,
      partMake: null,
      part: {
        name: "",
        oePartNumber: "",
        alternativePartNumber: "",
        vehicleMake: null,
        vehicleModel: "",
        vehicleYear: "",
        type: null,
        partType: null,
        service: null,
        category: null,
        subCategory: [],
        description: "",
        quantity: 0,
        discount: 0,
        price: 0,
        tags: [],
        images: [],
      },
    };
  },
  computed: {
    typeValid() {
      return this.part.type !== null;
    },
    alertTitle() {
      if (this.pageErrors.length > 0 && this.successMsg === "") {
        return this.pageErrors.length === 1
          ? "An Error has occurred"
          : "A couple of errors have occured";
      } else {
        return "Details Update";
      }
    },
    serviceValid() {
      return this.part.service !== null;
    },
    partTypeValid() {
      return this.part.partType !== null;
    },
    categoriesValid() {
      return this.part.categories.length > 0;
    },
    schema() {
      const errormsg =
        "must be a `number` type, but the final value was: `NaN`. Enter 0 if no value or -1 for 'contact for price'";
      const schema = yup.object({
        name: yup.string().required(),
        oePartNumber: yup.string().required(),
        alternativePartNumber: yup.string().nullable(),
        vehicleMake: yup.string().required(),
        vehicleModel: yup.string().nullable(),
        vehicleYear: yup.string(),
        quantity: yup
          .number()
          .typeError("Quantity " + errormsg)
          .required()
          .min(1),
        discount: yup
          .number()
          .typeError("Discount " + errormsg)
          .min(0)
          .max(100),
        price: yup
          .number()
          .min(-1)
          .typeError("Price " + errormsg), //.min(0),
        type: yup.string().required(),
        // partType: yup.string().required(),
        service: yup.string().required(),
        category: yup.string().required(),
        subCategory: yup.array().min(1).required(),
        images: yup.array().min(1).required(),
        description: yup.string(),
      });

      return schema;
    },
    sub_categories() {
      const cats = [];
      const categories = this.$store.getters["categories/subCategories"];
      for (let index = 0; index < categories.length; index++) {
        let element = {
          label: categories[index].name,
          value: categories[index].id,
        };
        cats.push(element);
      }
      return cats;
    },
    categories() {
      const cats = [];
      const categories = this.$store.getters["categories/categories"];
      for (let index = 0; index < categories.length; index++) {
        let element = {
          label: categories[index].name,
          value: categories[index].id,
        };
        cats.push(element);
      }
      return cats;
    },
    vehicleMakes() {
      const makes = [];
      const vMakes = this.$store.getters["categories/vehicleMakes"];
      for (let index = 0; index < vMakes.length; index++) {
        let element = {
          label: vMakes[index].name,
          value: vMakes[index].id,
        };
        makes.push(element);
      }
      return makes;
    },
    vehicleModels() {
      const models = [];
      const vModels = this.$store.getters["categories/vehicleModels"];
      for (let index = 0; index < vModels.length; index++) {
        let element = {
          label: vModels[index].name,
          value: vModels[index].id,
        };
        models.push(element);
      }
      return models;
    },
    partTypes() {
      const partTypes = [];
      const pTypes = this.$store.getters["categories/partTypes"];
      for (let index = 0; index < pTypes.length; index++) {
        let element = { label: pTypes[index].name, value: pTypes[index].id };
        partTypes.push(element);
      }
      return partTypes;
    },
    services() {
      const services = [];
      const servArr = this.$store.getters["services/services"];
      for (let index = 0; index < servArr.length; index++) {
        let element = { label: servArr[index].name, value: servArr[index].id };
        services.push(element);
      }
      return services;
    },
    service() {
      return this.$store.getters["services/service"];
    },
    isLoggedIn() {
      return this.$store.getters.isAuthenticated;
    },
    loggedInUser() {
      return this.$store.getters.user;
    },
    loggedInUserToken() {
      return this.$store.getters.token;
    },
    loggedInUserId() {
      return this.$store.getters.userId;
    },
  },
  created() {
    if (this.loggedInUser.is_superuser) {
      this.loadServices();
    } else {
      this.part.service = this.loggedInUserId;
      this.loadService();
    }

    this.loadCategories();
    // this.loadSubCategoriesByCat(this.part.category);
    this.loadVehicleMakes();
    // this.loadVehicleModels(this.part.vehicleMake);
    this.loadPartTypes();
  },
  methods: {
    onChange(files) {
      // console.log(files);
      this.part.images = files;
      console.log(this.part.images);
    },
    async loadServices() {
      this.isLoading = true;
      try {
        await this.$store.dispatch("services/loadServices");
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;
    },
    async loadService() {
      this.isLoading = true;
      try {
        await this.$store.dispatch("services/getService", {
          serviceId: this.loggedInUser.id,
        });
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;
    },
    async loadCategories() {
      this.isLoading = true;
      try {
        await this.$store.dispatch("categories/loadCategories");
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;
    },
    async loadSubCategoriesByCat(catId) {
      this.isLoading = true;
      try {
        await this.$store.dispatch("categories/loadSubCategories", {
          params: {
            category: catId,
          },
        });
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;
    },
    async loadVehicleMakes() {
      this.isLoading = true;
      try {
        await this.$store.dispatch("categories/loadVehicleMakes");
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;
    },
    async loadVehicleModels(makeId) {
      this.isLoading = true;
      try {
        await this.$store.dispatch("categories/loadVehicleModels", {
          params: {
            make: makeId,
          },
        });
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;
    },
    async loadPartTypes() {
      this.isLoading = true;
      try {
        await this.$store.dispatch("categories/loadPartTypes");
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;
    },
    async onSubmit() {
      this.isLoading = true;
      const formData = new FormData();
      formData.append("name", this.part.name);
      formData.append("oePartNumber", this.part.oePartNumber);
      formData.append("alternativePartNumber", this.part.alternativePartNumber);
      formData.append("vehicleMake", this.part.vehicleMake);
      formData.append(
        "vehicleModel",
        this.part.vehicleModel === null ? "" : this.part.vehicleModel
      );
      formData.append("vehicleYear", this.part.vehicleYear);
      formData.append("quantity", this.part.quantity);
      formData.append("discount", this.part.discount);
      formData.append("price", this.part.price);
      // formData.append("oldprice", this.part.oldprice);
      formData.append("type", this.part.type);
      // formData.append("partType", this.part.partType);
      formData.append("description", this.part.description);
      formData.append("service", this.part.service);
      formData.append("category", this.part.category);
      // formData.append("tags", JSON.stringify(this.part.tags));

      for (const t of Object.keys(this.part.subCategory)) {
        formData.append("subCategory", this.part.subCategory[t]);
      }

      for (const i of Object.keys(this.part.images)) {
        formData.append("images", this.part.images[i]);
      }

      try {
        const success = await this.$store.dispatch("parts/addPart", {
          form: formData,
        });
        if (success) {
          this.successMsg = "A new part has been successfully created.";
          // this.resetForm();
        }
      } catch (error) {
        console.log(error);
        console.log(error.response.data);
        if (error.response.data.non_field_errors) {
          this.pageErrors = error.response.data.non_field_errors;
        } else {
          for (const key in error.response.data) {
            this.pageErrors.push(
              error.response.data[key][0] + " (" + key.toUpperCase() + ")"
            );
          }
        }
      }
      this.isLoading = false;
    },
    resetForm() {
      this.part = {
        name: "",
        oePartNumber: "",
        alternativePartNumber: "",
        vehicleMake: "",
        vehicleModel: "",
        vehicleYear: "",
        type: null,
        partType: null,
        service: null,
        categories: [],
        quantity: null,
        discount: null,
        price: null,
        oldprice: null,
        tags: [],
        images: [],
      };
    },
    handleError() {
      this.pageErrors = [];
      if (this.successMsg !== "") {
        this.successMsg = "";
        this.$router.push("/admin-parts");
      }
    },
  },
  watch: {
    partCategory: function (val) {
      this.partCategory = val;
      this.part.category = val;
      this.part.subCategory = [];
      this.loadSubCategoriesByCat(val);
    },
    partMake: function (val) {
      this.partMake = val;
      this.part.vehicleMake = val;
      this.part.vehicleModel = null;
      this.loadVehicleModels(val);
    },
  },
};
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped>
.error-show {
  color: #fb6340;
}
</style>
