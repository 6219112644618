import GlobalComponents from "./globalComponents";
import GlobalDirectives from "./globalDirectives";

import "../assets/vendor/nucleo/css/nucleo.css";
import "../assets/vendor/@fortawesome/fontawesome-free/css/all.min.css";
import "../assets/scss/argon.scss";
import "../../public/assets/css/vendor/ion-fonts.css";
import "../../public/assets/css/plugins/slick.css";
import "../../public/assets/css/plugins/animate.css";
import "../../public/assets/css/plugins/jquery-ui.min.css";
import "../../public/assets/css/plugins/animate.css";
import "../../public/assets/css/plugins/lightgallery.min.css";
import "../../public/assets/css/plugins/nice-select.css";
import "../../public/assets/css/style.css";
import SidebarPlugin from "../components/SidebarPlugin";

export default {
  install(app) {
    app.use(SidebarPlugin);
    app.use(GlobalComponents);
    app.use(GlobalDirectives);
  },
};
