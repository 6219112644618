export default {
  userId(state) {
    return state.user.id;
  },
  user(state) {
    return state.user;
  },
  token(state) {
    return state.token;
  },
  isAuthenticated(state) {
    return !!state.token;
  },
  didAutoLogout(state) {
    return state.didAutoLogout;
  },
};
