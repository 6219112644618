<template>
  <div class="main-wrapper">
    <website-header></website-header>
    <slot name="content"></slot>
    <website-footer></website-footer>
    <load-script></load-script>
  </div>
</template>

<script>
import LoadScript from "./LoadScript.vue";
import WebsiteHeader from "./WebsiteHeader.vue";
import WebsiteFooter from "./WebsiteFooter.vue";

export default {
  components: { LoadScript, WebsiteHeader, WebsiteFooter },
};
</script>
