<template>
  <div>
    <base-dialog :show="isLoading" title="Loading..." fixed>
      <base-spinner></base-spinner>
    </base-dialog>
    <base-dialog
      :show="pageErrors.length > 0 || successMsg !== ''"
      :title="alertTitle"
      @close="handleError"
    >
      <div v-if="pageErrors.length > 0 && successMsg === ''">
        <base-alert v-for="error in pageErrors" :key="error" type="danger">
          <span class="alert-inner--text">{{ error }}</span>
        </base-alert>
      </div>
      <div v-else>
        <base-alert type="success">
          <span class="alert-inner--text">{{ successMsg }}</span>
        </base-alert>
      </div>
    </base-dialog>
    <base-header
      class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
      style="
        min-height: 600px;
        background-image: url(img/theme/car-img.jpg);
        background-size: cover;
        background-position: center top;
      "
    >
      <!-- Mask -->
      <span class="mask bg-gradient-success opacity-8"></span>
      <!-- Header container -->
      <div class="container-fluid d-flex align-items-center">
        <div class="row">
          <div class="col-lg-7 col-md-10"></div>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <!-- <div class="col-xl-4 order-xl-2 mb-5 mb-xl-0"></div> -->

        <div class="col-xl-12 order-xl-1">
          <card shadow type="secondary">
            <template v-slot:header>
              <div class="bg-white border-0">
                <div class="row align-items-center">
                  <div class="col-8">
                    <h3 class="mb-0">Edit Part</h3>
                  </div>
                  <div class="col-4 text-right">
                    <a href="/admin-parts" class="btn btn-sm btn-primary"
                      >View All Parts</a
                    >
                    <a href="/admin-upload-parts" class="btn btn-sm btn-warning"
                      >Upload Excel</a
                    >
                  </div>
                </div>
              </div>
            </template>

            <Form
              @submit="onSubmit"
              v-if="hasPart"
              :validation-schema="schema"
              v-slot="{ errors }"
              enctype="multipart/form-data"
              id="editPartForm"
            >
              <h6 class="heading-small text-muted mb-4">Part Information</h6>
              <div class="pl-lg-4">
                <div class="row">
                  <div class="col-lg-6 form-group">
                    <label :class="{ 'error-show': errors.name }"
                      >Part Name</label
                    >
                    <Field
                      name="name"
                      class="form-control"
                      :class="{ 'is-invalid': errors.name }"
                      :value="part.name"
                      type="text"
                      @input="updatePartState('name', $event)"
                      placeholder="Part Name"
                    />
                    <div class="invalid-feedback">
                      {{ errors.name }}
                    </div>
                  </div>
                  <div class="col-lg-6 form-group">
                    <label :class="{ 'error-show': errors.oePartNumber }"
                      >Part Number</label
                    >
                    <Field
                      name="oePartNumber"
                      class="form-control"
                      :class="{ 'is-invalid': errors.oePartNumber }"
                      :value="part.oePartNumber"
                      type="text"
                      @input="updatePartState('oePartNumber', $event)"
                      placeholder="Part Number"
                    />
                    <div class="invalid-feedback">
                      {{ errors.oePartNumber }}
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6 form-group">
                    <label :class="{ 'error-show': errors.category }"
                      >Select Category</label
                    >
                    <Field
                      v-slot="{ field }"
                      name="category"
                      class="form-control"
                      :value="part.category"
                      @input="updatePartMState('category', $event)"
                    >
                      <Multiselect
                        v-bind="field"
                        placeholder="Select Category"
                        v-model="partCategory"
                        :searchable="true"
                        :options="categories"
                      />
                    </Field>
                    <div class="invalid-feedback">
                      {{ errors.category }}
                    </div>
                  </div>
                  <div class="col-lg-6 form-group">
                    <label :class="{ 'error-show': errors.subCategory }"
                      >Select Sub-category</label
                    >
                    <Field
                      v-slot="{ field }"
                      name="subCategory"
                      class="form-control"
                      :value="part.subCategory"
                      @input="updatePartMState('subCategory', $event)"
                    >
                      <Multiselect
                        v-bind="field"
                        placeholder="Select Sub-category"
                        v-model="part.subCategory"
                        mode="tags"
                        :closeOnSelect="false"
                        :searchable="true"
                        :options="sub_categories"
                      />
                    </Field>
                    <div class="invalid-feedback">
                      {{ errors.category }}
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6 form-group">
                    <label :class="{ 'error-show': errors.vehicleMake }"
                      >Select Vehicle Make</label
                    >
                    <Field
                      v-slot="{ field }"
                      name="vehicleMake"
                      class="form-control"
                      :value="part.vehicleMake"
                      @input="updatePartMState('vehicleMake', $event)"
                    >
                      <Multiselect
                        v-bind="field"
                        placeholder="Select Vehicle Make"
                        v-model="partMake"
                        :searchable="true"
                        :options="vehicleMakes"
                      />
                    </Field>
                    <div class="invalid-feedback">
                      {{ errors.vehicleMake }}
                    </div>
                  </div>
                  <div class="col-lg-6 form-group">
                    <label :class="{ 'error-show': errors.vehicleModel }"
                      >Select Vehicle Model</label
                    >
                    <Field
                      v-slot="{ field }"
                      name="vehicleModel"
                      class="form-control"
                      :value="part.vehicleModel"
                      @input="updatePartMState('vehicleModel', $event)"
                    >
                      <Multiselect
                        v-bind="field"
                        placeholder="Select Vehicle Model"
                        v-model="part.vehicleModel"
                        :searchable="true"
                        :options="vehicleModels"
                      />
                    </Field>
                    <div class="invalid-feedback">
                      {{ errors.vehicleModel }}
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6 form-group">
                    <label :class="{ 'error-show': errors.vehicleYear }"
                      >Vehicle Year</label
                    >
                    <Field
                      name="vehicleYear"
                      class="form-control"
                      :class="{ 'is-invalid': errors.vehicleYear }"
                      :value="part.vehicleYear"
                      type="text"
                      @input="updatePartState('vehicleYear', $event)"
                      placeholder="Vehicle Year"
                    />
                    <div class="invalid-feedback">
                      {{ errors.vehicleYear }}
                    </div>
                  </div>
                  <div class="col-lg-6 form-group">
                    <div v-if="loggedInUser.is_superuser" class="form-group">
                      <label :class="{ 'invalid-feedback': errors.service }"
                        >Select Service</label
                      >
                      <Field
                        v-slot="{ field }"
                        class="form-control"
                        :value="partCp.service"
                        @input="updatePartMState('service', $event)"
                        name="service"
                      >
                        <Multiselect
                          v-bind="field"
                          v-model="partCp.service"
                          placeholder="Select Service"
                          :searchable="true"
                          :options="services"
                        />
                      </Field>
                      <div class="invalid-feedback">
                        {{ errors.service }}
                      </div>
                    </div>
                    <div v-else class="form-group">
                      <label :class="{ 'error-show': errors.service }"
                        >Select Service</label
                      >
                      <Field
                        disabled
                        name="service"
                        :value="part.service"
                        class="form-control"
                        placeholder="Service"
                      >
                      </Field>
                      <div class="invalid-feedback">
                        {{ errors.service }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6 form-group">
                    <label :class="{ 'error-show': errors.type }"
                      >Select Type</label
                    >
                    <Field
                      v-slot="{ field }"
                      name="type"
                      :value="part.type"
                      @input="updatePartMState('type', $event)"
                      class="form-control"
                    >
                      <Multiselect
                        v-bind="field"
                        v-model="part.type"
                        placeholder="Select Type"
                        value="oe"
                        :options="[
                          { value: 'oe', label: 'OE' },
                          { value: 'refurbished', label: 'Refurbished' },
                          { value: 'alternative', label: 'Alternative' },
                          { value: 'used', label: 'Used' },
                        ]"
                      />
                    </Field>
                    <div class="invalid-feedback">
                      {{ errors.type }}
                    </div>
                  </div>
                  <div class="col-lg-6 form-group">
                    <label
                      :class="{ 'error-show': errors.alternativePartNumber }"
                      ><strong>Alternative Part Number</strong></label
                    >
                    <Field
                      name="alternativePartNumber"
                      class="form-control"
                      :class="{ 'is-invalid': errors.alternativePartNumber }"
                      :value="part.alternativePartNumber"
                      @input="updatePartState('alternativePartNumber', $event)"
                      type="text"
                      placeholder="Alternative Part Number"
                    />
                    <div class="invalid-feedback">
                      {{ errors.alternativePartNumber }}
                    </div>
                  </div>
                  <!-- <div class="col-lg-6 form-group">
                    <label :class="{ 'error-show': errors.partType }"
                      >Select Part Type</label
                    >
                    <Field
                      v-slot="{ field }"
                      name="partType"
                      class="form-control"
                      :value="part.partType"
                      @input="updatePartMState('partType', $event)"
                    >
                      <Multiselect
                        v-bind="field"
                        v-model="part.partType"
                        placeholder="Select Part Type"
                        :searchable="true"
                        :options="partTypes"
                      />
                    </Field>
                    <div class="invalid-feedback">
                      {{ errors.partType }}
                    </div>
                  </div> -->
                  <div class="col-lg-12 form-group">
                    <label :class="{ 'error-show': errors.description }"
                      >Part Description</label
                    >
                    <Field
                      as="textarea"
                      v-model="part.description"
                      name="description"
                      class="form-control"
                      rows="4"
                      placeholder="Part Description..."
                    >
                    </Field>
                    <div
                      class="invalid-feedback"
                      :style="
                        errors.description
                          ? { display: 'block' }
                          : { display: 'none' }
                      "
                    >
                      {{ errors.description }}
                    </div>
                  </div>
                  <!-- <div class="col-lg-12 form-group">
                    <label :class="{ 'error-show': errors.categories }"
                      >Tags</label
                    >
                    <Field
                      v-slot="{ field }"
                      name="tags"
                      class="form-control"
                      :value="part.tags"
                      @input="updatePartMState('tags', $event)"
                    >
                      <Multiselect
                        v-model="part.tags"
                        mode="tags"
                        :closeOnSelect="false"
                        :searchable="true"
                        :createTag="true"
                        v-bind="field"
                        :options="tagOptions"
                      />
                    </Field>
                    <div class="invalid-feedback">
                      {{ errors.tags }}
                    </div>
                  </div> -->
                </div>
              </div>
              <hr class="my-4" />
              <!-- Address -->
              <h6 class="heading-small text-muted mb-4">Part Pricing</h6>
              <div class="pl-lg-4">
                <div class="row">
                  <div class="col-lg-6 form-group">
                    <label :class="{ 'error-show': errors.quantity }"
                      >Part Quantity</label
                    >
                    <Field
                      name="quantity"
                      class="form-control"
                      :class="{ 'is-invalid': errors.quantity }"
                      v-model="part.quantity"
                      type="number"
                      min="0"
                      @input="updatePartState('quantity', $event)"
                      placeholder="Part Quantity"
                    />
                    <div class="invalid-feedback">
                      {{ errors.quantity }}
                    </div>
                  </div>
                  <div class="col-lg-6 form-group">
                    <label :class="{ 'error-show': errors.discount }"
                      >Part Discount</label
                    >
                    <Field
                      name="discount"
                      class="form-control"
                      :class="{ 'is-invalid': errors.discount }"
                      v-model="part.discount"
                      type="number"
                      min="0"
                      max="100"
                      @input="updatePartState('discount', $event)"
                      placeholder="Part Discount"
                    />
                    <div class="invalid-feedback">
                      {{ errors.discount }}
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6 form-group">
                    <label :class="{ 'error-show': errors.price }"
                      >Part Price</label
                    >
                    <Field
                      name="price"
                      class="form-control"
                      :class="{ 'is-invalid': errors.price }"
                      v-model="part.price"
                      type="number"
                      min="0.01"
                      @input="updatePartState('price', $event)"
                      placeholder="Part Price"
                    />
                    <div class="invalid-feedback">
                      {{ errors.price }}
                    </div>
                  </div>
                  <!-- <div class="col-lg-6 form-group">
                    <label :class="{ 'error-show': errors.oldprice }"
                      >Part Oldprice</label
                    >
                    <Field
                      name="oldprice"
                      class="form-control"
                      :class="{ 'is-invalid': errors.oldprice }"
                      v-model="part.oldprice"
                      type="number"
                      min="0.01"
                      @input="updatePartState('oldprice', $event)"
                      placeholder="Part Oldprice"
                    />
                    <div class="invalid-feedback">
                      {{ errors.oldprice }}
                    </div>
                  </div> -->
                </div>
              </div>
              <div class="pl-lg-4">
                <base-button
                  type="primary"
                  nativeType="submit"
                  block
                  icon="ni ni-bag-17"
                  >Save Part Information</base-button
                >
              </div>
              <hr class="my-4" />
              <!-- Description -->
              <h6 class="heading-small text-muted mb-4">Part Images</h6>
              <div class="pl-lg-4">
                <div class="col-lg-12 form-group">
                  <label :class="{ 'error-show': errors.images }"
                    >Part Media</label
                  >
                  <Field v-slot="{ field }" name="images" class="form-control">
                    <UploadImages
                      uploadMsg="Add Part Images"
                      class="form-control"
                      v-bind="field"
                      @changed="onChange"
                    />
                  </Field>
                  <div
                    class="invalid-feedback"
                    :style="
                      errors.images ? { display: 'block' } : { display: 'none' }
                    "
                  >
                    {{ errors.images }}
                  </div>
                </div>
              </div>
              <!-- <div class="pl-lg-4">
                <base-button
                  type="primary"
                  nativeType="submit"
                  block
                  icon="ni ni-bag-17"
                  >Save Part</base-button
                >
              </div> -->
            </Form>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Field, Form } from "vee-validate";
import Multiselect from "@vueform/multiselect";
import UploadImages from "vue-upload-drop-images";
import * as yup from "yup";

export default {
  components: { Form, Field, Multiselect, UploadImages },
  data() {
    return {
      isLoading: false,
      tagOptions: [
        { value: "new", label: "New" },
        { value: "hot", label: "Hot" },
        { value: "exclusive", label: "Exclusive" },
      ],
      pageErrors: [],
      successMsg: "",
      images: [],
      partCategory: null,
      partMake: null,
    };
  },
  computed: {
    typeValid() {
      return this.part.type !== null;
    },
    alertTitle() {
      if (this.pageErrors.length > 0 && this.successMsg === "") {
        return this.pageErrors.length === 1
          ? "An Error has occurred"
          : "A couple of errors have occured";
      } else {
        return "Details Update";
      }
    },
    serviceValid() {
      return this.part.service !== null;
    },
    partTypeValid() {
      return this.part.partType !== null;
    },
    categoriesValid() {
      return this.part.categories.length > 0;
    },
    schema() {
      const errormsg =
        "must be a `number` type, but the final value was: `NaN`. Enter 0 if no value";
      const schema = yup.object({
        name: yup.string().required(),
        oePartNumber: yup.string(),
        alternativePartNumber: yup.string().nullable(),
        vehicleMake: yup.string().required(),
        vehicleModel: yup.string(),
        vehicleYear: yup.string(),
        quantity: yup
          .number()
          .default(1)
          .typeError("Quantity " + errormsg)
          .required()
          .min(1),
        discount: yup
          .number()
          .default(0)
          .typeError("Discount " + errormsg)
          .min(0)
          .max(100),
        price: yup.string().typeError("Price " + errormsg), //.min(0),
        type: yup.string().required(),
        // partType: yup.string().required(),
        service: yup.string().required(),
        category: yup.string().required(),
        subCategory: yup.array().min(1).required(),
        // images: yup.array().min(1),
        description: yup.string().required(),
      });

      return schema;
    },
    categories() {
      const cats = [];
      const categories = this.$store.getters["categories/categories"];
      for (let index = 0; index < categories.length; index++) {
        let element = {
          label: categories[index].name,
          value: categories[index].id,
        };
        cats.push(element);
      }
      return cats;
    },
    sub_categories() {
      const cats = [];
      const categories = this.$store.getters["categories/subCategories"];
      for (let index = 0; index < categories.length; index++) {
        let element = {
          label: categories[index].name,
          value: categories[index].id,
        };
        cats.push(element);
      }
      return cats;
    },
    vehicleMakes() {
      const makes = [];
      const vMakes = this.$store.getters["categories/vehicleMakes"];
      for (let index = 0; index < vMakes.length; index++) {
        let element = {
          label: vMakes[index].name,
          value: vMakes[index].id,
        };
        makes.push(element);
      }
      return makes;
    },
    vehicleModels() {
      const models = [];
      const vModels = this.$store.getters["categories/vehicleModels"];
      for (let index = 0; index < vModels.length; index++) {
        let element = {
          label: vModels[index].name,
          value: vModels[index].id,
        };
        models.push(element);
      }
      return models;
    },
    partTypes() {
      const partTypes = [];
      const pTypes = this.$store.getters["categories/partTypes"];
      for (let index = 0; index < pTypes.length; index++) {
        let element = { label: pTypes[index].name, value: pTypes[index].id };
        partTypes.push(element);
      }
      return partTypes;
    },
    services() {
      const services = [];
      const servArr = this.$store.getters["services/services"];
      for (let index = 0; index < servArr.length; index++) {
        let element = { label: servArr[index].name, value: servArr[index].id };
        services.push(element);
      }
      return services;
    },
    service() {
      return this.$store.getters["services/service"];
    },
    part() {
      return this.$store.getters["parts/part"];
    },
    hasPart() {
      return this.$store.getters["parts/hasPart"];
    },
    isLoggedIn() {
      return this.$store.getters.isAuthenticated;
    },
    loggedInUser() {
      return this.$store.getters.user;
    },
    loggedInUserId() {
      return this.$store.getters.userId;
    },
  },
  async created() {
    if (this.loggedInUser.is_superuser) {
      this.loadServices();
    } else {
      //   this.part.service = this.loggedInUserId;
      this.loadService();
    }

    await this.loadPart();
    this.loadSubCategories(this.part.category);
    this.partCategory = this.part.category;
    this.partMake = this.part.vehicleMake;
    this.loadCategories();

    this.loadVehicleMakes();
    this.loadVehicleModels(this.part.vehicleMake);
    this.loadPartTypes();
  },
  methods: {
    //this.params.search = this.$route.params.q;
    onChange(files) {
      this.part.images = files;
      // console.log(this.part.images);
    },
    async loadServices() {
      this.isLoading = true;
      try {
        await this.$store.dispatch("services/loadServices");
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;
    },
    async loadPart() {
      this.isLoading = true;
      try {
        await this.$store.dispatch("parts/getPart", {
          id: this.$route.params.id,
        });
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;
    },
    async loadService() {
      this.isLoading = true;
      try {
        await this.$store.dispatch("services/getService", {
          serviceId: this.loggedInUser.id,
        });
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;
    },
    async loadSubCategories(catId) {
      this.isLoading = true;
      try {
        await this.$store.dispatch("categories/loadSubCategories", {
          params: {
            category: catId,
          },
        });
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;
    },
    async loadCategories() {
      this.isLoading = true;
      try {
        await this.$store.dispatch("categories/loadCategories");
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;
    },
    async loadVehicleMakes() {
      this.isLoading = true;
      try {
        await this.$store.dispatch("categories/loadVehicleMakes");
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;
    },
    async loadVehicleModels(makeId) {
      this.isLoading = true;
      try {
        await this.$store.dispatch("categories/loadVehicleModels", {
          params: {
            make: makeId,
          },
        });
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;
    },
    async loadPartTypes() {
      this.isLoading = true;
      try {
        await this.$store.dispatch("categories/loadPartTypes");
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;
    },
    async onSubmit() {
      this.isLoading = true;
      try {
        const success = await this.$store.dispatch("parts/updatePartInfo", {
          part: this.part,
          slug: this.part.id,
        });
        if (success) {
          this.successMsg = "Part has been successfully updated.";
          // this.resetForm();
        }
      } catch (error) {
        console.log(error.response.data);
        if (error.response.data.non_field_errors) {
          this.pageErrors = error.response.data.non_field_errors;
        } else {
          for (const key in error.response.data) {
            this.pageErrors.push(
              error.response.data[key][0] + " (" + key.toUpperCase() + ")"
            );
          }
        }
      }
      this.isLoading = false;
    },
    handleError() {
      this.pageErrors = [];
      this.successMsg = "";
    },
    updatePartMState(prop, value) {
      value = { target: { value: value } };
      this.updatePartState(prop, value);
    },
    updatePartState(prop, value) {
      value = value.target.value;
      this.$store.dispatch("parts/updatePart", { prop, value }).catch(() => {
        console.log("Could not update part data");
      });
    },
  },
  watch: {
    partCategory: function (newValue, oldValue) {
      this.partCategory = newValue;
      if (oldValue !== null) {
        this.loadSubCategories(newValue);
        this.updatePartMState("subCategory", []);
      }
    },
    partMake: function (newValue, oldValue) {
      this.partMake = newValue;
      // this.part.vehicleModel = null;
      if (oldValue !== null) {
        this.loadVehicleModels(newValue);
        this.updatePartMState("vehicleModel", null);
      }
    },
  },
};
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped>
.error-show {
  color: #fb6340;
}
</style>
