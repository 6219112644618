export default {
  parts(state) {
    return state.parts;
  },

  part(state) {
    return state.part;
  },

  hasParts(state) {
    return state.parts.results && state.parts.results.length > 0;
  },
  hasPart(state) {
    return state.part !== null;
  },
};
