import subscriptionService from "../../../services/subscription.service";

export default {
  async addSubscription(context, data) {
    // const userId = context.rootGetters.userId;

    const token = context.rootGetters.token;
    const response = await subscriptionService.createSubcription(
      token,
      data.form
    );
    console.log(response);

    // context.commit("addPart", {
    //   ...coachData,
    //   id: userId,
    // });
  },

  async loadSubscriptions(context) {
    // if (!payload.forceRefresh && !context.getters.shouldUpdate) {
    //   return;
    // }

    const token = context.rootGetters.token;
    const response = await subscriptionService.getSubcriptions(token);

    context.commit("setSubscriptions", response.data);
    // context.commit("setFetchTimeStamp");
  },

  async getSubscription(context, payload) {
    const token = context.rootGetters.token;
    const response = await subscriptionService.getSubcription(
      token,
      payload.serviceId
    );

    console.log(response);

    // context.commit("setSubscription", response.data);
  },
};
