import advertsService from "../../../services/adverts.service";
import advertService from "../../../services/adverts.service";

export default {
  async addAdvert(context, data) {
    // const userId = context.rootGetters.userId;

    const token = context.rootGetters.token;
    const response = await advertService.createAdvert(token, data.form);
    console.log(response);

    context.commit("addAdvert", response.data);
    return true;
  },

  async loadAdverts(context, payload = null) {
    // use to filter
    let params = "?";
    if (payload !== null && payload.serviceSlug) {
      params = params + `serviceSlug=${payload.serviceSlug}`;
    }
    const token = context.rootGetters.token;
    const loadpay = payload !== null ? params : "";
    const response = await advertsService.getAdverts(token, loadpay);
    console.log(response.data);

    context.commit("setAdverts", response.data);
  },
};
