<template>
  <base-wrapper-custom>
    <template v-slot:content>
      <popular-search></popular-search>
      <first-slider :slides="slides"></first-slider>
      <!-- Begin Uren's Shipping Area -->
      <!-- <div class="uren-shipping_area">
        <div class="container-fluid">
          <div class="shipping-nav">
            <div class="row no-gutters">
              <div class="shipping-grid">
                <div class="shipping-item">
                  <div class="shipping-icon">
                    <i class="ion-ios-paperplane-outline"></i>
                  </div>
                  <div class="shipping-content">
                    <h6>Customer Satisfation</h6>
                    <p>Our customer needs are important</p>
                  </div>
                </div>
              </div>
              <div class="shipping-grid">
                <div class="shipping-item">
                  <div class="shipping-icon">
                    <i class="ion-ios-help-outline"></i>
                  </div>
                  <div class="shipping-content">
                    <h6>Support 24/7</h6>
                    <p>Contact us 24 hours a day</p>
                  </div>
                </div>
              </div>
              <div class="shipping-grid">
                <div class="shipping-item">
                  <div class="shipping-icon">
                    <i class="ion-ios-refresh-empty"></i>
                  </div>
                  <div class="shipping-content">
                    <h6>All you requirements</h6>
                    <p>Find all your parts here</p>
                  </div>
                </div>
              </div>
              <div class="shipping-grid">
                <div class="shipping-item">
                  <div class="shipping-icon">
                    <i class="ion-ios-undo-outline"></i>
                  </div>
                  <div class="shipping-content">
                    <h6>Have your say</h6>
                    <p>Rate all the services</p>
                  </div>
                </div>
              </div>
              <div class="shipping-grid">
                <div class="shipping-item">
                  <div class="shipping-icon">
                    <i class="ion-ios-locked-outline"></i>
                  </div>
                  <div class="shipping-content last-child">
                    <h6>Meet your service providers</h6>
                    <p>Have peace of mind</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <!-- Uren's Shipping Area End Here -->
      <product-area-home
        v-if="parts"
        :products="splitSlideArray(0)"
        :showHeading="true"
      ></product-area-home>
      <product-area-home
        v-if="parts && splitSlideArray(1).length > 0"
        :products="splitSlideArray(1)"
      ></product-area-home>
      <product-area-home
        v-if="parts && splitSlideArray(2).length > 0"
        :products="splitSlideArray(2)"
      ></product-area-home>
      <advert-banner-big
        v-if="adsBig3.length > 0 && adsBig3.length === 2"
        :first_ad="adsBig3[0]"
        :second_ad="adsBig3[1]"
      ></advert-banner-big>
      <advert-banner-big
        v-if="adsBig.length > 0 && adsBig.length === 2"
        :first_ad="adsBig[0]"
        :second_ad="adsBig[1]"
      ></advert-banner-big>
      <advert-banner-big
        v-if="adsBig2.length > 0 && adsBig2.length === 2"
        :first_ad="adsBig2[0]"
        :second_ad="adsBig2[1]"
      ></advert-banner-big>

      <!-- Begin Uren's Banner Three Area -->
      <div class="uren-banner_area uren-banner_area-3">
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-12">
              <div class="banner-item img-hover_effect">
                <div class="banner-img"></div>
                <div class="banner-content">
                  <span class="contact-info"
                    ><i class="ion-android-call"></i> +2778 893 4978</span
                  >
                  <h4>Anytime & Anywhere</h4>
                  <!-- <h3>You are.</h3> -->
                  <p>
                    Let us know what service you need and we can help! Waiting
                    for your call...
                  </p>
                  <a href="javascript:void(0)" class="read-more">Learn More</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Uren's Banner Three Area End Here -->

      <!-- Begin Uren's Banner Three Area -->
      <div class="uren-banner_area uren-banner_area-3">
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-12">
              <div class="banner-item img-hover_effect">
                <div style="height: 590px; overflow: scroll">
                  <div
                    class="banner-img"
                    style="
                      background-image: url(../../../img/map.png);
                      min-width: 1400px;
                      min-height: 1200px;
                    "
                  ></div>
                </div>
                <!-- <div class="banner-content">
                  <span class="contact-info"
                    ><i class="ion-android-call"></i> +2778 893 4978</span
                  >
                  <h4>Anytime & Anywhere</h4>
                  <p>
                    Let us know what service you need and we can help! Waiting
                    for your call...
                  </p>
                  <a href="javascript:void(0)" class="read-more">Learn More</a>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Uren's Banner Three Area End Here -->
      <partner-brand :partners="[]"></partner-brand>
      <page-loader v-if="isLoading"></page-loader>
      <load-script v-if="test"></load-script>
    </template>
  </base-wrapper-custom>
</template>

<script>
import PopularSearch from "../../components/website/PopularSearch.vue";
import FirstSlider from "../../components/website/FirstSlider.vue";
import AdvertBannerBig from "../../components/website/AdvertBannerBig.vue";
import ProductAreaHome from "../../components/website/ProductAreaHome.vue";
import PartnerBrand from "../../components/website/PartnerBrand.vue";
import BaseWrapperCustom from "../../components/website/BaseWrapperCustom.vue";
import PageLoader from "../../components/website/PageLoader.vue";
import LoadScript from "../../components/website/LoadScript.vue";
import _ from "lodash";

export default {
  components: {
    PopularSearch,
    FirstSlider,
    BaseWrapperCustom,
    AdvertBannerBig,
    ProductAreaHome,
    PartnerBrand,
    PageLoader,
    LoadScript,
  },
  data() {
    return {
      isLoading: false,
      test: false,
      slides: [
        {
          image: "../assets/images/slider/1.jpg",
          title:
            "Our goal is to create a digital marketplace where by consumers in the automotive industry can find parts and reliable/affordable services in the area.",
          content:
            "<h3>Car interior</h3><h4>Starting at <span>$99.00</span></h4>",
          url: "/",
        },
        {
          image: "../assets/images/slider/2.jpg",
          title: "Cars, Trucks, Bikes & Trailers",
          content: "<h3>Wheels & Tires</h3><h4>Sale up to 20% off</h4>",
          url: "/",
        },
      ],
      partes: [
        {
          id: 1,
          images: [
            {
              id: 1,
              get_image: "http://127.0.0.1:8000/media/uploads/parts/1-1.jpg",
            },
            {
              id: 2,
              get_image: "http://127.0.0.1:8000/media/uploads/parts/1-2.jpg",
            },
          ],
          name: "Wheels and Tyres",
          price: "100",
          oldprice: "0",
          discount: "0",
          tags: ["new"],
        },
      ],
      adsBig: [
        {
          ad_image: "../../assets/images/banner/1-1.jpg",
          ad_heading: "Standard Package",
          title: "Cost Per Month:",
          subtitle: "R250",
          description: "Unlimited parts listings - Wish list notifications",
          company_url: "/login",
          btn_text: "Subscribe",
        },
        {
          ad_image: "../../assets/images/banner/1-2.jpg",
          ad_heading: "Premium Package",
          title: "Cost Per Month",
          subtitle: "R550",
          description:
            "Unlimited parts listings - Parts trends analysis - Wish list notifications - Guided service histories - Promoted listing on Facebook/Instagrams - Services subscription - Stage 2 – Service history - Advertise Services",
          company_url: "/login",
          btn_text: "Subscribe",
        },
      ],
      adsBig2: [
        {
          ad_image: "../../assets/images/banner/1-2.jpg",
          ad_heading: "Roll Bar Advertising",
          title: "Cost Per Month",
          subtitle: "Contact us for pricing",
          description: "Roll Bar Advertising",
          company_url: "/contact",
          btn_text: "Contact us",
        },
        {
          ad_image: "../../assets/images/banner/2-1.jpg",
          ad_heading: "",
          title: "",
          subtitle: "",
          description: "",
          company_url: "",
          btn_text: "",
        },
      ],
      adsBig3: [
        {
          ad_image: "../../assets/images/banner/lim.jpg",
          ad_heading: "",
          title: "",
          subtitle: "",
          description: "",
          company_url: "",
          btn_text: "",
        },
        {
          ad_image: "../../assets/images/banner/lim2.jpg",
          ad_heading: "",
          title: "",
          subtitle: "",
          description: "",
          company_url: "",
          btn_text: "",
          
        },
      ],
    };
  },
  computed: {
    parts() {
      return this.$store.getters["parts/parts"];
    },
    hasParts() {
      return !this.isLoading && this.$store.getters["parts/hasParts"];
    },
    isLoggedIn() {
      return this.$store.getters.isAuthenticated;
    },
  },
  methods: {
    async loadParts() {
      this.isLoading = true;
      try {
        await this.$store.dispatch("parts/loadPartsWeb", {
          params: {
            page_size: 18,
          },
        });
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;
    },
    splitSlideArray(index) {
      const arraysplit = _.chunk(this.parts.results, 6);
      if (typeof arraysplit[index] === "undefined") {
        // index = 0;
        return [];
      }
      return arraysplit[index];
    },
  },
  created() {
    document.title = "PSI Link | Homepage";
    this.loadParts().then(() => {
      (this.test = true), console.log("SUCCESS");
    });
  },
};
</script>
