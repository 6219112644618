<template>
  <div class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
    <div
      class="card-header border-0"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
            {{ title }}
          </h3>
        </div>
        <div class="col text-right">
          <a href="/admin-add-advert" class="btn btn-sm btn-primary"
            >Add Advert</a
          >
          <!-- <a href="/admin-dashboard" class="btn btn-sm btn-warning"
            >Import Advert</a
          > -->
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <base-table
        class="table align-items-center table-flush"
        :class="type === 'dark' ? 'table-dark' : ''"
        :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
        tbody-classes="list"
        :data="tableData"
      >
        <template v-slot:columns>
          <th>Advert</th>
          <th>Period</th>
          <th>Date Start</th>
          <th>Date End</th>
          <th>Advert Type</th>
          <th></th>
        </template>

        <template v-slot:default="row">
          <th scope="row">
            <div class="media align-items-center">
              <a href="#" class="avatar rounded-circle mr-3">
                <img alt="Image placeholder" :src="row.item.get_image" />
              </a>
              <div class="media-body">
                <span class="name mb-0 text-sm">{{ row.item.name }}</span>
              </div>
            </div>
          </th>
          <td class="budget">{{ row.item.period }} days</td>
          <td class="budget">{{ row.item.startDate }}</td>
          <td class="budget">
            {{ row.item.endDate }}
          </td>

          <td>
            <div class="d-flex align-items-center">
              <span class="completion mr-2">{{
                row.item.advertType.toUpperCase()
              }}</span>
            </div>
          </td>

          <td class="text-right">
            <base-dropdown class="dropdown" position="right">
              <template v-slot:title>
                <a
                  class="btn btn-sm btn-icon-only text-light"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i class="fas fa-ellipsis-v"></i>
                </a>
              </template>

              <template v-slot:default>
                <a class="dropdown-item" href="#"
                  ><i class="fa fa-edit"></i>Edit</a
                >
                <a class="dropdown-item" href="#"
                  ><i class="fa fa-trash"></i>Delete</a
                >
              </template>
            </base-dropdown>
          </td>
        </template>
      </base-table>
    </div>

    <div
      class="card-footer d-flex justify-content-end"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <base-pagination :total="tableData.length"></base-pagination>
    </div>
  </div>
</template>
<script>
export default {
  name: "parts-table",
  props: {
    type: {
      type: String,
    },
    tableData: {
      type: Object,
      default: () => {},
    },
    title: String,
  },
  data() {
    return {
      // tableData: [
      //   {
      //     img: "img/theme/bootstrap.jpg",
      //     title: "Argon Design System",
      //     budget: "$2500 USD",
      //     status: "pending",
      //     statusType: "warning",
      //     completion: 60,
      //   },
      //   {
      //     img: "img/theme/angular.jpg",
      //     title: "Angular Now UI Kit PRO",
      //     budget: "$1800 USD",
      //     status: "completed",
      //     statusType: "success",
      //     completion: 100,
      //   },
      //   {
      //     img: "img/theme/sketch.jpg",
      //     title: "Black Dashboard",
      //     budget: "$3150 USD",
      //     status: "delayed",
      //     statusType: "danger",
      //     completion: 72,
      //   },
      //   {
      //     img: "img/theme/react.jpg",
      //     title: "React Material Dashboard",
      //     budget: "$4400 USD",
      //     status: "on schedule",
      //     statusType: "info",
      //     completion: 90,
      //   },
      //   {
      //     img: "img/theme/vue.jpg",
      //     title: "Vue Paper UI Kit PRO",
      //     budget: "$2200 USD",
      //     status: "completed",
      //     statusType: "success",
      //     completion: 100,
      //   },
      // ],
    };
  },
};
</script>
<style></style>
