export default {
  categories(state) {
    return state.categories;
  },

  subCategories(state) {
    return state.sub_categories;
  },

  vehicleMakes(state) {
    return state.vehicleMakes;
  },

  vehicleModels(state) {
    return state.vehicleModels;
  },

  partTypes(state) {
    return state.partTypes;
  },

  hasCategories(state) {
    return state.categories && state.categories.length > 0;
  },

  hasSubCategories(state) {
    return state.sub_categories && state.sub_categories.length > 0;
  },

  hasVehicleMakes(state) {
    return state.vehicleMakes && state.vehicleMakes.length > 0;
  },

  hasVehicleModels(state) {
    return state.vehicleModels && state.vehicleModels.length > 0;
  },

  hasPartTypes(state) {
    return state.partTypes && state.partTypes.length > 0;
  },
};
