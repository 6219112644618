import axios from "axios";

const API_URL = process.env.VUE_APP_API_URL + "users/";

class UserService {
  getConfig(token) {
    return {
      headers: { Authorization: `Token ${token}` },
    };
  }

  async getMe(token) {
    return await axios.get(API_URL + "me/", this.getConfig(token));
  }

  async update(token, user) {
    return await axios.patch(API_URL + "me/", user, this.getConfig(token));
  }

  async activateEmail(activation) {
    return await axios.post(API_URL + "activation/", activation);
  }

  async resendActivateEmail(activation) {
    return await axios.post(API_URL + "resend_activation/", activation);
  }

  async resetPassword(email) {
    return await axios.post(API_URL + "reset_password/", email);
  }

  async changePassword(reset) {
    return await axios.post(API_URL + "reset_password_confirm/", reset);
  }
}

export default new UserService();
