export default {
  addWishlists(state, payload) {
    state.wishlists.push(payload);
  },
  setWishlists(state, payload) {
    state.wishlists = payload;
  },
  setWishlist(state, payload) {
    state.wishlist = payload;
  },
  updateWishist(state, payload) {
    state.wishlist[payload.prop] = payload.value;
  },
  removePartWishist(state, payload) {
    state.wishlist.parts = state.wishlist.parts.filter(
      (part) => part.id !== payload.partId
    );
  },
};
