export default {
  addPart(state, payload) {
    if (!state.parts.results) {
      state.parts.results = [];
    }
    state.parts.results.push(payload);
  },
  setParts(state, payload) {
    state.parts = payload;
  },
  setPart(state, payload) {
    state.part = payload;
  },
  deletePart(state, payload) {
    const p = state.parts.results;
    let index = p.findIndex((e) => e.id === payload);
    p.splice(index, 1);
    state.parts.results = p;
  },
  updatePart(state, payload) {
    state.part[payload.prop] = payload.value;
  },
};
