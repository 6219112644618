import axios from "axios";

const API_URL = process.env.VUE_APP_API_URL;

class AuthService {
  async login(user) {
    return await axios.post(API_URL + "token/login", {
      username: user.username,
      password: user.password,
    });
  }

  //   logout() {
  //     localStorage.removeItem("user");
  //   }

  async register(user) {
    return await axios.post(API_URL + "users/", user);
  }

  async contactUsPost(contact) {
    return await axios.post(API_URL + "contact-us-add/", contact);
  }
}

export default new AuthService();
