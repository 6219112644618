export default {
  addSubscriptions(state, payload) {
    state.subscriptions.push(payload);
  },
  setSubscriptions(state, payload) {
    state.subscriptions = payload;
  },
  setSubscription(state, payload) {
    state.subscription = payload;
  },
};
