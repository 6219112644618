<template>
  <!-- Begin Popular Search Area -->
  <div class="popular-search_area">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-12">
          <div class="popular-search">
            <label>Popular Search:</label>
            <a href="javascript:void(0)">Brakes & Rotors,</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Popular Search Area End Here -->
</template>
<script>
export default {};
</script>
