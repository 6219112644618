<template>
  <base-wrapper>
    <template v-slot:content>
      <page-loader v-if="isLoading"></page-loader>
      <!-- Begin Uren's Breadcrumb Area -->
      <div class="breadcrumb-area">
        <div class="container">
          <div class="breadcrumb-content">
            <ul>
              <li><a href="/">Home</a></li>
              <li class="active">Login | Register</li>
            </ul>
          </div>
        </div>
      </div>
      <!-- Uren's Breadcrumb Area End Here -->
      <!-- Begin Uren's Login Register Area -->
      <div class="uren-login-register_area">
        <div class="container-fluid">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-xs-12 col-lg-6">
              <!-- Login Form s-->
              <Form
                @submit="onSubmitLogin"
                :validation-schema="schema"
                v-slot="{ errors }"
              >
                <div class="login-form">
                  <base-alert
                    v-if="loginErrors.length > 0"
                    type="danger"
                    dismissible
                  >
                    <!-- <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span> -->
                    <span
                      class="alert-inner--text"
                      v-for="error in loginErrors"
                      :key="error"
                      >{{ error }}</span
                    >
                    <button
                      type="button"
                      class="close"
                      data-dismiss="alert"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </base-alert>
                  <h4 class="login-title">Login</h4>
                  <div class="row">
                    <div class="col-md-12 col-12 form-group">
                      <label :class="{ 'invalid-feedback': errors.username }"
                        >Username*</label
                      >
                      <Field
                        type="text"
                        name="username"
                        class="form-control"
                        autocomplete="on"
                        v-model="login.username"
                        :class="{ 'is-invalid': errors.username }"
                        placeholder="Username"
                      />
                      <div class="invalid-feedback">{{ errors.username }}</div>
                    </div>
                    <div class="col-12 form-group mb--20">
                      <label :class="{ 'invalid-feedback': errors.password }"
                        >Password*</label
                      >
                      <Field
                        type="password"
                        v-model="login.password"
                        autocomplete="new-password"
                        class="form-control"
                        name="password"
                        :class="{ 'is-invalid': errors.password }"
                        placeholder="Password"
                      />
                      <div class="invalid-feedback">{{ errors.password }}</div>
                    </div>
                    <div class="col-md-12">
                      <div class="forgotton-password_info">
                        <a href="/forgot-password"> Forgotten password?</a>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <button class="uren-login_btn">Login</button>
                    </div>
                  </div>
                </div>
              </Form>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6 col-xs-12">
              <Form
                @submit="onSubmitSignup"
                :validation-schema="schemaSignup"
                v-slot="{ errors }"
              >
                <div class="login-form">
                  <base-alert
                    v-if="signupErrors.length > 0"
                    type="danger"
                    dismissible
                  >
                    <!-- <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span> -->
                    <span
                      class="alert-inner--text"
                      v-for="error in signupErrors"
                      :key="error"
                      >{{ error }}</span
                    >
                    <button
                      type="button"
                      class="close"
                      data-dismiss="alert"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </base-alert>
                  <h4 class="login-title">Register</h4>
                  <div class="row">
                    <div class="col-md-6 col-12 form-group mb--20">
                      <label :class="{ 'invalid-feedback': errors.first_name }"
                        >First Name</label
                      >
                      <Field
                        name="first_name"
                        class="form-control"
                        :class="{ 'is-invalid': errors.first_name }"
                        v-model="user.first_name"
                        type="text"
                        placeholder="First Name"
                      />
                      <div class="invalid-feedback">
                        {{ errors.first_name }}
                      </div>
                    </div>
                    <div class="col-md-6 col-12 form-group mb--20">
                      <label :class="{ 'invalid-feedback': errors.last_name }"
                        >Last Name</label
                      >
                      <Field
                        name="last_name"
                        class="form-control"
                        :class="{ 'is-invalid': errors.last_name }"
                        v-model="user.last_name"
                        type="text"
                        placeholder="Last Name"
                      />
                      <div class="invalid-feedback">{{ errors.last_name }}</div>
                    </div>
                    <div class="col-md-12 form-group">
                      <label :class="{ 'invalid-feedback': errors.username }"
                        >Username*</label
                      >
                      <Field
                        name="username"
                        class="form-control"
                        :class="{ 'is-invalid': errors.username }"
                        v-model="user.username"
                        type="text"
                        placeholder="Username"
                      />
                      <div class="invalid-feedback">{{ errors.username }}</div>
                    </div>
                    <div class="col-md-12 form-group">
                      <label :class="{ 'invalid-feedback': errors.email }"
                        >Email Address*</label
                      >
                      <Field
                        name="email"
                        class="form-control"
                        :class="{ 'is-invalid': errors.email }"
                        v-model="user.email"
                        type="email"
                        placeholder="Email Address"
                      />
                      <div class="invalid-feedback">{{ errors.email }}</div>
                    </div>
                    <div class="col-md-6 form-group">
                      <label :class="{ 'invalid-feedback': errors.password }"
                        >Password</label
                      >
                      <Field
                        name="password"
                        class="form-control"
                        :class="{ 'is-invalid': errors.password }"
                        v-model="user.password"
                        type="password"
                        placeholder="Password"
                      />
                      <div class="invalid-feedback">{{ errors.password }}</div>
                    </div>
                    <div class="col-md-6 form-group">
                      <label :class="{ 'invalid-feedback': errors.password }"
                        >Confirm Password</label
                      >
                      <Field
                        name="re_password"
                        class="form-control"
                        :class="{ 'is-invalid': errors.re_password }"
                        v-model="user.re_password"
                        type="password"
                        placeholder="Confirm Password"
                      />
                      <div class="invalid-feedback">
                        {{ errors.re_password }}
                      </div>
                    </div>
                    <div class="col-md-12 form-group">
                      <Field
                        class="form-check-input"
                        :class="{ 'is-invalid': errors.userOption }"
                        v-model="user.userOption"
                        type="radio"
                        name="userOption"
                        id="customer"
                        value="is_customer"
                      />
                      <label
                        class="form-check-label"
                        :class="{ 'is-invalid': errors.userOption }"
                        for="customer"
                        >Customer <br class="v_bs"> <small>(No Subscription)</small></label
                      >

                      <Field
                        class="form-check-input"
                        v-model="user.userOption"
                        :class="{ 'is-invalid': errors.userOption }"
                        type="radio"
                        name="userOption"
                        id="service"
                        value="is_service"
                      />
                      <label
                        class="form-check-label"
                        :class="{ 'is-invalid': errors.userOption }"
                        for="service"
                        >Parts Provider <br class="v_bs">
                        <small>(Subscription Required)</small></label
                      >

                      <Field
                        class="form-check-input"
                        v-model="user.userOption"
                        :class="{ 'is-invalid': errors.userOption }"
                        type="radio"
                        name="userOption"
                        id="service"
                        value="is_service"
                      />
                      <label
                        class="form-check-label"
                        :class="{ 'is-invalid': errors.userOption }"
                        for="service"
                        >Service Provider <br class="v_bs">
                        <small>(Subscription Required)</small></label
                      >

                      <div class="invalid-feedback">
                        {{ errors.userOption }}
                      </div>
                    </div>
                    <div class="col-12">
                      <base-alert v-if="success" type="success" dismissible>
                        <span class="alert-inner--text">{{ success }}</span>
                        <button
                          type="button"
                          class="close"
                          data-dismiss="alert"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </base-alert>
                      <button class="uren-register_btn">Register</button>
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
      <!-- Uren's Login Register Area  End Here -->
    </template>
  </base-wrapper>
</template>

<script>
import BaseWrapper from "../../components/website/BaseWrapper.vue";
import PageLoader from "../../components/website/PageLoader.vue";
import { Field, Form } from "vee-validate";
import * as yup from "yup";

export default {
  components: { BaseWrapper, Form, Field, PageLoader },
  data() {
    return {
      login: {
        username: "",
        password: "",
      },
      user: {
        first_name: "",
        last_name: "",
        username: "",
        email: "",
        password: "",
        userOption: "",
        re_password: "",
      },
      success: "",
      loginErrors: [],
      signupErrors: [],
      isLoading: false,
    };
  },
  computed: {
    schema() {
      const schema = yup.object({
        username: yup.string().required(),
        password: yup.string().required("Invalid details, please try again"),
      });

      return schema;
    },
    schemaSignup() {
      const schema = yup.object({
        first_name: yup.string().required().min(2),
        last_name: yup.string().required().min(2),
        username: yup.string().required().min(4),
        email: yup.string().required().email(),
        password: yup.string().required().min(6),
        re_password: yup.string().required().min(6),
        userOption: yup.string().required(),
      });

      return schema;
    },
    loggedInUser() {
      return this.$store.getters.user;
    },
  },
  methods: {
    async onSubmitLogin() {
      this.isLoading = true;
      try {
        await this.$store.dispatch("login", {
          login: {
            username: this.login.username,
            password: this.login.password,
          },
        });
        if (this.loggedInUser.is_service || this.loggedInUser.is_superuser) {
          this.$router.replace("/admin-dashboard");
        } else {
          this.$router.replace("/");
        }
      } catch (error) {
        console.log(error.response.data);
        if (error.response.data.non_field_errors) {
          this.loginErrors = error.response.data.non_field_errors;
        } else {
          for (const key in error.response.data) {
            this.loginErrors.push(
              error.response.data[key][0] + " (" + key.toUpperCase() + ")"
            );
          }
        }
      }

      this.isLoading = false;
    },
    async onSubmitSignup() {
      if (this.user.password !== this.user.re_password) {
        this.signupErrors.push("Confirm password does not match password");
        return;
      }
      this.isLoading = true;

      try {
        await this.$store.dispatch("signup", {
          user: this.user,
        });
        this.success =
          this.user.userOption === "is_customer"
            ? "New Customer successfully created and email has been send for account activation."
            : "New Service successfully created and email has been send for account activation";
      } catch (error) {
        console.log(error.response.data);
        if (error.response.data.non_field_errors) {
          this.loginErrors = error.response.data.non_field_errors;
        } else {
          for (const key in error.response.data) {
            this.loginErrors.push(
              error.response.data[key][0] + " (" + key.toUpperCase() + ")"
            );
          }
        }
      }
      this.isLoading = false;
    },
  },
  mounted() {
    document.title = "PSI Link | Login/Register";
  },
};
</script>

<style scoped>
.v_bs {
  display: none;
}

@media (max-width: 600px){
  .v_bs {
    display: block;
  }
}
</style>
