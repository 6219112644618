import axios from "axios";

const API_URL = process.env.VUE_APP_API_URL + "subscriptions/";

class SubscriptionService {
  getConfig(token) {
    return {
      headers: { Authorization: `Token ${token}` },
    };
  }

  async createSubcription(token, data) {
    return await axios.post(API_URL, data, this.getConfig(token));
  }

  async getSubcriptions(token) {
    return await axios.get(API_URL, this.getConfig(token));
  }

  async getSubcription(token, slug) {
    return await axios.get(API_URL + slug, this.getConfig(token));
  }
}

export default new SubscriptionService();
