import axios from "axios";

const API_URL = process.env.VUE_APP_API_URL;

class AdvertService {
  getConfig(token) {
    return {
      headers: { Authorization: `Token ${token}` },
    };
  }

  async createAdvert(token, data) {
    return await axios.post(
      API_URL + "ad-service/",
      data,
      this.getConfig(token)
    );
  }

  async getAdverts(token, payload) {
    return await axios.get(
      API_URL + `ad-service/${payload}`,
      this.getConfig(token)
    );
  }
}

export default new AdvertService();
